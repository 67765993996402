import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';

import compose from 'lodash/flowRight';

import QueryCourseGet from '../../../../GraphQL/QueryCourseGet';

import component from './CourseSidebar';

export default compose(
  withRouter,
  graphql(QueryCourseGet, {
    options: () => ({
      fetchPolicy: 'cache-first',
    }),
    props: ({ data: { loading, courseGet }, ownProps }) => ({
      ...ownProps,
      loading,
      course: courseGet || {},
    }),
  }),
)(component);
