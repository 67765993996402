import { graphql } from 'react-apollo';

import MutationUserJoinCourse from '../../GraphQL/MutationUserJoinCourse';
import QueryUserGetCurrent from '../../GraphQL/QueryUserGetCurrent';

import component from './Component';

export default graphql(MutationUserJoinCourse, {
  props: ({ mutate }) => ({
    onJoinCourse: code => mutate({
      variables: {
        code,
      },
      update: (_proxy_, { data: { userJoinCourse } }) => {
        if (userJoinCourse) {
          const { id, currentUserPosition = {} } = userJoinCourse;
          const { bookId, pageId } = currentUserPosition;

          const fragments = ['class', id];
          if (bookId) fragments.push('book', bookId);
          if (pageId) fragments.push('page', pageId);

          window.location = fragments.join('/');
        }
      },
      refetchQueries: [{ query: QueryUserGetCurrent }],
    }),
  }),
})(component);
