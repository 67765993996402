import React from 'react';
import t from 'prop-types';

import styles from './Image.css';

const View = ({ uri }) => {
  const src = /^(http|https).*/.test(uri) ? uri : [
    process.env.REACT_APP_URL,
    process.env.REACT_APP_KLFA_S3URL,
    uri,
  ].join('/')

  return <img className={styles.image} src={src} alt="" />;
};

View.propTypes = {
  uri: t.string,
};

View.defaultProps = {
  uri: 'https://placekitten.com/g/450/200',
};

export default View;
