import '@material/button/dist/mdc.button.css';
import '@material/dialog/dist/mdc.dialog.css';

import React from 'react';
import t from 'prop-types';
import { Button, ButtonIcon } from 'rmwc/Button';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, DialogButton,
} from 'rmwc/Dialog';
import { TextField, TextFieldHelperText } from 'rmwc/TextField';

import I18n from '../../util/i18n';

class EditBookMetadataButtonAndDialog extends React.Component {
  static propTypes = {
    onSave: t.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  onAccept = () => {
    const { onSave } = this.props;
    const { title, subtitle } = this.state;
    onSave({ title, subtitle });
  };

  ontitleChange = (event) => {
    this.setState({ title: event.target.value });
  };

  onSubtitleChange = (event) => {
    this.setState({ subtitle: event.target.value });
  };

  static getDerivedStateFromProps(props, prevState) {
    const { title, subtitle } = props;
    if (!prevState || (!prevState.title && !prevState.subtitle)) {
      return { title, subtitle };
    }

    return null;
  }

  open = () => {
    this.setState({ open: true });
  };

  close = () => {
    this.setState({ open: false });
  };

  render() {
    const { open, title, subtitle } = this.state;
    return (
      <React.Fragment>
        <Button onClick={this.open}>
          <ButtonIcon icon="short_text" /> {I18n.get('Edit Metadata')}
        </Button>
        <Dialog open={open} onClose={this.close}>
          <DialogTitle>{I18n.get('Edit Book Metadata')}</DialogTitle>
          <DialogContent>
            <TextField
              value={title || ''}
              onChange={this.ontitleChange}
              label={I18n.get('Book title...')}
            />
            <TextFieldHelperText>{I18n.get('E.g.: 5B')}</TextFieldHelperText>
            <TextField
              value={subtitle || ''}
              onChange={this.onSubtitleChange}
              label={I18n.get('Book subtitle...')}
            />
            <TextFieldHelperText>{I18n.get('E.g.: 5B')}</TextFieldHelperText>
          </DialogContent>
          <DialogActions>
            <DialogButton action="close" isDefaultAction>
              {I18n.get('Cancel')}
            </DialogButton>
            <DialogButton action="accept" raised onClick={this.onAccept}>
              {I18n.get('Save')}
            </DialogButton>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default EditBookMetadataButtonAndDialog;
