import gql from 'graphql-tag';

export default gql`
  subscription studentStatusUpdated($courseId: ID!) {
    studentStatusUpdated(id: $courseId) {
      user {
        id
      }
      status
    }
  }
`;
