import React, { Component } from 'react';
import t from 'prop-types';
import { ButtonIcon } from 'rmwc/Button';

import Cell from './Cell';
import styles from './Foldable.css';

class Foldable extends Component {
  static propTypes = {
    children: t.oneOfType([t.node, t.arrayOf(t.node)]).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
    };
  }

  toggleCollapsed = () => {
    const { collapsed } = this.state;
    this.setState({ collapsed: !collapsed });
  };

  render() {
    const { children, title } = this.props;
    const { collapsed } = this.state;

    /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
    return (
      <Cell {...this.props} type="foldable">
        <div
          className={[styles.toggle, styles[collapsed ? 'closed' : 'open']].join(' ')}
          onClick={this.toggleCollapsed}
        >
          <ButtonIcon icon={collapsed ? 'chevron_right' : 'expand_more'} />
          <span>{title}</span>
        </div>
        {collapsed ? null : children}
      </Cell>
    );
    /* eslint-enable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
  }
}

export default Foldable;
