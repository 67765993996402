import React from 'react';
import t from 'prop-types';
import { TopAppBar, TopAppBarRow, TopAppBarSection } from 'rmwc/TopAppBar';
import { Link } from 'react-router-dom';
import { ButtonIcon } from 'rmwc/Button';

import I18n from '../../util/i18n';
import PageView from '../Pages/View/PageViewContainer';

import CustomBookCssAndCodeRendererHOC from './CustomBookCssAndCodeRendererContainerHOC';
import TableOfContents from './TableOfContents';
import styles from './BookView.css';

const renderTopBar = () => (
  <React.Fragment>
    <TopAppBar fixed className={styles.topAppBar}>
      <TopAppBarRow>
        <TopAppBarSection alignStart>
          <Link className="mdc-button mdc-ripple-upgraded" to="/books">
            <ButtonIcon icon="arrow_back_ios" />
            {I18n.get('Exit Viewer')}
          </Link>
        </TopAppBarSection>
      </TopAppBarRow>
    </TopAppBar>
  </React.Fragment>
);

const renderPage = (bookId, pageId) => {
  if (!pageId) {
    return (
      <div className={`${styles.page} ${styles.placeholder}`}>
        {I18n.get('Select a page from the left to edit')}
      </div>
    );
  }

  return (
    <div className={styles.pageViewWrapper}>
      <PageView pageId={pageId} className={styles.page} viewOnly />
    </div>
  );
};

const BookView = ({ bookId, pageId }) => (
  <div className={`${styles.container} kalfa-book-${bookId}`}>
    {renderTopBar(bookId)}
    <CustomBookCssAndCodeRendererHOC bookId={bookId}>
      <div className={styles.sidebar}>
        <TableOfContents bookId={bookId} className={styles.widthToc} readOnly />
        {renderPage(bookId, pageId)}
      </div>
    </CustomBookCssAndCodeRendererHOC>
  </div>
);

BookView.propTypes = {
  bookId: t.string.isRequired,
  pageId: t.string,
};

BookView.defaultProps = {
  pageId: null,
};

export default BookView;
