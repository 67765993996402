import { graphql } from 'react-apollo';

import I18n from '../../util/i18n';
import MutationCreateRawCellAndInsert from '../../GraphQL/MutationCreateRawCellAndInsert';

import component from './AddRawCellButton';

export default graphql(MutationCreateRawCellAndInsert, {
  props: ({ mutate, ownProps: { pageId, positionInPage } }) => ({
    onAdd: () => mutate({
      variables: {
        contents: `<p>${I18n.get('Change this')}</p>`,
        pageId,
        positionInPage,
      },
    }),
  }),
})(component);
