import { graphql, withApollo } from 'react-apollo';

import compose from 'lodash/flowRight';

import MutationDeleteChapter from '../../../GraphQL/MutationDeleteChapter';
import MutationDeletePages from '../../../GraphQL/MutationDeletePages';
import MutationRemoveChapterOrPageFromBook from '../../../GraphQL/MutationRemoveChapterOrPageFromBook';

import component from './RightClickMenu';

export default compose(
  withApollo,
  graphql(MutationDeleteChapter, {
    props: ({
      mutate, ownProps: {
        chapterId, chapterIndex, bookId, client, pages,
      },
    }) => ({
      onDelete: () => {
        mutate({
          variables: {
            chapterId,
          },
          update: () => {
            client.mutate({
              mutation: MutationRemoveChapterOrPageFromBook,
              variables: {
                bookId,
                chapterOrPageIndex: chapterIndex,
              },
            });
            if (pages.length > 0) {
              client.mutate({
                mutation: MutationDeletePages,
                variables: {
                  pageIds: pages.map(page => page.id),
                },
              });
            }
          },
        });
      },
    }),
  }),
)(component);
