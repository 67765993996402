import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AllCoursesInCards from '../../components/Courses/AllInCardsContainer';

import Course from './CourseContainer';

const App = () => (
  <Switch>
    <Route path="/class/:courseId/book/:bookId/page/:pageId" component={Course} />
    <Route path="/class/:courseId/book/:bookId" component={Course} />
    <Route path="/class/:courseId" component={Course} />
    <Route path="/classes" component={AllCoursesInCards} />
    <Route component={Course} />
  </Switch>
);

export default App;
