import React from 'react';
import { Button, ButtonIcon } from 'rmwc/Button';
import { Grid, GridCell, GridInner } from 'rmwc/Grid';
import { Icon } from 'rmwc/Icon';
import { IconButton } from 'rmwc/IconButton';
import { SimpleDialog } from 'rmwc/Dialog';
import { Typography } from 'rmwc/Typography';
import MathJax from 'react-mathjax2';

import Cache from '../../util/cache';

import '@material/typography/dist/mdc.typography.css';
import '@material/button/dist/mdc.button.css';
import '@material/layout-grid/dist/mdc.layout-grid.css';
import '@material/icon-button/dist/mdc.icon-button.css';
import '@material/dialog/dist/mdc.dialog.css';
import '@material/theme/dist/mdc.theme.css';

import Essay from './Essay';
import Quiz from './Quiz';
import Figure from './Figure';
import GoalIcon from './GoalIcon';
import Lightbox from './Lightbox';
import Audio from './Audio';
import DragAndSort from './DragAndSort';
import DragToBucket from './DragToBucket';
import Video from './Video';
import YouTube from './YouTube';
import Paint from './Paint';
import Carousel from './Carousel';
import Raw from './Raw';
import Foldable from './Foldable';
import GeoGebra from './GeoGebra';

window.Button = Button;
window.ButtonIcon = ButtonIcon;
window.Grid = Grid;
window.GridCell = GridCell;
window.GridInner = GridInner;
window.Icon = Icon;
window.IconButton = IconButton;
window.Typography = Typography;
window.SimpleDialog = SimpleDialog;

window.Essay = Essay;
window.Quiz = Quiz;
window.Figure = Figure;
window.GoalIcon = GoalIcon;
window.Audio = Audio;
window.DragAndSort = DragAndSort;
window.DragToBucket = DragToBucket;
window.Video = Video;
window.YouTube = YouTube;
window.Paint = Paint;
window.Carousel = Carousel;
window.Raw = Raw;
window.Lightbox = Lightbox;
window.Foldable = Foldable;
window.GeoGebra = GeoGebra;

// eslint-disable-next-line no-underscore-dangle
window.__klfaCache = Cache;

// eslint-disable-next-line react/prop-types
window.Equation = ({ children }) => {
  if (typeof children === 'string') {
    return React.createElement(MathJax.Context, {
      input: 'tex',
    }, React.createElement(MathJax.Node, {
      inline: true,
    }, children));
  }

  return React.createElement('h1', null,
    '\u26A0\uFE0F Children of ',
    React.createElement('code', null, 'Math'),
    ' need to be a string object.');
};
