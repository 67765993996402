import gql from 'graphql-tag';

export default gql`
  mutation cellUpdate(
    $id: String!
    $customCss: String
    $contents: String
    $visibility: VisibilityStatus
  ) {
    cellUpdate(id: $id, customCss: $customCss, contents: $contents, visibility: $visibility) {
      id
      customCss
      contents
      visibility
      transpiledCode
    }
  }
`;
