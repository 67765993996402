// @ts-check
import React from 'react';
import t from 'prop-types';

import Cache from '../../../util/cache';
import AddCourseCellButton from '../AddCourseCellButtonContainer';
import CourseContext from '../../Courses/View/CourseContext';
import CourseCell from '../CourseCellContainer';

import CellView from './CellView';

function noop() {}
class PageView extends React.Component {
  static propTypes = {
    pageId: t.string.isRequired,
    page: t.shape({
      cells: t.array,
    }).isRequired,
    answerUpdate: t.func.isRequired,
    className: t.string,
    viewOnly: t.bool,
    editMode: t.bool,
    courseId: t.string,
    subscribeToAnswerUpdated: t.func.isRequired,
  };

  static defaultProps = {
    className: '',
    viewOnly: false,
    editMode: false,
    courseId: '',
  };

  constructor(props) {
    super(props);
    const { subscribeToAnswerUpdated } = props;
    this.unsubscribe = subscribeToAnswerUpdated();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  renderCourseCells = (context, cell) => {
    const { courseId } = this.props;
    const relatedCourseCells = ((context && context.course && context.course.courseCells) || [])
      .filter(f => f.afterCell === cell.id)
      .sort((a, b) => b.rank - a.rank);

    if (relatedCourseCells.length === 0) return null;
    return (
      <div>
        {relatedCourseCells.map(c => (
          <CourseCell
            id={c.id}
            key={c.id}
            contents={c.contents}
            type={c.cellType}
            courseId={courseId}
            myAnswer={c.myAnswer}
            answers={c.answers}
            course={c}
          />
        ))}
      </div>
    );
  };

  render() {
    const {
      pageId,
      page: { cells = [] },
      answerUpdate,
      className,
      viewOnly,
      editMode,
      courseId,
    } = this.props;

    const { isTeacher = false } = Cache.getItem('userRoles') || {};

    const displayedCells = isTeacher
      ? cells
      : cells.filter(({ visibility }) => visibility !== 'teacher');

    const renderedCells = displayedCells.map(cell => (
      <React.Fragment key={cell.id}>
        <CellView
          answerUpdate={viewOnly ? noop : answerUpdate}
          isTeacherCell={cell.visibility === 'teacher'}
          {...cell}
        />
        <CourseContext.Consumer>
          {(context = {}) => this.renderCourseCells(context, cell)}
        </CourseContext.Consumer>
        {editMode && cell.visibility !== 'teacher' ? (
          // @ts-ignore
          <AddCourseCellButton afterCell={cell.id} courseId={courseId} rank={0} />
        ) : null}
      </React.Fragment>
    ));

    return (
      <div className={`kalfa-page-view kalfa-page-${pageId} ${className}`}>{renderedCells}</div>
    );
  }
}

export default PageView;
