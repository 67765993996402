import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import compose from 'lodash/flowRight';

import QueryBookGet from '../../GraphQL/QueryBookGet';

import component from './BookTitleAndSubtitle';

export default compose(
  withRouter,
  graphql(QueryBookGet, {
    props: ({ data: { loading, bookGet }, ownProps }) => ({
      ...ownProps,
      loading,
      book: bookGet || {},
    }),
  }),
)(component);
