import React from 'react';
import t from 'prop-types';
import { Typography } from 'rmwc/Typography';

import I18n from '../../util/i18n';

import '@material/textfield/dist/mdc.textfield.css';
import '@material/theme/dist/mdc.theme.css';

import Cell from './Cell';
import './Figure.css';

const FigureCell = (props) => {
  const { uri, alt, caption } = props;
  const startsWith = (str = '', search = '') => str.substr(0, search.length) === search;

  const image = (startsWith(uri, 'https://') || startsWith(uri, 'data:image/gif;'))
    ? uri
    : [
      process.env.REACT_APP_URL,
      process.env.REACT_APP_KLFA_S3URL,
      'static/img',
      uri,
    ].join('/');

  return (
    <Cell {...props} type="figure">
      <img className="kalfa-figure" alt={alt} src={image} />
      {caption ? (
        <Typography use="caption" className="kalfa-figure-caption">
          {caption}
        </Typography>
      ) : null}
    </Cell>
  );
};

FigureCell.propTypes = {
  caption: t.string,
  alt: t.string,
  uri: t.string,
};

FigureCell.defaultProps = {
  caption: null,
  alt: I18n.get('Figure'),
  uri: 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
};

export default FigureCell;
