// @ts-check

import React from 'react';
import t from 'prop-types';
import { Redirect } from 'react-router-dom';

import CourseViewer from '../../components/Courses/View/index';
import PleaseWait from '../../components/PleaseWait';

const Course = ({
  courseId, bookId, pageId, course, loading,
}) => {
  if (loading) return <PleaseWait />;

  const { books = [] } = course || {};

  // FIXME:
  // import Cache from '../../util/cache';
  // console.log('DO SOME REDIRECTION AND CHECKING WITH', { courseId, currentRoles, currentUser });
  // const currentUser = Cache.getItem('userCurrent');
  // const currentRoles = Cache.getItem('userRoles');

  if (!courseId || !books.length) {
    return <Redirect to="/" />;
  }

  if (!bookId) {
    const { bookId: currentUserBookId = undefined, pageId: currentUserPageId = undefined } = course.currentUserPosition || {};

    const { bookId: courseBookId = undefined, pageId: coursePageId = undefined } = course.currentUserPosition || {};

    if (currentUserBookId && currentUserPageId) {
      return (
        <Redirect to={`/class/${courseId}/book/${currentUserBookId}/page/${currentUserPageId}`} />
      );
    }

    if (courseBookId && coursePageId) {
      return <Redirect to={`/class/${courseId}/book/${courseBookId}/page/${coursePageId}`} />;
    }

    const [book] = books;

    const { id: firstPageId = null } = (book.pages || [])[0] || {};

    return <Redirect to={`/class/${courseId}/book/${book.id}/page/${firstPageId}`} />;
  }

  if (!pageId) {
    const [book] = books.filter(({ id }) => id === bookId);

    const { id: firstPageId = null } = (book.pages || [])[0] || {};
    return <Redirect to={`/class/${courseId}/book/${bookId}/page/${firstPageId}`} />;
  }

  return <CourseViewer courseId={courseId} bookId={bookId} pageId={pageId} course={course} />;
};

Course.propTypes = {
  courseId: t.string.isRequired,
  bookId: t.string,
  pageId: t.string,
  course: t.shape({
    books: t.arrayOf(
      t.shape({
        id: t.string,
        pages: t.arrayOf(
          t.shape({
            id: t.string,
          }),
        ),
      }),
    ),
  }),
  loading: t.bool.isRequired,
};

Course.defaultProps = {
  bookId: null,
  pageId: null,
  course: {},
};

export default Course;
