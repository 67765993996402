import React from 'react';
import t from 'prop-types';

class CourseNavigator extends React.Component {
  static propTypes = {
    subscribeToUpdateCourseCurrentPosition: t.func.isRequired,
  };

  constructor(props) {
    super();
    const { course } = props || {};
    const { currentPosition } = course || {};
    const { bookId, pageId } = currentPosition || {};

    this.state = { bookId, pageId };
  }

  UNSAFE_componentWillMount() {
    const { subscribeToUpdateCourseCurrentPosition } = this.props;
    subscribeToUpdateCourseCurrentPosition();
  }

  shouldComponentUpdate(newProps) {
    const {
      course,
      history: {
        push,
        location: { pathname },
      },
    } = newProps;
    const { currentPosition, id } = course || {};
    const { bookId, pageId } = currentPosition || {};

    const { bookId: displayedBookId, pageId: displayedPageId } = this.state;

    if (!displayedBookId && bookId) this.setState({ bookId });
    if (!displayedPageId && pageId) this.setState({ pageId });

    if (!displayedBookId || !displayedPageId) return false;

    if (!bookId || !pageId) return false;
    if (bookId === displayedBookId && pageId === displayedPageId) return false;

    this.setState({ bookId, pageId });

    const newPathname = `/class/${id}/book/${bookId}/page/${pageId}`;
    if (newPathname === pathname) return false;

    push(newPathname);
    return false;
  }

  render() {
    return null;
  }
}

export default CourseNavigator;
