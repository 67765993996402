import React, { Component } from 'react';
import t from 'prop-types';
import { Button } from 'rmwc/Button';

import Cache from '../../util/cache';
import Hub from '../../util/hub';
import { quizValidator } from '../../util/cellAnswersValidator';

import CellAnswers from './CellAnswers';
import Cell from './Cell';

import './Quiz.css';

const logger = console;

class Quiz extends Component {
  static propTypes = {
    statement: t.string,
    multiSelect: t.bool,
    items: t.arrayOf(t.string),
    validation: t.oneOfType([t.number, t.arrayOf(t.number), t.oneOf(['any', 'none'])]),
  };

  static defaultProps = {
    statement: null,
    multiSelect: false,
    items: ['A', 'B', 'C'],
    validation: 'any',
  };

  constructor() {
    super();
    const { registerWithCell, answerUpdate } = window.poormansContextReplacement;
    const {
      cellId, rank, initialStateJson, answers, initialIsReady, course,
    } = registerWithCell(
      'quiz',
    );

    Hub.listen(`${cellId}::quiz::${rank}::own`, this);
    Hub.listen(`${cellId}::quiz::${rank}::others`, this);

    this.rank = rank;
    this.course = course;
    this.initialStateJson = initialStateJson;
    this.answerUpdate = answerUpdate;

    let selected = [];
    try {
      selected = (this.initialStateJson && JSON.parse(`${this.initialStateJson}`)) || [];
    } catch (e) {
      logger.error('Invalid JSON', this.initialStateJson, e);
    }
    this.state = { selected, answers, isReady: initialIsReady };
  }

  onHubCapsule(capsule) {
    const { channel } = capsule;

    if (channel.endsWith('::own')) {
      return this.onOwnAnswerCapsule(capsule);
    }
    if (channel.endsWith('::others')) {
      return this.onOthersAnswerCapsule(capsule);
    }
    logger.error('Unknown channel for capsule', capsule);
    return null;
  }

  onOwnAnswerCapsule(capsule) {
    const {
      payload: { data, isReady },
    } = capsule;
    try {
      const selected = (data && JSON.parse(data)) || [];
      this.setState({ selected, isReady });
    } catch (e) {
      logger.error('Invalid JSON', data, e);
    }
  }

  onOthersAnswerCapsule(capsule) {
    const { payload: answers } = capsule;
    this.setState({ answers });
  }

  onClick = (index, multiSelect) => () => {
    const { selected } = this.state;
    const indexOfIndex = selected.indexOf(index);

    if (indexOfIndex !== -1) {
      selected.splice(indexOfIndex, 1);
    } else {
      if(!multiSelect) {
        selected.splice(0, selected.length);
      }

      selected.push(index);
      selected.sort();
    }

    this.answerUpdate('quiz', this.rank, JSON.stringify(selected));
    this.setState({
      selected,
    });
  };

  toggleIsReady = () => {
    const { isReady } = this.state;
    this.answerUpdate('quiz', this.rank, undefined, !isReady);
    this.setState({ isReady: !isReady });
  };

  render() {
    const {
      props: { statement, multiSelect, items, validation },
      state: { answers = {}, selected, isReady },
    } = this;

    const { isTeacher } = Cache.getItem('userRoles') || {};
    const isCorrect = quizValidator(validation, selected);

    const buttons = items.map((item, index) => {
      const isSelected = selected.indexOf(index) >= 0;
      return (
        <Button
          key={index}
          unelevated={isSelected}
          outlined={!isSelected}
          className="kalfa-quiz-button"
          onClick={this.onClick(index, multiSelect)}
        >
          {item}
        </Button>
      );
    });

    let isReadyStr = isReady ? 'yes' : 'no';
    if (!selected.length) isReadyStr = 'disabled';

    return (
      <Cell
        {...this.props}
        isReady={isReadyStr}
        isCorrect={isCorrect}
        type="quiz"
        toggleIsReady={this.toggleIsReady}
      >
        <div className="kalfa-cell-statement kalfa-quiz-statement">{statement}</div>
        <div className="kalfa-quiz-buttons">{buttons}</div>
        {isTeacher && (
          <CellAnswers answers={answers} course={this.course} type="quiz" {...this.props} />
        )}
      </Cell>
    );
  }
}

export default Quiz;
