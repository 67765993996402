import gql from 'graphql-tag';

export default gql`
  query pageGet($pageId: ID!) {
    pageGet(id: $pageId) {
      id
      title
      subtitle
      cells {
        id
        visibility
        customCss
        contents
        transpiledCode
        myAnswer {
          answers {
            answerType
            index
            data
            isReady
          }
        }
        answers {
          user {
            id
            displayName
            roles
          }
          answers {
            answerType
            index
            data
            isReady
          }
        }
      }
    }
  }
`;
