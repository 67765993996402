import gql from 'graphql-tag';

export default gql`
  mutation updatePage($input: UpdatePageInput!) {
    updatePage(input: $input) {
      id
      title
      subtitle
    }
  }
`;
