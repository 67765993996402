// @ts-check

import React from 'react';
import t from 'prop-types';
import { graphql } from 'react-apollo';

import updateCacheUser from '../util/updateCacheUser';
import QueryUserGetCurrent from '../GraphQL/QueryUserGetCurrent';

import PleaseWait from './PleaseWait';

const getCookieValue = (a) => {
  const b = document.cookie.match(`(^|;)\\s*${a}\\s*=\\s*([^;]+)`);
  return b ? b.pop() : '';
};

const LOGIN_API = process.env.REACT_APP_KLFA_LOGIN_API_ENDPOINT || '/api/login';
const token = getCookieValue('kltoken');

const redirect = () => {
  // for some reason there's sometines a redirect loop
  // this is supposed to avoid it
  // probably this is not necessary, as the main reason
  // seems to be the service worker
  if (window.location.pathname === LOGIN_API) {
    window.location.href = `${LOGIN_API}?redirect=/`;
    return;
  }
  window.location.href = `${LOGIN_API}?redirect=${window.location.pathname}`;
};

if (!token) redirect();

const AuthWrapper = ({ user = {}, loading, children }) => {
  if (loading) return null;

  if (!user.id) {
    return redirect();
  }

  const { userRolesBoolean } = updateCacheUser(user);

  const className = (user.roles || []).map(role => `klfa-role-${role}`).join(' ');
  return user.id ? (
    <div className={className}>{children(userRolesBoolean, user)}</div>
  ) : (
    <PleaseWait />
  );
};

AuthWrapper.propTypes = {
  children: t.func.isRequired,
  user: t.shape({ id: t.string }).isRequired,
  loading: t.bool.isRequired,
};

export default graphql(QueryUserGetCurrent, {
  // @ts-ignore
  props: ({ data: { loading, userGetCurrent }, ownProps }) => ({
    ...ownProps,
    loading,
    user: userGetCurrent || {},
  }),
  // @ts-ignore
})(AuthWrapper);
