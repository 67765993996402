// @ts-check
import React from 'react';
import t from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, ButtonIcon } from 'rmwc/Button';
import { Drawer, DrawerHeader, DrawerContent } from 'rmwc/Drawer';
import {
  ListItem,
  ListItemText,
  ListItemMeta,
  ListDivider,
  ListGroup,
  ListItemGraphic,
} from 'rmwc/List';
import {
  Dialog, DialogTitle, DialogActions, DialogButton, DialogContent,
} from 'rmwc/Dialog';
import { Typography } from 'rmwc/Typography';

import I18n from '../../../../util/i18n';
import Cache from '../../../../util/cache';
import ClassWall from '../ClassWallContainer';
import TableOfContents from '../../../Books/TableOfContents';
import StudentList from '../StudentListWithContext';

import '@material/dialog/dist/mdc.dialog.css';
import '@material/button/dist/mdc.button.css';
import '@material/drawer/dist/mdc.drawer.min.css';
import '@material/list/dist/mdc.list.min.css';
import '@material/typography/dist/mdc.typography.css';

// @ts-ignore
import styles from './MobileMenuSidebar.css';
import ReactToPrint from 'react-to-print';
import TopAppBarButton from '../TopAppBarButtonContainer';

class MobileMenuSidebar extends React.Component {
  static propTypes = {
    bookId: t.string.isRequired,
    className: t.string,
    course: t.shape({
      image: t.string,
      code: t.string,
    }).isRequired,
    loading: t.bool,
    courseId: t.string,
    onClose: t.func.isRequired,
    onLeaveCourse: t.func.isRequired,
    open: t.bool,
    history: t.shape({ push: t.func.isRequired }).isRequired,
    toggleEditMode: t.func.isRequired,
  };

  static defaultProps = {
    className: '',
    open: false,
    loading: true,
    courseId: '',
  };

  constructor(props) {
    super(props);
    this.helpVideo = React.createRef();
    this.state = {
      activeTabIndex: 'default',
      isOpen: false
    };
  }

  showTocContent = () => this.setState({ activeTabIndex: 'toc' });

  showDefaultContent = () => this.setState({ activeTabIndex: 'default' });

  showStudentsContent = () => this.setState({ activeTabIndex: 'students' });

  showWallContent = () => this.setState({ activeTabIndex: 'wall' });

  redirectToClasses = () => {
    const {
      history: { push },
    } = this.props;
    push('/classes');
  };

  toggleIsOpen = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  onAccept = () => {
    const { onLeaveCourse } = this.props;

    onLeaveCourse();
    this.toggleIsOpen();
  };

  render() {
    const {
      course,
      className,
      onClose,
      open,
      bookId,
      loading,
      courseId,
      toggleEditMode,
      componentToPrint,
    } = this.props;
    const { isTeacher = false } = Cache.getItem('userRoles') || {};

    const { activeTabIndex, isOpen } = this.state;

    const backButton = (
      <Button onClick={this.showDefaultContent} unelevated>
        <ButtonIcon icon="arrow_back_ios" />
        Back
      </Button>
    );

    const defaultContent = (
      <div style={{ display: 'flex', flexDirection: 'column', overflowY: 'scroll' }}>
        <DrawerHeader
          className={styles.headerImage}
          style={{ backgroundImage: `url(/images/${course.image})` }}
        >
          <Typography tag="h3">{I18n.get('Hello')}</Typography>
        </DrawerHeader>
        <DrawerContent>
          <ListItem onClick={this.showTocContent}>
            <ListItemGraphic icon="library_books" />
            <ListItemText>{I18n.get('Table of contents')}</ListItemText>
            <ListItemMeta icon="chevron_right" />
          </ListItem>
          <ListItem onClick={this.showWallContent}>
            <ListItemGraphic icon="mode_comment" />
            <ListItemText>{I18n.get('Class Wall')}</ListItemText>
            <ListItemMeta icon="chevron_right" />
          </ListItem>
          <ListItem onClick={this.showStudentsContent}>
            <ListItemGraphic icon="people" />
            <ListItemText>{I18n.get('Students')}</ListItemText>
            <ListItemMeta icon="chevron_right" />
          </ListItem>
          <ListDivider />
          <ListGroup>
            <ListItem onClick={isTeacher ? this.redirectToClasses : this.toggleIsOpen}>
              <ListItemGraphic icon="exit_to_app" />
              <ListItemText>{I18n.get('Leave Classroom')}</ListItemText>
            </ListItem>
            {isTeacher ? (
              <React.Fragment>
                <ListItem onClick={toggleEditMode}>
                  <ListItemGraphic icon="edit" />
                  <ListItemText>{I18n.get('Teacher edit')}</ListItemText>
                </ListItem>
                <TopAppBarButton checked={course.syncWithStudents} isMobile={true} />
              </React.Fragment>
            ) : null}
            {/* <ListItem>
              <ListItemGraphic icon="swap_horiz" />
              <ListItemText>Switch Classroom</ListItemText>
            </ListItem> */}
            <ReactToPrint
              trigger={() => (
                <ListItem onClick={toggleEditMode}>
                  <ListItemGraphic icon="arrow_downward" />
                  <ListItemText>{I18n.get('Printable View')}</ListItemText>
                </ListItem>
              )}
              content={() => componentToPrint}
            />

            {/* videotutorial */}
            {isTeacher && <ListItem onClick={() => (
              window.open(
                `${process.env.REACT_APP_URL}/static/videotutorial.html`,
                '_blank'
              )
            )}>
              <ListItemGraphic icon="help_outline" />
              <ListItemText>{I18n.get('HelpNavitem')}</ListItemText>
            </ListItem>}

            <Link to="/logout" className="mdc-ripple-surface mdc-list-item mdc-ripple-upgraded">
              <ListItemGraphic icon="close" />
              <ListItemText>{I18n.get('Logout')}</ListItemText>
            </Link>
          </ListGroup>
          {/* <ListDivider /> */}
        </DrawerContent>
        <div className={styles.code}>
          <Typography use="button">{I18n.get('classroom code')}:</Typography>
          <Typography use="headline3" tag="div">
            {course.code}
          </Typography>
        </div>
      </div>
    );

    const studentContent = (
      <React.Fragment>
        <DrawerHeader
          className={styles.headerImage}
          style={{ backgroundImage: `url(/images/${course.image})` }}
        >
          {backButton}
          <Typography tag="h3">{I18n.get('Students')}</Typography>
        </DrawerHeader>
        <DrawerContent>
          <StudentList courseId={course.id} />
        </DrawerContent>
      </React.Fragment>
    );

    const tocContent = (
      <React.Fragment>
        <DrawerHeader
          className={styles.headerImage}
          style={{ backgroundImage: `url(/images/${course.image})` }}
        >
          {backButton}
          <Typography tag="h3">{I18n.get('Table of contents')}</Typography>
        </DrawerHeader>
        <DrawerContent>
          <TableOfContents
            bookId={bookId}
            readOnly
            course={course.id}
            className={styles.tableOfContents}
          />
        </DrawerContent>
      </React.Fragment>
    );

    const wallContent = (
      <React.Fragment>
        <DrawerHeader
          className={styles.headerImage}
          style={{ backgroundImage: `url(/images/${course.image})` }}
        >
          {backButton}
          <Typography tag="h3">{I18n.get('Class Wall')}</Typography>
        </DrawerHeader>
        <DrawerContent>
          {loading ? null : <ClassWall courseId={courseId} activities={course.activities} />}
        </DrawerContent>
      </React.Fragment>
    );

    const getContents = () => {
      switch (activeTabIndex) {
        case 'default':
          return defaultContent;
        case 'students':
          return studentContent;
        case 'toc':
          return tocContent;
        case 'wall':
          return wallContent;
        default:
          return <h1>No content selected</h1>;
      }
    };

    return (
      <React.Fragment>
        <Drawer modal open={open} onClose={onClose} className={`${styles.drawer} ${className}`}>
          {getContents()}
        </Drawer>

        {/* "Leave classroom are you sure" Dialog */}
        <Dialog open={isOpen} onClose={this.toggleIsOpen}>
          <DialogTitle>{I18n.get('Are you sure?')}</DialogTitle>
          <DialogActions>
            <DialogButton action="close" isDefaultAction>
              {I18n.get('Cancel')}
            </DialogButton>
            <DialogButton action="accept" raised onClick={this.onAccept}>
              {I18n.get('Accept')}
            </DialogButton>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default MobileMenuSidebar;
