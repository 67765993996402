/* eslint-disable react/no-multi-comp */
import React, { Component } from 'react';
import t from 'prop-types';
import {
  ListItem,
  ListItemText,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemGraphic,
  ListItemMeta,
} from 'rmwc/List';

import I18n from '../../../util/i18n';
import NavigationButton from '../NavigationButtonContainer';
import EditPageMetadata from '../../Pages/EditPageMetadataContainer';
import EditChapterMetadata from '../../Pages/EditChapterMetadataContainer';

import RightClickPageMenu from './RightClickPageMenuContainer';
import RightClickChapterMenu from './RightClickChapterMenuContainer';
import styles from './ListItem.css';

import '@material/list/dist/mdc.list.min.css';

function noop() {}
class TocListItem extends Component {
  static propTypes = {
    id: t.string.isRequired,
    index: t.number,
    title: t.string,
    subtitle: t.string,
    indent: t.number,
    bookId: t.string,
    type: t.string.isRequired,
    graphic: t.string.isRequired,
    history: t.shape({
      location: t.shape({
        pathname: t.string,
        search: t.string,
      }),
      push: t.func,
    }).isRequired,
    pages: t.arrayOf(
      t.shape({
        id: t.string,
      }),
    ),
    readOnly: t.bool,
    onUpdate: t.func.isRequired,
  };

  static defaultProps = {
    title: I18n.get('Untitled Page'),
    subtitle: null,
    indent: 0,
    index: 0,
    bookId: '0',
    pages: [],
    readOnly: false,
  };

  constructor() {
    super();
    this.state = {
      pageMetadataDialog: false,
      chapterMetadataDialog: false,
      rightClickMenu: {
        isOpen: false,
        coordinates: {
          x: 0,
          y: 0,
        },
      },
    };
  }

  handleRightClick = (event) => {
    const { pageX, pageY } = event;
    this.setState({
      rightClickMenu: {
        isOpen: true,
        coordinates: {
          x: pageX,
          y: pageY,
        },
      },
    });
    event.preventDefault();
  };

  openPageDialog = () => {
    this.setState({ pageMetadataDialog: true });
  };

  closePageDialog = () => {
    this.setState({ pageMetadataDialog: false });
  };

  openChapterDialog = () => {
    this.setState({ chapterMetadataDialog: true });
  };

  closeChapterDialog = () => {
    this.setState({ chapterMetadataDialog: false });
  };

  onRightClickMenuClose = () => {
    this.setState({
      rightClickMenu: {
        isOpen: false,
        coordinates: {
          x: -100,
          y: -100,
        },
      },
    });
  };

  render() {
    const {
      id,
      title,
      subtitle,
      indent,
      bookId,
      index,
      type,
      graphic,
      pages,
      readOnly,
      history,
      onClick: chapterOnClick,
      match: {
        params: { pageId: routePageId },
      },
      onUpdate,
    } = this.props;

    const {
      rightClickMenu: {
        isOpen,
        coordinates: { x, y },
      },
      pageMetadataDialog,
      chapterMetadataDialog,
    } = this.state;

    const onClick = pageId => () => {
      const {
        location: { pathname, search = '' },
        push,
      } = history;
      const segments = pathname.split('/');
      if (segments[segments.length - 2] === 'page') {
        segments[segments.length - 1] = pageId;
      } else {
        segments.push(...['page', pageId]);
      }
      const to = `${segments.join('/')}${search}`;

      onUpdate(bookId, pageId);
      push(to);
    };

    const localListItem = (pageId, clickable) => (
      <ListItem
        key={id}
        onClick={chapterOnClick || (clickable ? onClick(pageId) : noop)}
        onContextMenu={this.handleRightClick}
        className={`klfa-toc-list-item ${indent && 'klfa-indent'} ${
          pageId === routePageId ? styles.highlight : null
        }`}
      >
        <ListItemGraphic icon={graphic} />
        <ListItemText>
          <ListItemPrimaryText title="test">
            <label title={title}>{title || ''}</label>
          </ListItemPrimaryText>
          {subtitle && <ListItemSecondaryText>{subtitle}</ListItemSecondaryText>}
        </ListItemText>
        {!readOnly && <ListItemMeta icon="drag_indicator" />}
      </ListItem>
    );

    return (
      <React.Fragment>
        <NavigationButton
          bookId={bookId}
          targetPageId={id}
          direction="id"
          component={({ pageId }) => localListItem(pageId, !pages.length)}
        />
        {!readOnly && (
          <div
            className={styles.rightClickMenu}
            style={{
              top: y,
              left: x,
            }}
          >
            {type === 'Page' ? (
              <RightClickPageMenu
                onClose={this.onRightClickMenuClose}
                isOpen={isOpen}
                pageId={id}
                pageIndex={index}
                bookId={bookId}
                openMetadataDialog={this.openPageDialog}
              />
            ) : (
              <RightClickChapterMenu
                onClose={this.onRightClickMenuClose}
                isOpen={isOpen}
                chapterId={id}
                chapterIndex={index}
                bookId={bookId}
                pages={pages}
                openMetadataDialog={this.openChapterDialog}
              />
            )}
          </div>
        )}
        {!readOnly
          && (type === 'Page' ? (
            <EditPageMetadata
              title={title}
              subtitle={subtitle}
              isOpen={pageMetadataDialog}
              pageId={id}
              close={this.closePageDialog}
            />
          ) : (
            <EditChapterMetadata
              title={title}
              subtitle={subtitle}
              isOpen={chapterMetadataDialog}
              chapterId={id}
              close={this.closeChapterDialog}
            />
          ))}
      </React.Fragment>
    );
  }
}

export default TocListItem;
