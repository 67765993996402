import QueryStorageUploadUrlGet from '../GraphQL/QueryStorageUploadUrlGet';

import client from './getGraphQLClient';

function sanitize(name) {
  if (typeof name !== 'string') {
    throw new Error('type of name is not string');
  }
  return name.replace(/[^a-z.A-Z0-9]+/g, '-').replace(/^-|-$/g, '');
}

function keyToUrl(key, timestamp) {
  const file = timestamp ? `${key}?timestamp=${timestamp}` : key;
  return [`${process.env.REACT_APP_URL}/${process.env.REACT_APP_KLFA_S3URL}`, file].join(
    '/',
  );
}

// I chose to keep the signature of AWS Amplify Storage.put function
const put = async (name, fileOrString, putOptions = {}) => {
  const { contentType } = putOptions;
  const filename = sanitize(name);
  const isFile = typeof fileOrString.name === 'string';

  const { data } = await client.query({
    query: QueryStorageUploadUrlGet,
    variables: { filename },
  });

  const { url, key } = data.storageUploadUrlGet;

  const options = {
    method: 'PUT',
    mode: 'cors',
    body: fileOrString,
  };

  if (contentType) {
    options.headers = {
      'Content-Type': contentType,
    };
  } else if (!isFile) {
    options.headers = {
      'Content-Type': 'text/plain',
    };
  }

  await fetch(url, options);
  return key;
};

const get = (key, timestamp) => fetch(keyToUrl(key, timestamp), { method: 'GET', mode: 'cors', cache: 'no-cache' });
const getJSON = (key, timestamp) => get(key, timestamp).then(r => r.json());

export default {
  keyToUrl,
  get,
  getJSON,
  put,
};
