import { graphql } from 'react-apollo';

import MutationUpdateChapter from '../../GraphQL/MutationUpdatePage';

import component from './EditPageOrChapterMetadata';

export default graphql(MutationUpdateChapter, {
  props: ({ mutate, ownProps }) => ({
    onSave: input => mutate({
      variables: {
        input: {
          id: ownProps.chapterId,
          ...input,
        },
      },
      optimisticResponse: () => ({
        updateChapter: {
          id: ownProps.chapterId,
          ...input,
          __typename: 'Page',
          optimistic: true,
        },
      }),
    }),
  }),
})(component);
