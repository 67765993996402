import React from 'react';
import t from 'prop-types';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, DialogButton,
} from 'rmwc/Dialog';
import { TextField } from 'rmwc/TextField';

import I18n from '../../util/i18n';

import '@material/button/dist/mdc.button.css';
import '@material/dialog/dist/mdc.dialog.css';

class EditPageOrChapterMetadata extends React.Component {
  static propTypes = {
    onSave: t.func,
    close: t.func,
    isOpen: t.bool,
    title: t.string,
    subtitle: t.string,
  };

  static defaultProps = {
    isOpen: false,
    onSave: () => {},
    close: () => {},
    title: '',
    subtitle: '',
  };

  constructor(props) {
    super(props);
    const { title, subtitle } = props;
    this.state = { title, subtitle };
  }

  onAccept = () => {
    const { onSave, close } = this.props;
    const { title, subtitle } = this.state;
    const input = { title };
    input.subtitle = subtitle || null;

    onSave(input);
    close();
  };

  onClose = () => {
    const { close } = this.props;
    close();
  };

  static getDerivedStateFromProps(props, prevState) {
    const { title, subtitle } = props;
    if (!prevState && (props.title || props.subtitle)) {
      return { title, subtitle };
    }
    return null;
  }

  handleTitleChange = (event) => {
    this.setState({ title: event.target.value });
  };

  handleSubtitleChange = (event) => {
    this.setState({ subtitle: event.target.value });
  };

  renderForm = () => {
    const { title, subtitle } = this.state || {};
    return (
      <React.Fragment>
        <TextField
          label={I18n.get('Title')}
          onChange={this.handleTitleChange}
          value={title || ''}
          onKeyDown={e => e.stopPropagation()}
        />
        <br />
        <br />
        <TextField
          label={I18n.get('Subtitle')}
          onChange={this.handleSubtitleChange}
          value={subtitle || ''}
          fullwidth
          onKeyDown={e => e.stopPropagation()}
        />
      </React.Fragment>
    );
  };

  render() {
    const { isOpen } = this.props;
    return (
      <Dialog open={isOpen} onClose={this.onClose}>
        <DialogTitle>{I18n.get('Edit Metadata')}</DialogTitle>
        <DialogContent>{this.renderForm()}</DialogContent>
        <DialogActions>
          <DialogButton action="close">{I18n.get('Cancel')}</DialogButton>
          <DialogButton action="accept" raised onClick={this.onAccept}>
            {I18n.get('Save')}
          </DialogButton>
        </DialogActions>
      </Dialog>
    );
  }
}

export default EditPageOrChapterMetadata;
