import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { WebSocketLink } from 'apollo-link-ws';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';

const logger = console;

const getGraphQLClient = () => new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) => logger.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ));
      }
      if (networkError) logger.error('[Network error] :', networkError);
      // if (networkError.message === 'User not found') {
      //   document.cookie = 'kltoken=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      // }
    }),
    new WebSocketLink({
      uri: process.env.REACT_APP_KLFA_GRAPHQL_WS_ENDPOINT || 'ws://',
      credentials: 'include',
      reconnect: true,
      inactivityTimeout: 0,
      options: {
        reconnect: true,
      },
    }),
  ]),
  cache: new InMemoryCache(),
});

const graphQLClient = getGraphQLClient();

window.graphQLClient = graphQLClient;

export default graphQLClient;
