import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import t from 'prop-types';
import {
  List,
  ListItem,
  ListItemText,
  ListItemPrimaryText,
  ListItemSecondaryText,
} from 'rmwc/List';
import { TextField, TextFieldHelperText } from 'rmwc/TextField';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, DialogButton,
} from 'rmwc/Dialog';
import { Select } from 'rmwc/Select';
import { ButtonIcon } from 'rmwc/Button';

import '@material/dialog/dist/mdc.dialog.css';
import '@material/button/dist/mdc.button.css';

import Cache from '../../util/cache';
import I18n from '../../util/i18n';

import styles from './Component.css';

class JumpTo extends Component {
  static propTypes = {
    match: t.shape({
      params: t.shape({
        bookId: t.string.isRequired,
        pageId: t.string.isRequired,
      }).isRequired,
    }).isRequired,
    loading: t.bool.isRequired,
    courses: t.arrayOf(t.shape({})).isRequired,
    onAdd: t.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      book: 'mathe',
    };
  }

  open = () => {
    this.setState({ isOpen: true });
  };

  close = () => {
    this.setState({ isOpen: false });
  };

  addCourse = (title, description, book) => {
    const { onAdd } = this.props;
    return () => onAdd(title, description, book);
  };

  onClick = id => () => {
    const {
      match: {
        params: { bookId, pageId },
      },
    } = this.props;

    window.location = `/class/${id}/book/${bookId}/page/${pageId}`;
  };

  changeTitle = (event) => {
    this.setState({ title: event.target.value });
  };

  changeDescription = (event) => {
    this.setState({ description: event.target.value });
  };

  renderForm = () => {
    const { title, description, book } = this.state;

    return (
      <React.Fragment>
        <TextField
          value={title || ''}
          onChange={this.changeTitle}
          label={I18n.get('Class name...')}
        />
        <TextFieldHelperText>{I18n.get('E.g.: 5B')}</TextFieldHelperText>
        <br />
        <TextField
          className={styles.textarea}
          value={description || ''}
          onChange={this.changeDescription}
          label={I18n.get('Class description...')}
          textarea
          fullwidth
        />
        <TextFieldHelperText>{I18n.get('E.g.: Mo 11am, We 10am, Fri 2pm')}</TextFieldHelperText>
        <br />
        <Select
          box="true"
          placeholder=""
          value={book}
          options={[{ label: 'BNE & Mathematik digital', value: 'mathe' }]}
          readOnly
        />
      </React.Fragment>
    );
  };

  render() {
    const {
      match: {
        params: { bookId, pageId },
      },
      loading,
      courses,
    } = this.props;

    if (loading) return null;

    const { isPublisher, isStudent } = Cache.getItem('userRoles');
    if (isPublisher) return <Redirect to={`/book/${bookId}/page/${pageId}`} />;

    if (isStudent) {
      const userCurrent = Cache.getItem('userCurrent');
      const { currentCourse } = userCurrent;

      if (currentCourse) {
        return <Redirect to={`/class/${currentCourse.id}/book/${bookId}/page/${pageId}`} />;
      }
      return <Redirect to="/join" />;
    }

    const {
      isOpen, title, description, book,
    } = this.state;

    const courseListItems = courses.map(
      ({
        title: courseTitle, code, description: courseDescription, id,
      }) => (
        <ListItem className={styles.listItem} onClick={this.onClick(id)} key={id}>
          <ListItemText>
            <ListItemPrimaryText>{courseTitle}</ListItemPrimaryText>
            <ListItemSecondaryText>{code}</ListItemSecondaryText>
            <ListItemSecondaryText>{courseDescription}</ListItemSecondaryText>
          </ListItemText>
        </ListItem>
      ),
    );

    return (
      <div className={styles.container}>
        <span className={styles.title}>{I18n.get('Select a class:')}</span>

        <List twoLine>
          {courseListItems}
          <ListItem className={styles.listItem} onClick={this.open}>
            <ListItemText>
              <ListItemPrimaryText>
                <div className={styles.addButton}>
                  <ButtonIcon icon="add" />
                  <span>{I18n.get('Create New Class')}</span>
                </div>
              </ListItemPrimaryText>
            </ListItemText>
          </ListItem>
        </List>
        <Dialog open={isOpen} onClose={this.close}>
          <DialogTitle>{I18n.get('Create New Class')}</DialogTitle>
          <DialogContent>{this.renderForm()}</DialogContent>
          <DialogActions>
            <DialogButton action="close">{I18n.get('Cancel')}</DialogButton>
            <DialogButton action="accept" raised onClick={this.addCourse(title, description, book)}>
              {I18n.get('Create Class')}
            </DialogButton>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default JumpTo;
