import gql from 'graphql-tag';

export default gql`
  mutation createRawCellAndInsert(
    $contents: String!
    $customCss: String
    $pageId: ID!
    $positionInPage: Int
  ) {
    createRawCellAndInsert(
      input: {
        contents: $contents
        customCss: $customCss
        pageId: $pageId
        positionInPage: $positionInPage
      }
    ) {
      id
      title
      subtitle
      cells {
        id
        visibility
        ... on RawCell {
          customCss
          contents
        }
      }
      editingPhase
      createdAt
      updatedAt
    }
  }
`;
