// @ts-check
import React from 'react';
import t from 'prop-types';
import { Typography } from 'rmwc/Typography';

import '@material/textfield/dist/mdc.textfield.css';
import '@material/theme/dist/mdc.theme.css';

import Cell from './Cell';
import './Audio.css';

const AudioCell = (props) => {
  const { uri, caption } = props;
  const startsWith = (str = '', search = '') => str.substr(0, search.length) === search;

  const audio = startsWith(uri, 'https://')
    ? uri
    : [
      process.env.REACT_APP_URL,
      process.env.REACT_APP_KLFA_S3URL,
      'static/audio',
      uri,
    ].join('/');

  return (
    <Cell {...props} type="audio">
      <audio controls>
        <track kind="captions" />
        <source src={audio} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      {caption ? (
        <Typography use="caption" className="kalfa-audio-caption">
          {caption}
        </Typography>
      ) : null}
    </Cell>
  );
};

AudioCell.propTypes = {
  uri: t.string.isRequired,
  caption: t.string,
};

AudioCell.defaultProps = {
  caption: null,
};

export default AudioCell;
