import React from 'react';
import t from 'prop-types';
import { TabBar, Tab } from 'rmwc/Tabs';
import { Typography } from 'rmwc/Typography';

import I18n from '../../../../util/i18n';
import ClassWall from '../ClassWallContainer';
import StudentList from '../StudentListWithContext';

import styles from './CourseSidebar.css';

import '@material/typography/dist/mdc.typography.css';
import '@material/tab-bar/dist/mdc.tab-bar.min.css';
import '@material/tab/dist/mdc.tab.min.css';
import '@material/tab-indicator/dist/mdc.tab-indicator.min.css';
import '@material/tab-scroller/dist/mdc.tab-scroller.min.css';

class CourseSidebar extends React.Component {
  static propTypes = {
    className: t.string,
    course: t.shape({
      image: t.string,
      code: t.string,
      id: t.string.isRequired,
    }).isRequired,
    loading: t.bool,
    courseId: t.string,
  };

  static defaultProps = {
    className: '',
    loading: true,
    courseId: '',
  };

  constructor() {
    super();
    this.state = {
      activeTab: 0,
    };
  }

  onTabChange = evt => this.setState({ activeTab: evt.detail.index });

  render() {
    const { activeTab } = this.state;
    const {
      course, className = '', loading, courseId,
    } = this.props;

    const getContents = (index, conentsClassName) => {
      if (!course.id) return null;

      switch (index) {
        case 0:
          return loading ? null : (
            <ClassWall
              courseId={courseId}
              activities={course.activities}
              className={conentsClassName}
            />
          );
        case 1:
          return <StudentList courseId={course.id} className={conentsClassName} />;
        default:
          return <h1>500 Invalid Index</h1>;
      }
    };

    return (
      <div className={`${styles.sidebar} ${className}`}>
        <div
          className={styles.sidebarImage}
          style={{ backgroundImage: `url(/images/${course.image})` }}
        />
        <div className={styles.tabsWrapper}>
          <TabBar activeTabIndex={activeTab} onActivate={this.onTabChange}>
            <Tab>{I18n.get('Class Wall')}</Tab>
            <Tab>{I18n.get('Students')}</Tab>
          </TabBar>
        </div>
        {/* <div className={styles.contentAndCodeWrapper}> */}
        {getContents(activeTab, styles.contentAndCodeWrapper)}
        {/* </div> */}
        <div className={styles.code}>
          <div className="klfa-dashed-line-short" />
          <Typography use="button">{I18n.get('classroom code')}:</Typography>
          <Typography use="headline3" tag="div">
            {course.code}
          </Typography>
        </div>
      </div>
    );
  }
}

export default CourseSidebar;
