import React, { Component } from 'react';
import { ButtonIcon } from 'rmwc/Button';
import t from 'prop-types';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, DialogButton,
} from 'rmwc/Dialog';

import I18n from '../../../util/i18n';
import Cache from '../../../util/cache';
import Storage from '../../../util/storage';
import urlParser from '../../../../node_modules/js-video-url-parser/dist/jsVideoUrlParser';

import ClassWallImageItem from './ClassWallImageItem';
import styles from './ClassWall.css';

import '@material/dialog/dist/mdc.dialog.css';
import '@material/button/dist/mdc.button.css';

const logger = console;

const URL_REGEX = /^http[s]:\/\//;

function stopPropagation(e) {
  e.stopPropagation();
}

function renderActivityContent(activity) {
  const { content, type = 'text' } = activity;
  const src = Storage.keyToUrl(content);

  if (type === 'image') {
    return <ClassWallImageItem src={src} />;
  }

  if (content.match(URL_REGEX)) {
    const parsedContent = urlParser.parse(content);
    if (parsedContent) {
      const { provider, id } = parsedContent;
      let iframeSrc;

      if (provider === 'youtube') {
        iframeSrc = `https://www.youtube.com/embed/${id}`;
      } else if (provider === 'vimeo') {
        iframeSrc = `https://player.vimeo.com/video/${id}`;
      }
      if (iframeSrc) {
        return (
          <iframe title={iframeSrc} src={iframeSrc} frameBorder="0" style={{ width: '100%' }} />
        );
      }
    }

    return (
      <a href={content} target="_blank" rel="noopener noreferrer">
        {content}
      </a>
    );
  }
  return <span>{content}</span>;
}

class ClassWall extends Component {
  static propTypes = {
    activityAppend: t.func.isRequired,
    activityDelete: t.func.isRequired,
    activities: t.arrayOf(
      t.shape({
        content: t.string,
        id: t.string,
      }),
    ),
    className: t.string,
  };

  static defaultProps = {
    activities: [],
    className: '',
  };

  constructor(props) {
    super(props);
    this.state = { text: '', imageUploadDialogIsOpen: false };
  }

  onSubmit = () => {
    const { activityAppend } = this.props;
    const { text } = this.state;

    activityAppend(text, 'html');
    this.setState({ text: '' });
  };

  onActivityDelete = index => () => {
    const { activityDelete } = this.props;
    activityDelete(index);
  };

  openImageUploadDialog = () => {
    this.setState({ imageUploadDialogIsOpen: true, isSaving: false });
  };

  closeImageUploadDialog = async (e) => {
    if (e && e.detail && e.detail.action === 'accept') {
      this.setState({ isSaving: true });
      await this.onUploadImage();
    }
    this.setState({ isSaving: false, imageUploadDialogIsOpen: false });
  };

  onUploadImage = async () => {
    const { activityAppend } = this.props;

    const file = this.imageUploader.files[0];

    if (!file) return;

    const { name, type: contentType } = file;

    if (contentType.match('image') !== null) {
      try {
        const key = await Storage.put(`activity-image-${name}`, file);
        activityAppend(key, 'image');
      } catch (err) {
        logger.error('Error while uploading file', err);
      }
    } else {
      // eslint-disable-next-line no-alert
      window.alert(I18n.get('you can only upload a picture'));
    }
  };

  onTextBoxChange = ({ target: { value } }) => {
    this.setState({ text: value });
  };

  onTextBoxKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.onSubmit();
      e.preventDefault();
    }
  };

  render() {
    const { activities = [], className } = this.props;
    const { isTeacher } = Cache.getItem('userRoles') || {};
    const { text, imageUploadDialogIsOpen, isSaving } = this.state;
    const reversedActivities = (activities || []).slice(0).reverse();
    const activityCount = reversedActivities.length;

    return (
      <div className={`${styles.classWall} ${className}`}>
        <div className={styles.sendButtonContainer}>
          <div className={styles.textareaContainer}>
            <textarea
              rows="2"
              value={text}
              onChange={this.onTextBoxChange}
              onKeyPress={this.onTextBoxKeyDown}
              onKeyDown={stopPropagation}
              placeholder={I18n.get('Class Wall Input Placeholder')}
            />
            <ButtonIcon onClick={this.onSubmit} icon="add" />
          </div>
          <div className={styles.uploadImageButton}>
            <ButtonIcon onClick={this.openImageUploadDialog} icon="image" />
          </div>
          {imageUploadDialogIsOpen ? (
            <Dialog open onClose={this.closeImageUploadDialog}>
              <DialogTitle>{I18n.get('Upload Image')}</DialogTitle>
              <DialogContent>
                <input
                  type="file"
                  accept="image/*"
                  onChange={this.onFileUpploadChange}
                  ref={(c) => {
                    this.imageUploader = c;
                  }}
                />
              </DialogContent>
              <DialogActions>
                <DialogButton action="close" isDefaultAction>
                  {I18n.get('Cancel')}
                </DialogButton>
                <DialogButton action="accept" raised disabled={isSaving}>
                  {isSaving ? I18n.get('Please wait') : I18n.get('Save')}
                </DialogButton>
              </DialogActions>
            </Dialog>
          ) : null}
        </div>
        <div id="activities" className={styles.activities}>
          {reversedActivities.map((activity, index) => (
            <div key={index} className={styles.activity}>
              {renderActivityContent(activity)}
              <ButtonIcon
                onClick={this.onActivityDelete(activityCount - index - 1)}
                icon="delete"
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default ClassWall;
