import React from 'react';
import t from 'prop-types';
import {
  TopAppBar, TopAppBarRow, TopAppBarSection, TopAppBarTitle,
} from 'rmwc/TopAppBar';
import { IconButton } from 'rmwc/IconButton';

import styles from './MobileTopAppBar.css';

import '@material/top-app-bar/dist/mdc.top-app-bar.css';
import '@material/button/dist/mdc.button.css';
import '@material/list/dist/mdc.list.css';

class MobileTopAppBar extends React.Component {
  static propTypes = {
    course: t.shape({
      title: t.string,
    }).isRequired,
    toggleSidebar: t.func.isRequired,
  };

  static defaultProps = {
    course: {}
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {
      course,
      toggleSidebar
    } = this.props;

    return (
      <TopAppBar className={styles.topAppBar} fixed>
        <TopAppBarRow>
          <TopAppBarSection alignStart>
            <IconButton icon="menu" onClick={toggleSidebar} />
            <TopAppBarTitle>{course.title || ''}</TopAppBarTitle>
          </TopAppBarSection>
        </TopAppBarRow>
      </TopAppBar>
    );
  }
}

export default MobileTopAppBar;