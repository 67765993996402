// @ts-check
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import compose from 'lodash/flowRight';

import Cache from '../../../util/cache';
import QueryCourseGet from '../../../GraphQL/QueryCourseGet';
import QueryPageGet from '../../../GraphQL/QueryPageGet';
import MutationAnswerUpdate from '../../../GraphQL/MutationAnswerUpdate';
import SubscriptionAnswerUpdated from '../../../GraphQL/SubscriptionAnswerUpdated';
import client from '../../../util/getGraphQLClient';

import component from './PageView';

const updateQuery = (
  prev,
  {
    subscriptionData: {
      data: { answerUpdated },
    },
  },
) => {
  const prevCells = cloneDeep(prev.pageGet.cells);

  const {
    userId, cellId, answers = [], user,
  } = answerUpdated;

  const { id: currentUserId } = Cache.getItem('userCurrent');

  const cellIds = prevCells.map(cell => cell.id);
  const cellIndex = cellIds.indexOf(cellId);

  if (cellIndex < 0) return prev; // not on this page

  const prevCell = prevCells[cellIndex] || {};

  const prevAnswers = prevCell.answers || [];
  const answerIndex = prevAnswers.map(a => a.user.id).indexOf(userId);

  if (answerIndex < 0) {
    // new customer
    prevAnswers.push({
      user,
      answers,
      __typename: 'Answer',
    });
  } else {
    const prevAnswer = prevAnswers[answerIndex];
    const existingAnswers = prevAnswer.answers;

    answers.forEach((newAD) => {
      const {
        answerType: newAT, index: newI, data: newD, isReady: newIR,
      } = newAD;

      // if answer exists, update it, if not, add it
      const offset = existingAnswers.find(element => (
        element.answerType === newAT
        && element.index === newI
      ));

      if (offset >= 0) {
        existingAnswers[offset] = { ...existingAnswers[offset], data: newD, isReady: newIR };
      } else {
        existingAnswers.push(newAD);
      }
    });
  }

  if (userId === currentUserId) {
    // update myAnswer, too
    let prevMyAnswerData = (prevCell.myAnswer || {}).answers || [];

    answers.forEach((newAD) => {
      const {
        answerType: newAT, index: newI, data: newD, isReady: newIR,
      } = newAD;

      const matchedAD = prevMyAnswerData.filter(
        prevAD => prevAD.answerType === newAT && prevAD.index === newI,
      );

      if (matchedAD.length) {
        prevMyAnswerData = prevMyAnswerData.map((prevAD) => {
          const { answerType: prevAT, index: prevI } = prevAD;
          if (newAT !== prevAT) return prevAD;
          if (newI !== prevI) return prevAD;
          return { ...prevAD, data: newD, isReady: newIR };
        });
      } else {
        prevMyAnswerData = prevMyAnswerData.slice(0); // create a copy to refresh
        prevMyAnswerData.push(newAD);
      }
    });

    prevCell.myAnswer = { ...prevCell.myAnswer, answers: prevMyAnswerData, __typename: 'Answer' };
  }

  return {
    ...prev,
    pageGet: { ...prev.pageGet, cells: prevCells },
  };
};

export default compose(
  withRouter,
  graphql(QueryPageGet, {
    // @ts-ignore
    props: ({ ownProps, data: { pageGet, subscribeToMore } }) => {
      const { isTeacher = false } = Cache.getItem('userRoles') || {};
      const course = client.readQuery({
        query: QueryCourseGet,
        variables: {
          // @ts-ignore
          courseId: ownProps.courseId,
        },
      });

      const students = get(course, 'courseGet.students', []).map(({ user: { id } }) => id);

      const filterAnswers = cell => get(cell, 'answers', []).filter((answer) => {
        const id = get(answer, 'user.id');
        return students.indexOf(id) > -1;
      });

      const subscribeToAnswerUpdated = () => subscribeToMore({
        document: SubscriptionAnswerUpdated,
        // @ts-ignore
        variables: { courseId: ownProps.courseId },
        updateQuery,
      });

      const page = {
        ...pageGet,
        cells: ((pageGet || {}).cells || []).map(cell => ({
          ...cell,
          answers: isTeacher ? filterAnswers(cell) : [],
        })),
      };

      return { page, subscribeToAnswerUpdated };
    },
  }),
  graphql(MutationAnswerUpdate, {
    props: ({ mutate, ownProps }) => ({
      answerUpdate: ({
        cellId, answerType, index, data, isReady,
      }) => {
        mutate({
          variables: {
            cellId,
            answerType,
            index,
            data,
            isReady,
          },
          update: (proxy) => {
            // @ts-ignore
            const query = proxy.readQuery({
              query: QueryPageGet,
              variables: { pageId: ownProps.pageId },
            });
            const userCurrent = Cache.getItem('userCurrent') || {};
            const userId = userCurrent.id;

            const cells = query.pageGet.cells.map((cell) => {
              if (cell.id !== cellId) return cell;
              const answers = cell.answers.map((a) => {
                if (a.user.id !== userId) return a;
                const answerDatas = a.answers.map((ad) => {
                  if (ad.index === index && ad.answerType === answerType) return { ...ad, data, isReady: !!isReady };
                  return ad;
                });
                return { ...a, answers: answerDatas };
              });

              let myAnswerDatas;

              const [myCurrentAnswer] = cell.myAnswer.answers.filter(
                ad => ad.index === index && ad.answerType === answerType,
              );

              if (myCurrentAnswer) {
                myAnswerDatas = cell.myAnswer.answers.map((ad) => {
                  if (ad.index === index && ad.answerType === answerType) return { ...ad, data, isReady: !!isReady };
                  return ad;
                });
              } else {
                myAnswerDatas = cell.myAnswer.answers;
                myAnswerDatas.push({
                  answerType,
                  index,
                  data,
                  isReady: !!isReady,
                  __typename: 'AnswerData',
                });
              }

              return { ...cell, answers, myAnswer: { ...cell.myAnswer, answers: myAnswerDatas } };
            });

            proxy.writeQuery({
              query: QueryPageGet,
              // @ts-ignore
              variables: { pageId: ownProps.pageId },
              data: { ...query, pageGet: { ...query.pageGet, cells } },
            });
          },
        });
      },
    }),
  }),
)(component);
