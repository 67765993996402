// @ts-check
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Button } from 'rmwc/Button';
import { TextField } from 'rmwc/TextField';
import t from 'prop-types';

import '@material/textfield/dist/mdc.textfield.min.css';

import Cache from '../../util/cache';
import I18n from '../../util/i18n';

import './Component.css';

class JoinCourse extends Component {
  static propTypes = {
    onJoinCourse: t.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { code: '' };

    this.handleChange = this.handleChange.bind(this);
  }

  handleJoinCourse = () => {
    const { onJoinCourse } = this.props;
    const { code } = this.state;
    return onJoinCourse(code);
  };

  handleChange = (event) => {
    this.setState({ code: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.handleJoinCourse();
    return false;
  };

  render() {
    const { currentCourse } = Cache.getItem('userCurrent') || {};
    if (currentCourse) {
      return <Redirect to={`/class/${currentCourse.id}`} />;
    }
    const { code } = this.state;

    return (
      <div className="klfa-join-course-container">
        <form onSubmit={this.handleSubmit}>
          <TextField
            value={code || ''}
            onChange={this.handleChange}
            autoFocus
            withLeadingIcon="lock"
            label="Classroom code..."
            type="number"
          />
          {/* <TextFieldHelperText persistent>Optional help text.</TextFieldHelperText> */}
          <div>
            <Button raised onClick={this.handleJoinCourse}>
              {I18n.get('Enter Classroom')}
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default JoinCourse;
