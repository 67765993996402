import gql from 'graphql-tag';

export default gql`
  query bookGet($bookId: ID!) {
    bookGet(id: $bookId) {
      id
      customCss
      customJs
    }
  }
`;
