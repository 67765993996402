import { graphql } from 'react-apollo';
import compose from 'lodash/flowRight';

import QueryBookList from '../../GraphQL/QueryBookList';

import component from './AllInCards';

export default compose(
  graphql(QueryBookList, {
    props: (props) => {
      const {
        data: { bookList: books },
      } = props;
      return { books };
    },
  }),
)(component);
