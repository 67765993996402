import React from 'react';

import CourseContext from './CourseContext';
import Component from './StudentList';

export default props => (
  <CourseContext.Consumer>
    {(c = {}) => <Component {...props} students={(c && c.course && c.course.students) || []} />}
  </CourseContext.Consumer>
);
