import React from 'react';
import t from 'prop-types';
import { TextField } from 'rmwc/TextField';

import Cache from '../../util/cache';
import Hub from '../../util/hub';
import { essayValidator } from '../../util/cellAnswersValidator';

import Cell from './Cell';
import CellAnswers from './CellAnswers';
import './Essay.css';

import '@material/theme/dist/mdc.theme.css';
import '@material/textfield/dist/mdc.textfield.css';

const logger = console;

class Essay extends React.Component {
  static propTypes = {
    statement: t.string,
    text: t.string,
    validation: t.oneOfType([
      t.shape({
        operator: t.oneOf(['OR', 'AND']),
        min: t.number,
        containsAny: t.arrayOf(t.string),
        matchRegex: t.number,
      }),
      t.oneOf(['any', 'none']),
    ]),
    label: t.string,
    rows: t.number,
    value: t.string,
  };

  static defaultProps = {
    statement: null,
    text: '',
    validation: 'any',
    label: null,
    rows: 1,
    value: '',
  };

  constructor(props) {
    super(props);
    const { value: initialValue } = props;

    const { registerWithCell, answerUpdate } = window.poormansContextReplacement;
    const {
      cellId, rank, initialStateJson, answers, initialIsReady, course,
    } = registerWithCell(
      'essay',
    );

    this.rank = rank;
    this.course = course;
    this.answerUpdate = answerUpdate;

    Hub.listen(`${cellId}::essay::${rank}::own`, this);
    Hub.listen(`${cellId}::essay::${rank}::others`, this);

    try {
      this.state = {
        value: (initialStateJson && JSON.parse(`${initialStateJson}`)) || initialValue || '',
        answers,
        isEditing: false,
        isReady: initialIsReady,
      };
    } catch (e) {
      this.state = { value: '', isEditing: false };
      logger.error('Invalid JSON', initialStateJson, e);
    }
  }

  // componentWillUnmount() {
  //   this.onBlur();
  // }

  onHubCapsule(capsule) {
    const { channel } = capsule;
    if (channel.endsWith('::own')) {
      return this.onOwnAnswerCapsule(capsule);
    }
    if (channel.endsWith('::others')) {
      return this.onOthersAnswerCapsule(capsule);
    }
    logger.error('Unknown channel for capsule', capsule);
    return null;
  }

  onOwnAnswerCapsule(capsule) {
    const {
      payload: { data, isReady },
    } = capsule;
    const { isEditing } = this.state;

    if (!isEditing) {
      // The chap is editing. I am leaving him alone
      try {
        this.setState({
          value: (data && JSON.parse(`${data}`)) || '',
          isReady,
        });
      } catch (e) {
        logger.error('Invalid JSON', data, e);
      }
    }
  }

  onOthersAnswerCapsule(capsule) {
    const { payload: answers } = capsule;
    this.setState({ answers });
  }

  onChange = (e) => {
    const self = this;
    const { value } = (e || {}).target || {};
    this.setState({ value });

    if (this.timer) clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      const { savedValue } = self;
      if (value === savedValue) return;
      self.answerUpdate('essay', self.rank, JSON.stringify(value));
    }, 3000);
  };

  onBlur = (e) => {
    const { value } = (e || {}).target || {};
    const { savedValue } = this;
    this.setState({ isEditing: false, value });
    if (value === savedValue) return;

    this.answerUpdate('essay', this.rank, JSON.stringify(value));
    this.savedValue = value;
  };

  onFocus = () => {
    this.setState({ isEditing: true });
  };

  toggleIsReady = () => {
    const { isReady } = this.state;
    this.answerUpdate('essay', this.rank, undefined, !isReady);
    this.setState({ isReady: !isReady });
  };

  render() {
    const {
      props: {
        statement, label, rows, validation, placeholder, noCheckbox
      },
      state: { answers, value, isReady },
    } = this;
    const { isTeacher } = Cache.getItem('userRoles') || {};

    const isCorrect = essayValidator(validation, value);

    let isReadyStr = isReady ? 'yes' : 'no';
    if (!value) isReadyStr = 'disabled';

    return (
      <Cell
        {...this.props}
        isReady={isReadyStr}
        toggleIsReady={this.toggleIsReady}
        isCorrect={isCorrect}
        noCheckbox={noCheckbox}
        type="essay"
      >
        <div className="kalfa-cell-statement kalfa-essay-statement">{statement}</div>
        <div className="kalfa-essay-textfield">
          <TextField
            textarea={rows > 1}
            fullwidth
            label={label}
            rows={rows}
            onChange={this.onChange}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            value={value}
            onKeyDown={e => e.stopPropagation()}
            placeholder={placeholder}
          />
        </div>
        {isTeacher && (
          <CellAnswers answers={answers} course={this.course} type="essay" {...this.props} />
        )}
      </Cell>
    );
  }
}

export default Essay;
