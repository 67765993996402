import { graphql, withApollo } from 'react-apollo';

import compose from 'lodash/flowRight';

import MutationDeletePage from '../../../GraphQL/MutationDeletePage';
import MutationRemoveChapterOrPageFromBook from '../../../GraphQL/MutationRemoveChapterOrPageFromBook';

import component from './RightClickMenu';

export default compose(
  withApollo,
  graphql(MutationDeletePage, {
    props: ({
      mutate, ownProps: {
        pageId, pageIndex, bookId, client,
      },
    }) => ({
      onDelete: () => {
        mutate({
          variables: {
            pageId,
          },
          update: () => {
            client.mutate({
              mutation: MutationRemoveChapterOrPageFromBook,
              variables: {
                bookId,
                chapterOrPageIndex: pageIndex,
              },
            });
          },
        });
      },
    }),
  }),
)(component);
