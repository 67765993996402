// @ts-check
import { graphql } from 'react-apollo';

import compose from 'lodash/flowRight';

import MutationAnswerUpdate from '../../../../GraphQL/MutationAnswerUpdate';
import QueryCourseGet from '../../../../GraphQL/QueryCourseGet';

import component from './Component';

const createNewAnswerData = data => ({
  answerType: 'essay',
  data,
  index: 0,
  isReady: false,
  __typename: 'AnswerData',
  typename: 'AnswerData',
});

export default compose(
  graphql(MutationAnswerUpdate, {
    props: ({ mutate, ownProps }) => ({
      answerUpdate: ({ data, isReady }) => {
        mutate({
          variables: {
            // @ts-ignore
            cellId: ownProps.cellId,
            answerType: 'essay',
            index: 0,
            data,
            isReady,
          },
          update: (proxy) => {
            let query;

            try {
              // @ts-ignore
              query = proxy.readQuery({
                query: QueryCourseGet,
                variables: { courseId: ownProps.courseId },
              });
            } catch (e) {
              console.error('e :', e);
              return;
            }

            const { courseGet } = query;
            const { courseCells = [] } = courseGet.courseCells;

            const cellIds = courseCells.map(cell => cell.id);
            // @ts-ignore
            const cellIndex = cellIds.indexOf(ownProps.cellId);

            if (cellIndex < 0) return;

            const cell = courseCells[cellIndex];

            if (!cell.myAnswer) {
              cell.myAnswer = {
                answers: [createNewAnswerData(data)],
                __typename: 'Answer',
              };
            } else if (cell.myAnswer.answers[0]) {
              cell.myAnswer.answers[0].data = data;
            } else {
              cell.myAnswer.answers[0] = createNewAnswerData(data);
            }

            courseCells[cellIndex] = cell;

            proxy.writeQuery({
              query: QueryCourseGet,
              // @ts-ignore
              variables: { courseId: ownProps.courseId },
              data: {
                ...query,
                courseGet: {
                  ...query.courseGet,
                  courseCells,
                },
              },
            });
          },
        });
      },
    }),
  }),
)(component);
