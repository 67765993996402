import React from 'react';
import t from 'prop-types';
// import { Redirect } from 'react-router-dom';
import { Typography } from 'rmwc/Typography';
import { Grid, GridCell } from 'rmwc/Grid';

import '@material/theme/dist/mdc.theme.css';
import '@material/button/dist/mdc.button.css';
import '@material/icon-button/dist/mdc.icon-button.css';
import '@material/typography/dist/mdc.typography.css';
import '@material/linear-progress/dist/mdc.linear-progress.css';
import '@material/layout-grid/dist/mdc.layout-grid.css';

import I18n from '../../util/i18n';
import PleaseWait from '../PleaseWait';

import styles from './AllInCards.css';
import AddNewCourseButton from './AddNewCourseButtonContainer';
import Course from './CourseCardContainer';

const Courses = ({ courses, loading }) => {
  if (loading) return <PleaseWait />;

  const cards = courses.map(
    ({
      id, title, image, code, students = {}, teachers, books, currentPosition, description,
    }) => (
      <GridCell span="4" key={id}>
        <Course
          id={id}
          title={title}
          image={image}
          organisation={teachers[0].organisation}
          students={students}
          teacher={teachers[0]}
          book={books[0]}
          currentPosition={currentPosition}
          code={code}
          description={description}
        />
      </GridCell>
    ),
  );

  return (
    <React.Fragment>
      <div className={styles.topClasses}>
        <Typography use="button" tag="h1">
          {I18n.get('Classes')}
        </Typography>
        <AddNewCourseButton />
      </div>
      <Grid>{cards}</Grid>
    </React.Fragment>
  );
};

Courses.propTypes = {
  courses: t.arrayOf(
    t.shape({
      id: t.string,
      title: t.string,
      image: t.string,
      organisation: t.string,
      students: t.array,
      teachers: t.array,
      books: t.array,
      currentPosition: t.shape({
        bookId: t.string,
        pageId: t.string,
      }),
    }),
  ).isRequired,
  loading: t.bool.isRequired,
};

export default Courses;
