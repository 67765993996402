import gql from 'graphql-tag';

export default gql`
  mutation createPage($title: String!) {
    createPage(input: { title: $title }) {
      id
      title
    }
  }
`;
