import React from 'react';
import t from 'prop-types';
import { Button, ButtonIcon } from 'rmwc/Button';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, DialogButton,
} from 'rmwc/Dialog';
import Loadable from 'react-loadable';

import I18n from '../../util/i18n';
import PleaseWait from '../PleaseWait';

import '@material/button/dist/mdc.button.css';
import '@material/dialog/dist/mdc.dialog.css';

// import CodeAndStyleEditor from '../CellEditor/CodeAndStyleEditor';
const CodeAndStyleEditor = Loadable({
  loader: () => import(/* webpackChunkName: "CodeAndStyleEditor" */ '../CellEditor/CodeAndStyleEditor'),
  loading: PleaseWait,
});

class EditCustomCssAndCodeButtonAndDialog extends React.Component {
  static propTypes = {
    onSave: t.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  onAccept = () => {
    const { onSave } = this.props;
    const { code: customJs, css: customCss } = this.state;
    onSave({
      customCss,
      customJs,
    });
  };

  onCodeChange = (code) => {
    this.setState({ code });
  };

  onCssChange = (css) => {
    this.setState({ css });
  };

  static getDerivedStateFromProps(props, prevState) {
    if (!prevState || (!prevState.code && !prevState.css)) {
      const { code, css } = props;
      return { code, css };
    }
    return null;
  }

  open = () => {
    this.setState({ open: true });
  };

  close = () => {
    this.setState({ open: false });
  };

  render() {
    const { open, code, css } = this.state;
    return (
      <React.Fragment>
        <Button onClick={this.open}>
          <ButtonIcon icon="palette" /> {I18n.get('Edit Theme')}
        </Button>
        <Dialog open={open} onClose={this.close}>
          <DialogTitle>{I18n.get('Edit Theme and Custom Code')}</DialogTitle>
          <DialogContent>
            <CodeAndStyleEditor
              code={code}
              css={css}
              onCodeChange={this.onCodeChange}
              onCssChange={this.onCssChange}
            />
          </DialogContent>
          <DialogActions>
            <DialogButton action="close" isDefaultAction>
              {I18n.get('Cancel')}
            </DialogButton>
            <DialogButton action="accept" raised onClick={this.onAccept}>
              {I18n.get('Save')}
            </DialogButton>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default EditCustomCssAndCodeButtonAndDialog;
