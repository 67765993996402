const isMultipleWrapper = (answers, isMultiple, singleValidator) => {
  const isValid = (isMultiple ? answers : [answers]).map(a => singleValidator(a));
  return isMultiple ? isValid : isValid[0];
};

export const quizValidator = (validation, answers, isMultiple = false) => isMultipleWrapper(answers, isMultiple, (a) => {
  if (validation === 'none') {
    return true;
  }
  if (validation === 'any') {
    return a.length > 0;
  }
  return a && a.toString() === validation.toString();
});

export const essayValidator = (validation, answers, isMultiple = false) => isMultipleWrapper(answers, isMultiple, (a) => {
  if (validation === 'none') {
    return true;
  }
  if (validation === 'any') {
    return a.length > 0;
  }
  let isMin;
  let matchRegex;
  let contains;

  if (typeof validation.min === 'number' && validation.min > 0) {
    isMin = a.length >= validation.min;
  }
  if (typeof validation.regex === 'object' && validation.regex.constructor === RegExp) {
    matchRegex = validation.regex.test(a);
  }
  if (
    typeof validation.containsAny === 'object'
      && validation.containsAny.constructor === Array
  ) {
    const cleanedValue = a
      .replace(/[.,]/g, ' ')
      .split(' ')
      .filter(notNull => notNull);
    contains = validation.containsAny.some(v => cleanedValue.indexOf(v) !== -1);
  }

  if (validation.operator === 'OR') {
    return isMin || matchRegex || contains || false;
  }
  // default operator is AND
  if (typeof isMin === 'undefined') isMin = true;
  if (typeof matchRegex === 'undefined') matchRegex = true;
  if (typeof contains === 'undefined') contains = true;
  return isMin && matchRegex && contains;
});

export const dragAndSortValidator = (answers, isMultiple = false) => isMultipleWrapper(answers, isMultiple, (a) => {
  if (!a) return false;
  for (let i = 0; i < a.length - 1; i++) {
    if (a[i].rank > a[i + 1].rank) {
      return false;
    }
  }
  return true;
});

export const dragToBucketValidator = (validation, items, answers, isMultiple = false) => isMultipleWrapper(answers, isMultiple, (a = {}) => {
  if (!validation) return true;
  if (validation === 'none') return true;
  if (validation === 'any' && a) return true;
  let isCorrect = true;
  if (!a) return false;

  Object.keys(a).forEach((bucketName) => {
    a[bucketName].forEach((item) => {
      if (items[item].belongsTo !== bucketName) {
        isCorrect = false;
      }
    });
  });
  return isCorrect;
});

export const paintValidator = (validation, answers, isMultiple = false) => isMultipleWrapper(answers, isMultiple, (a) => {
  if (validation === 'none') {
    return true;
  }
  if (validation === 'any') {
    return typeof a !== 'undefined';
  }
  return false;
});

export default {
  quizValidator,
  essayValidator,
  dragAndSortValidator,
  dragToBucketValidator,
  paintValidator,
};
