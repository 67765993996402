import { withApollo, graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import compose from 'lodash/flowRight';

import QueryPageGet from '../../GraphQL/QueryPageGet';
import MutationCellDelete from '../../GraphQL/MutationCellDelete';

import component from './PageEditor';

export default compose(
  withApollo,
  graphql(QueryPageGet, {
    props: ({ data: { loading, pageGet }, ownProps }) => ({
      ...ownProps,
      loading,
      page: pageGet || { id: '' },
    }),
  }),
  graphql(MutationCellDelete, {
    props: ({ mutate, ownProps }) => ({
      deleteCell: () => mutate({
        variables: {
          id: ownProps.id,
        },
        optimisticResponse: () => ({
          courseCellDelete: {
            id: ownProps.id,
            contents: '',
            __typename: 'CourseCell',
          },
        }),
        refetchQueries: [{ query: QueryPageGet, variables: { courseId: ownProps.pageId } }],
      }),
    }),
  }),
  withRouter,
)(component);
