import React from 'react';
import t from 'prop-types';
import { Typography } from 'rmwc/Typography';

import '@material/textfield/dist/mdc.textfield.css';
import '@material/theme/dist/mdc.theme.css';

import I18n from '../../util/i18n';

import Cell from './Cell';
import './Video.css';

const VideoCell = (props) => {
  const { uri, alt, caption = '' } = props;
  const startsWith = (str = '', search = '') => str.substr(0, search.length) === search;

  const video = startsWith(uri, 'https://')
    ? uri
    : [
      process.env.REACT_APP_URL,
      process.env.REACT_APP_KLFA_S3URL,
      'static/video',
      uri,
    ].join('/');

  return (
    <Cell {...props} type="video">
      <video controls alt={alt}>
        <track kind="captions" />
        <source src={video} type="video/mp4" />
        Your browser does not support the video element.
      </video>
      {caption ? (
        <Typography use="caption" className="kalfa-video-caption">
          {caption}
        </Typography>
      ) : null}
    </Cell>
  );
};

VideoCell.propTypes = {
  caption: t.string,
  alt: t.string,
  uri: t.string.isRequired,
};

VideoCell.defaultProps = {
  caption: null,
  alt: I18n.get('Video'),
};

export default VideoCell;
