import React, { Component } from 'react';
import t from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';

import BucketItem from './BucketItem';
import styles from './Bucket.css';

class Bucket extends Component {
  render() {
    const {
      name, items, allItems, widthPercentage,
    } = this.props;

    return (
      <Droppable droppableId={name}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{
              backgroundColor: snapshot.isDraggingOver ? 'yellow' : 'white',
              flexBasis: widthPercentage,
            }}
            className={styles.bucket}
          >
            <div>{name}</div>
            {items.map((i, index) => (
              <BucketItem contents={allItems[i].contents} index={index} key={index} />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  }
}

Bucket.propTypes = {
  name: t.string,
  children: t.oneOfType([t.arrayOf(t.node), t.node]),
  items: t.arrayOf(t.string).isRequired,
  allItems: t.shape({}).isRequired,
  widthPercentage: t.number,
};

Bucket.defaultProps = {
  name: '',
  children: null,
  widthPercentage: 100,
};

export default Bucket;
