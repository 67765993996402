import React, { Component } from 'react';
import { TextField } from 'rmwc/TextField';
import t from 'prop-types';

import I18n from '../../../util/i18n';
import Storage from '../../../util/storage';

import '@material/textfield/dist/mdc.textfield.css';
import styles from './Upload.css';

const logger = console;

function stopPropagation(e) {
  e.stopPropagation();
}

class Edit extends Component {
  static propTypes = {
    uri: t.string.isRequired,
    acceptedTypes: t.string.isRequired,
    updateContents: t.func.isRequired,
    toggleSaveButton: t.func.isRequired,
  };

  onFileUpploadChange = async (e) => {
    const { updateContents, toggleSaveButton } = this.props;
    const { files } = e.target;
    const { name, type: contentType } = files[0];
    try {
      toggleSaveButton();
      const key = await Storage.put(`${name}`, files[0], {
        contentType,
      });
      updateContents(key);
      toggleSaveButton();
    } catch (err) {
      logger.error('Error while uploading file', err);
      toggleSaveButton();
    }
  };

  handleCahnge = (event) => {
    const { updateContents } = this.props;
    updateContents(event.target.value);
  };

  render() {
    const { uri, acceptedTypes } = this.props;

    return (
      <div className={styles.container}>
        <TextField
          fullwidth
          defaultValue={uri}
          onChange={this.handleCahnge}
          onKeyDown={stopPropagation}
          placeholder={I18n.get('Paste the link here')}
        />
        <br />
        {I18n.get('Or')}
        &nbsp;
        <input type="file" accept={acceptedTypes} onChange={this.onFileUpploadChange} />
      </div>
    );
  }
}
export default Edit;
