import gql from 'graphql-tag';

export default gql`
  mutation courseCreate($title: String!, $description: String, $image: String, $books: [String!]!) {
    courseCreate(title: $title, description: $description, image: $image, books: $books) {
      id
      title
      description
      image
      books {
        id
        title
      }
    }
  }
`;
