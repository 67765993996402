import gql from 'graphql-tag';

export default gql`
  mutation appendPageToBook($bookId: ID!, $pageId: ID!) {
    appendPageToBook(input: { id: $bookId, pageId: $pageId }) {
      id
      pages {
        id
        title
        subtitle
      }
    }
  }
`;
