import React, { Component } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, DialogButton,
} from 'rmwc/Dialog';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import ReactDom from 'react-dom';
import t from 'prop-types';

import I18n from '../../../util/i18n';
import Cache from '../../../util/cache';
import Storage from '../../../util/storage';
import Hub from '../../../util/hub';
import { paintValidator } from '../../../util/cellAnswersValidator';
import Cell from '../Cell';
import CellAnswers from '../CellAnswers';

import './Paint.css';
import drawerConfig from './drawerConfig';

import '@material/dialog/dist/mdc.dialog.css';
import '@material/button/dist/mdc.button.css';

const logger = console;

function dataURItoBlob(dataURI) {
  const byteString = atob(dataURI.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab]);
}

function saveToCloud(key, body, contentType) {
  return Storage.put(key, body, { contentType });
}

class Paint extends Component {
  static propTypes = {
    validation: t.oneOfType([t.number, t.arrayOf(t.number), t.oneOf(['any', 'none'])]),
  };

  static defaultProps = {
    validation: 'any',
  };

  constructor() {
    super();
    const { registerWithCell, answerUpdate } = window.poormansContextReplacement;

    const {
      cellId, rank, initialStateJson, answers, initialIsReady, course,
    } = registerWithCell(
      'paint',
    );

    Hub.listen(`${cellId}::paint::${rank}::own`, this);
    Hub.listen(`${cellId}::paint::${rank}::others`, this);

    this.id = `${cellId}-${rank}`;
    this.drawer = null;
    this.rank = rank;
    this.answerUpdate = answerUpdate;
    this.course = course;
    this.initialStateJson = initialStateJson;

    this.state = {
      canvas: null,
      answers,
      isOpen: false,
      isReady: initialIsReady,
    };

    if (initialStateJson) {
      const { imageKey = null, timestamp } = JSON.parse(initialStateJson);
      this.state.image = imageKey && Storage.keyToUrl(imageKey, timestamp);
    }
  }

  componentDidMount() {
    this.loadState(this.initialStateJson);
  }

  componentWillUnmount() {
    const { isOpen } = this.state;
    if (isOpen) {
      this.saveCurrentState().then(() => this.drawer.destroy());
    }
    clearAllBodyScrollLocks();
  }

  onHubCapsule(capsule) {
    const { channel } = capsule;
    if (channel.endsWith('::own')) {
      return this.onOwnAnswerCapsule(capsule);
    }
    if (channel.endsWith('::others')) {
      return this.onOthersAnswerCapsule(capsule);
    }
    logger.error('Unknown channel for capsule', capsule);
    return null;
  }

  onOwnAnswerCapsule(capsule) {
    const {
      payload: { data, isReady },
    } = capsule;
    if (data) this.loadState(data, isReady);
  }

  onOthersAnswerCapsule(capsule) {
    const { payload: answers } = capsule;
    this.setState({ answers });
  }

  open = () => {
    // const { id } = this.props;
    const { canvas } = this.state;

    this.setState({
      isOpen: true,
    });
    setTimeout(() => {
      disableBodyScroll(this.dialog);
      // eslint-disable-next-line react/no-find-dom-node
      const dialog = ReactDom.findDOMNode(this.dialog).children[0].children[0];
      const width = dialog.getBoundingClientRect().width - 48;
      this.drawer = new window.DrawerJs.Drawer(null, drawerConfig, width, window.innerHeight - 230);
      window.$(`#canvas-editor-${this.id}`).append(this.drawer.getHtml());
      window.$('body').addClass('body-paint-workaround');
      this.drawer.onInsert();
      this.drawer.api.startEditing();
      this.drawer.api.loadCanvasFromData(canvas);
    }, 750);
  };

  close = () => {
    this.setState({
      isOpen: false,
    });

    this.saveCurrentState();

    window.$('body').removeClass('body-paint-workaround');
    enableBodyScroll(this.dialog);
    this.forceUpdate();
  };

  toggleIsReady = () => {
    const { isReady } = this.state;
    this.answerUpdate('paint', this.rank, undefined, !isReady);
    this.setState({ isReady: !isReady });
  };

  async loadState(state, isReady) {
    if (!state) return;
    const { canvasKey, imageKey, timestamp } = JSON.parse(state);
    if (imageKey) this.setState({ image: Storage.keyToUrl(imageKey, timestamp) });
    if (canvasKey) {
      const canvas = await Storage.getJSON(canvasKey, timestamp);
      const newState = { canvas };
      if (typeof isReady !== 'undefined') newState.isReady = isReady;
      this.setState(newState);
    }
  }

  async saveCurrentState() {
    const canvas = this.drawer.api.getCanvasAsJSON();
    const image = this.drawer.api.getCanvasAsImage();
    const timestamp = Date.now();

    this.setState({
      canvas,
      image,
    });

    this.drawer.destroy();

    return Promise.all([
      saveToCloud(`drawing-${this.id}.json`, canvas, 'application/json'),
      saveToCloud(`drawing-${this.id}.png`, dataURItoBlob(image), 'image/png'),
    ]).then(([canvasKey, imageKey]) => this.answerUpdate(
      'paint',
      this.rank,
      JSON.stringify({
        canvasKey,
        imageKey,
        timestamp,
      }),
    ));
  }

  render() {
    const {
      isOpen, image, answers, canvas, isReady,
    } = this.state;

    const { isTeacher } = Cache.getItem('userRoles') || {};
    const { validation } = this.props;

    const isCorrect = paintValidator(validation, canvas);

    let isReadyStr = isReady ? 'yes' : 'no';
    if (!canvas) isReadyStr = 'disabled';

    /* eslint-disable jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */
    return (
      <React.Fragment>
        <Cell
          {...this.props}
          isReady={isReadyStr}
          toggleIsReady={this.toggleIsReady}
          type="paint"
          isCorrect={isCorrect}
          style={{ height: 'auto !important' }}
        >
          {image ? (
            <img
              onClick={this.open}
              src={image}
              style={{
                minHeight: '100px',
                maxWidth: '100%',
                height: 'auto',
                width: '100%',
              }}
              alt="drawing pad"
            />
          ) : (
            <div
              onClick={this.open}
              style={{
                background: 'url(/images/noun_Draw_79261_000000.svg)',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                minHeight: '100px',
                height: '100%',
                opacity: '.5',
              }}
              role="button"
              tabIndex={0}
            />
          )}
          <Dialog
            open={isOpen}
            onClose={this.close}
            ref={(c) => {
              this.dialog = c;
            }}
          >
            <DialogTitle>{I18n.get('Drawing Pad')}</DialogTitle>
            <DialogContent>
              <div style={{ height: window.innerHeight - 230 }}>
                <div id={`canvas-editor-${this.id}`} style={{ position: 'relative' }} />
              </div>
            </DialogContent>
            <DialogActions>
              <DialogButton raised action="accept" isDefaultAction>
                {I18n.get('Save')}
              </DialogButton>
            </DialogActions>
          </Dialog>
        </Cell>
        {isTeacher && (
          <CellAnswers answers={answers} course={this.course} type="paint" {...this.props} />
        )}
      </React.Fragment>
    );
    /* eslint-enable jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */
  }
}

export default Paint;
