import React from 'react';
import t from 'prop-types';
import { Button } from 'rmwc/Button';
import Loadable from 'react-loadable';

import '@material/button/dist/mdc.button.css';

import I18n from '../../util/i18n';
import RawCell from '../Cells/Raw';
import PleaseWait from '../PleaseWait';

// import CodeAndStyleEditor from './CodeAndStyleEditor';
import styles from './EditorRawCell.css';

const CodeAndStyleEditor = Loadable({
  loader: () => import(/* webpackChunkName: "CodeAndStyleEditor" */ './CodeAndStyleEditor'),
  loading: PleaseWait,
});

class EditorRawCell extends React.Component {
  static propTypes = {
    cell: t.shape({
      id: t.string,
      contents: t.string,
      visibility: t.oneOf(['all', 'teacher']),
    }),
    onGoBack: t.func.isRequired,
    onSave: t.func.isRequired,
  };

  static defaultProps = {
    cell: { code: '', css: '' },
  };

  constructor(props) {
    super(props);
    const {
      cell: { contents: code, customCss: css, visibility },
    } = props;
    this.state = { code, css, visibility };
  }

  onCodeChange = (code) => {
    this.setState({ code });
  };

  onCssChange = (css) => {
    this.setState({ css });
  };

  onVisibilityChange = ({ target: { checked: isForTeachersOnly } }) => {
    const visibility = isForTeachersOnly ? 'teacher' : 'all';
    this.setState({ visibility });
  };

  handleSubmit = () => {
    const { onSave } = this.props;
    const { code, css, visibility } = this.state;
    onSave({ code, css, visibility });
  };

  render() {
    const { onGoBack, cell } = this.props;
    const { code, css, visibility } = this.state;

    const isForTeachersOnly = visibility === 'teacher';

    const customCss = (css || '').replace(/#CELLID/g, '.kalfa-raw-cell-editor-preview');

    return (
      <div className={styles.container}>
        <div className={styles.codeEditor}>
          <CodeAndStyleEditor
            onCodeChange={this.onCodeChange}
            onCssChange={this.onCssChange}
            code={code}
            css={css}
          />
          <br />
          <div className={styles.bottomButtonBar}>
            <div className={styles.visibilityButtonContainer}>
              <input
                type="checkbox"
                checked={isForTeachersOnly}
                onChange={this.onVisibilityChange}
              />
              {I18n.get('For teachers only')}
            </div>
            <div className={styles.saveAndCancelContainer}>
              <Button onClick={onGoBack}>{I18n.get('Cancel')}</Button>
              <Button onClick={this.handleSubmit} raised>
                Save
              </Button>
            </div>
          </div>
        </div>
        <div className={`kalfa-raw-cell-editor-preview ${styles.preview}`}>
          <style>{customCss}</style>
          <RawCell contents={code} id={cell.id} />
        </div>
      </div>
    );
  }
}

export default EditorRawCell;
