import React from 'react';
import t from 'prop-types';
import throttle from 'lodash/throttle';

import Hub from '../../util/hub';
import Cache from '../../util/cache';

import Cell from './Cell';
import CellAnswers from './CellAnswers';
import './GeoGebra.css';

const logger = console;

const getGeogebraParams = self => ({
  id: self.appletId,
  appName: self.perspective || 'classic',
  width: self.width || 800,
  height: 600,
  showToolBar: true,
  borderColor: null,
  showMenuBar: true,
  allowStyleBar: true,
  showAlgebraInput: true,
  enableLabelDrags: true,
  enableShiftDragZoom: true,
  capturingThreshold: null,
  showToolBarHelp: false,
  errorDialogsActive: true,
  showTutorialLink: true,
  showLogging: true,
  useBrowserForJS: false,
  autoHeight: true,
  showFullscreenButton: true,
  showSuggestionButtons: true,
  language: 'de',
  preventFocus: true
});

class GeoGebra extends React.Component {
  static propTypes = {
    perspective: t.oneOf(['classic', 'geometry', 'spreadsheet', 'graphing']),
  };

  static defaultProps = {
    perspective: 'classic',
  };

  constructor(props) {
    super(props);

    const { registerWithCell, answerUpdate } = window.poormansContextReplacement;
    const {
      cellId, rank, initialStateJson, answers, initialIsReady, course,
    } = registerWithCell(
      'geogebra',
    );

    Hub.listen(`${cellId}::geogebra::${rank}::own`, this);
    Hub.listen(`${cellId}::geogebra::${rank}::others`, this);

    this.cellId = cellId;
    this.rank = rank;
    this.course = course;
    this.appletId = `ggbApplet_${cellId.replace(/-/g, '_')}_${rank}`;

    this.initialStateJson = initialStateJson;
    this.answerUpdate = answerUpdate;

    this.containerId = `geogebra-container-${cellId}-${rank}`;

    this.geogebraContainerRef = React.createRef();

    this.state = { answers, isReady: initialIsReady };

    const mockAnswerUpdate = () => {
      if (!this.applet) return;
      window[this.appletId].getBase64(
        b => this.answerUpdate('geogebra', this.rank, JSON.stringify(b)),
      );
    };

    this.throttledAnswerUpdate = throttle(
      mockAnswerUpdate,
      2000,
      { leading: true },
    );
  }

  componentDidMount() {
    const { perspective } = this.props;
    const width = this.geogebraContainerRef.current
      && this.geogebraContainerRef.current.getBoundingClientRect().width;

    this.perspective = perspective;
    this.width = width;

    const params = getGeogebraParams(this);

    params.appletOnLoad = (api) => {
      const listener = this.throttledAnswerUpdate;
      api.registerAddListener(listener);
      api.registerRemoveListener(listener);
      api.registerRenameListener(listener);
      api.registerClearListener(listener);
      api.registerUpdateListener(listener);
      this.setGeoGebraApi(api);
    };

    try {
      const ggbbase64 = (this.initialStateJson && JSON.parse(`${this.initialStateJson}`));

      if (ggbbase64) {
        params.ggbbase64 = ggbbase64;
      }
    } catch (e) {
      logger.error('Invalid JSON', this.initialStateJson, e);
    }

    this.applet = new window.GGBApplet(5.0, params);
    this.applet.inject(this.containerId);
  }

  componentWillUnmount() {
    const api = this.geogebraApi;
    if (!api) return;

    const listener = this.throttledAnswerUpdate;
    api.unregisterAddListener(listener);
    api.unregisterRemoveListener(listener);
    api.unregisterRenameListener(listener);
    api.unregisterClearListener(listener);
    api.unregisterUpdateListener(listener);
  }

  onHubCapsule(capsule) {
    const { channel } = capsule;
    if (channel.endsWith('::own')) {
      return this.onOwnAnswerCapsule(capsule);
    }
    if (channel.endsWith('::others')) {
      return this.onOthersAnswerCapsule(capsule);
    }
    logger.error('Unknown channel for capsule', capsule);
    return null;
  }

  onOwnAnswerCapsule(capsule) {
    const {
      payload: {
        // data,
        isReady,
      },
    } = capsule;

    // try {
    //   // This is ignored on purpose
    //   // window[this.appletId].setBase64(JSON.parse(data));
    // } catch (e) {
    //   logger.error('Invalid JSON', data, e);
    // }

    this.setState({ isReady });
  }

  onOthersAnswerCapsule(capsule) {
    const { payload: answers } = capsule;
    this.setState({ answers });
  }

  setGeoGebraApi(api) {
    this.geogebraApi = api;
  }

  toggleIsReady = () => {
    const { isReady } = this.state;
    this.answerUpdate('geogebra', this.rank, undefined, !isReady);
    this.setState({ isReady: !isReady });
  };

  render() {
    const {
      state: { answers, isReady },
    } = this;

    const { isTeacher } = Cache.getItem('userRoles') || {};

    const isReadyStr = isReady ? 'yes' : 'no';

    return (
      <Cell
        {...this.props}
        toggleIsReady={this.toggleIsReady}
        isReady={isReadyStr}
        disableValidationFeedback
        type="geogebra"
      >
        <div ref={this.geogebraContainerRef} id={this.containerId} />
        {isTeacher && (
          <CellAnswers answers={answers} course={this.course} type="geogebra" {...this.props} />
        )}
      </Cell>
    );
  }
}

export default GeoGebra;
