import React from 'react';
import t from 'prop-types';

const BookTitleAndSubtitle = ({
  book,
  title: showTitle,
  subtitle: showSubtitle,
  customComponent,
}) => {
  if (!book) return null;
  const { title, subtitle } = book;

  if (!customComponent) {
    const willShowTitle = showTitle === true || (showSubtitle === null && showTitle === null);
    return (
      <React.Fragment>
        {willShowTitle ? <span className="kalfa-book-title">{title}</span> : null}
        {showSubtitle ? <span className="kalfa-book-subtitle">{subtitle}</span> : null}
      </React.Fragment>
    );
  }

  return customComponent({ title, subtitle });
};

BookTitleAndSubtitle.propTypes = {
  title: t.bool,
  subtitle: t.bool,
  customComponent: t.func,
  book: t.shape({
    title: t.string,
    subtitle: t.string,
  }),
};

BookTitleAndSubtitle.defaultProps = {
  customComponent: null,
  title: null,
  subtitle: null,
  book: null,
};

export default BookTitleAndSubtitle;
