// @ts-check
import Cache from './cache';

export default (user) => {
  const { roles = [] } = user || {};

  const userRolesBoolean = {
    isPublisher: roles.indexOf('publisher') >= 0,
    isTeacher: roles.indexOf('teacher') >= 0,
    isStudent: roles.indexOf('student') >= 0,
    isOther: roles.indexOf('other') >= 0,
  };

  Cache.setItem('userRoles', userRolesBoolean);
  Cache.setItem('userCurrent', user);

  return {
    userRolesBoolean,
    user,
  };
};
