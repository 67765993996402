import React, { Component } from 'react';
import t from 'prop-types';
import {
  Dialog, DialogContent, DialogActions, DialogButton,
} from 'rmwc/Dialog';
import noop from 'lodash/noop';

import I18n from '../../../util/i18n';

import '@material/dialog/dist/mdc.dialog.css';
import '@material/button/dist/mdc.button.css';

import styles from './ClassWallImageItem.css';

export default class ClassWallImageItem extends Component {
  static propTypes = {
    src: t.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  onOpen = () => {
    this.setState({ isOpen: true });
  };

  onClose = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { isOpen } = this.state;
    const { src } = this.props;

    /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
    return (
      <div>
        <img
          onClick={this.onOpen}
          className={`${styles.image}
          ${styles.zoomIn}`}
          src={src}
          alt=""
          onKeyDown={noop}
        />
        <Dialog open={isOpen} onClose={this.onClose}>
          <DialogContent className={styles.alignCenter}>
            <img className={styles.image} src={src} alt="" />
          </DialogContent>
          <DialogActions>
            <DialogButton action="close" isDefaultAction>
              {I18n.get('Close')}
            </DialogButton>
          </DialogActions>
        </Dialog>
      </div>
    );
    /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
  }
}
