import gql from 'graphql-tag';

export default gql`
  mutation answerUpdate(
    $cellId: ID!
    $answerType: AnswerType!
    $index: Int!
    $data: String
    $isReady: Boolean
  ) {
    answerUpdate(
      cellId: $cellId
      answerType: $answerType
      index: $index
      data: $data
      isReady: $isReady
    ) {
      user {
        id
        displayName
      }
    }
  }
`;
