import React from 'react';
import t from 'prop-types';

import '@material/textfield/dist/mdc.textfield.css';
import '@material/theme/dist/mdc.theme.css';

import Cell from './Cell';
import './YouTube.css';

const DEFAULT_URI = 'https://www.youtube.com/watch?v=tgbNymZ7vqY';

const YouTubeCell = (props) => {
  let { uri } = props;

  if (typeof uri !== 'string') {
    uri = DEFAULT_URI;
  }

  return (
    <Cell {...props} type="youtube">
      <img src="/images/youtube-negative.svg" alt="youtube icon" />
      <div className="youtube-video">
        <iframe
          title="test"
          src={uri.replace('watch?v=', 'embed/')}
          allowFullScreen="allowfullscreen"
          style={{ width: '100%' }}
        />
      </div>
    </Cell>
  );
};

YouTubeCell.propTypes = {
  uri: t.string,
};

YouTubeCell.defaultProps = {
  uri: DEFAULT_URI,
};

export default YouTubeCell;
