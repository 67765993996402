import { graphql } from 'react-apollo';

import compose from 'lodash/flowRight';

import QueryCourseList from '../../GraphQL/QueryCourseList';

import component from './AllInCards';

export default compose(
  graphql(QueryCourseList, {
    props: (props) => {
      const { data = {} } = props;
      const { courseList: courses = [], loading } = data;

      return {
        courses,
        loading,
      };
    },
  }),
)(component);
