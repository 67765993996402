import React from 'react';
import t from 'prop-types';
import { Button, ButtonIcon } from 'rmwc/Button';
import '@material/button/dist/mdc.button.css';
import '@material/theme/dist/mdc.theme.css';

import I18n from '../../util/i18n';

import EditorRawCell from './EditorRawCellContainer';

const Editor = ({
  pageId, bookId, cell, loading, history,
}) => {
  function navigateBack() {
    history.push(`/book/${bookId}/page/${pageId}`);
  }

  const goBack = (
    <div>
      <Button onClick={navigateBack}>
        <ButtonIcon icon="arrow_back" />
        {I18n.get('Back')}
      </Button>
    </div>
  );

  return loading ? null : (
    <div>
      <header>{goBack}</header>
      <EditorRawCell cell={cell} loading={loading} onGoBack={navigateBack} />
    </div>
  );
};

Editor.propTypes = {
  pageId: t.string.isRequired,
  bookId: t.string.isRequired,
  loading: t.bool,
  cell: t.shape({
    __typename: t.string,
  }),
  history: t.shape({
    push: t.func,
  }).isRequired,
};

Editor.defaultProps = {
  loading: false,
  cell: {},
};

export default Editor;
