import gql from 'graphql-tag';

export default gql`
  mutation courseCellCreate(
    $courseId: ID!
    $afterCell: ID!
    $rank: Int!
    $contents: String
    $cellType: CourseCellType!
  ) {
    courseCellCreate(
      courseId: $courseId
      afterCell: $afterCell
      rank: $rank
      contents: $contents
      cellType: $cellType
    ) {
      id
    }
  }
`;
