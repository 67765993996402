const logger = console;

const PUBLIC_URL = process.env.PUBLIC_URL != null ? process.env.PUBLIC_URL : '';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost'
    || window.location.hostname === '[::1]'
    || window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);

function registerValidSW(swUrl) {
  if (navigator.serviceWorker != null) {
    navigator.serviceWorker
      .register(swUrl)
      .then((registration) => {
        // eslint-disable-next-line no-param-reassign
        registration.onupdatefound = () => {
          const installingWorker = registration.installing;
          installingWorker.onstatechange = () => {
            if (installingWorker.state === 'installed') {
              if (navigator.serviceWorker != null) {
                if (navigator.serviceWorker.controller) {
                  logger.log('New content is available; please refresh.');
                } else {
                  logger.log('Content is cached for offline use.');
                }
              }
            }
          };
        };
      })
      .catch((error) => {
        logger.error('Error during service worker registration:', error);
      });
  }
}

function checkValidServiceWorker(swUrl) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl)
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      if (
        response.status === 404
        || response.headers.get('content-type').indexOf('javascript') === -1
      ) {
        // No service worker found. Probably a different app. Reload the page.
        if (navigator.serviceWorker != null) {
          navigator.serviceWorker.ready.then((registration) => {
            registration.unregister().then(() => {
              window.location.reload();
            });
          });
        }
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl);
      }
    })
    .catch(() => {
      logger.log('No internet connection found. App is running in offline mode.');
    });
}

export default function register() {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    const publicUrl = new URL(PUBLIC_URL, window.location);
    if (publicUrl.origin !== window.location.origin) {
      return;
    }

    window.addEventListener('load', () => {
      const swUrl = `${PUBLIC_URL}/service-worker.js`;

      if (isLocalhost) {
        checkValidServiceWorker(swUrl);
        if (navigator.serviceWorker != null) {
          navigator.serviceWorker.ready.then(() => {
            logger.log(
              'This web app is being served cache-first by a service '
                + 'worker. To learn more, visit https://goo.gl/SC7cgQ',
            );
          });
        }
      } else {
        registerValidSW(swUrl);
      }
    });
  }
}

export function unregister() {
  if (navigator.serviceWorker != null) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister();
    });
  }
}
