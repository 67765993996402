import React, { Component } from 'react';
import t from 'prop-types';
import { Button } from 'rmwc';

import I18n from '../../util/i18n';
import Cache from '../../util/cache';
import Html from '../Cells/CourseCells/Html';
import Video from '../Cells/CourseCells/Video';
import Image from '../Cells/CourseCells/Image';
import Essay from '../Cells/CourseCells/Essay';
import CellAnswers from '../Cells/CellAnswers';

import styles from './CourseCell.css';

const INTERACTIVE_TYPES = ['essay'];
class CourseCell extends Component {
  static propTypes = {
    id: t.string.isRequired,
    contents: t.string,
    saveContents: t.func.isRequired,
    deleteCell: t.func.isRequired,
    type: t.string.isRequired,
    myAnswer: t.shape({}),
    answers: t.arrayOf(t.shape({})),
    course: t.shape({}).isRequired,
    courseId: t.string.isRequired,
  };

  static defaultProps = {
    contents: null,
    myAnswer: {},
    answers: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      enableSave: true,
    };
    this.parent = React.createRef();
    this.contents = props.contents;
    this.initialContents = props.contents;
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { initialContents } = this;
    const { contents } = newProps;

    if (contents !== initialContents) {
      this.contents = contents;
      this.initialContents = contents;
    }
  }

  setEditMode = (editMode) => {
    this.setState({ editMode });
  };

  onDeleteCell = () => {
    const { deleteCell } = this.props;
    deleteCell();
  };

  onSaveContents = () => {
    const { saveContents } = this.props;
    const { contents } = this;
    saveContents(contents);
    this.setEditMode(false);
  };

  onUpdateContents = (contents) => {
    this.contents = contents;
  };

  toggleSaveButton = () => {
    const { enableSave } = this.state;
    this.setState({ enableSave: !enableSave });
  };

  onEditModeClick = () => {
    this.setEditMode(true);
  };

  renderCell = () => {
    const {
      type, id, myAnswer, courseId,
    } = this.props;
    const { editMode } = this.state;
    const { contents } = this;

    const { data, isReady } = ((myAnswer || {}).answers || [])[0] || {};

    switch (type) {
      case 'html':
        return (
          <Html
            editMode={editMode}
            html={contents}
            parent={this.parent.current}
            updateContents={this.onUpdateContents}
          />
        );

      case 'video':
        return (
          <Video
            editMode={editMode}
            uri={contents}
            updateContents={this.onUpdateContents}
            toggleSaveButton={this.toggleSaveButton}
          />
        );

      case 'image':
        return (
          <Image
            editMode={editMode}
            uri={contents}
            updateContents={this.onUpdateContents}
            toggleSaveButton={this.toggleSaveButton}
          />
        );

      case 'essay':
        return (
          <Essay
            editMode={editMode}
            statement={contents}
            updateContents={this.onUpdateContents}
            toggleSaveButton={this.toggleSaveButton}
            myAnswer={{ data, isReady }}
            cellId={id}
            courseId={courseId}
          />
        );

      default:
        return <span>Error with cell</span>;
    }
  };

  renderButtons = () => {
    const {
      type, answers = [], course, answer,
    } = this.props;
    const { editMode, enableSave } = this.state;

    if (editMode) {
      return (
        <React.Fragment>
          <Button
            className={styles.button}
            raised
            disabled={!enableSave}
            onClick={this.onSaveContents}
          >
            {I18n.get('Save')}
          </Button>
          <Button className={styles.button} raised onClick={this.onDeleteCell}>
            {I18n.get('Delete')}
          </Button>
        </React.Fragment>
      );
    }

    const answersByStudent = (answers || []).reduce(
      (acc, { user, answers: userAnswers }) => ({
        ...acc,
        [user.displayName]: userAnswers[0],
      }),
      {},
    );

    return (
      <React.Fragment>
        <Button className={styles.button} raised onClick={this.onEditModeClick}>
          {I18n.get('Edit')}
        </Button>
        {INTERACTIVE_TYPES.indexOf(type) !== -1 ? (
          <CellAnswers type="essay" answers={answersByStudent} course={course} answer={answer} />
        ) : null}
      </React.Fragment>
    );
  };

  render() {
    const { isTeacher } = Cache.getItem('userRoles') || {};
    return (
      <div
        style={{
          background: 'white',
          padding: '10px',
          border: '1px solid black',
          marginBottom: '10px',
        }}
        ref={this.parent}
      >
        {this.renderCell()}
        <br />
        {isTeacher ? this.renderButtons() : null}
      </div>
    );
  }
}

export default CourseCell;
