import Cache from './cache';

let currentLanguage = Cache.getItem('language', 'de');

/* eslint-disable max-len */
const translations = {
  de: {
    Library: 'Bibliothek',
    Welcome: 'Willkommen',
    Logout: 'Ausloggen',
    Help: 'Hilfe',
    'Please wait': 'Bitte warten',
    'Chapters and Pages': 'Kapitel und Seiten',
    Add: 'Hinzufügen',
    Edit: 'Bearbeiten',
    'Add new page': 'Neue Seite',
    'Add new Raw Content Cell': 'Neue Zelle',
    Back: 'Zurück',
    'Change this': 'Ändern',
    'Untitled Chapter': 'Unbenanntes Kapitel',
    'Untitled Page': 'Unbenannte Seite',
    'Untitled Book': 'Unbenanntes Buch',
    'Edit Cell': 'Zelle Bearbeiten',
    'Your Classes': 'Ihre Klassen',
    'Create New Class': 'Neue Klasse anlegen',
    'Class name...': 'Klassenname...',
    'Enter Class': 'Klasse eingeben',
    'E.g.: 5B': 'z.B.: 5B',
    'Class description...': 'Beschreibung...',
    'E.g.: Mo 11am, We 10am, Fri 2pm': 'z.B.: Mo 11:00 Uhr, Mi 10:00 Uhr, Fr 14:00 Uhr',
    Teachers: 'Lehrkräfte',
    Teacher: 'Lehrkraft',
    Students: 'Schüler/innen',
    Student: 'Schüler/in',
    'students in class': 'Schüler in der Klasse',
    Cancel: 'Schließen',
    'Create Class': 'Klasse anlegen',
    'Create New Course': 'Neuen Kurs erstellen',
    'Create New Book': 'Neues Buch',
    'Untitled Class': 'Unbenannte Klasse',
    Classes: 'Klassen',
    Analytics: 'Auswertung',
    Settings: 'Einstellungen',
    'Exit class': 'Kurs verlassen',
    'Table of contents': 'Inhaltsverzeichnis',
    'Sync with students': 'Sync Mit Schülern',
    Sync: 'Sync',
    'Page Title': 'Seitentitel',
    'Page Subtitle': 'Seiten-Untertitel',
    Save: 'Speichern',
    'Edit Page Information': 'Seiteninformationen bearbeiten',
    'Video tutorial on the learning platform': 'Videotutorial zur Lernplattform',
    'Edit Theme and Custom Code': 'Theme Bearbeiten',
    'For teachers only': 'Nur für Lehrer anzeigen',
    'Delete Cell': 'Zelle löschen',
    'GO TO CLASS': 'Zur Klasse gehen',
    'SEND LINK': 'LINK SENDEN',
    'Printable View': 'druckbare Ansicht',
    'Teacher edit': 'Material bearbeiten',
    'Class Wall': 'Schwarzes Brett',
    'Class Wall Input Placeholder': 'Füge Text oder YouTube URL ein',
    'Sync pages': 'Seiten synchronisieren',
    Delete: 'Löschen',
    'Exit Editor': 'Beende den Editor',
    'Exit Viewer': 'Viewer verlassen',
    'Edit Metadata': 'Metadaten bearbeiten',
    'Edit Theme': 'Thema bearbeiten',
    'Save Draft': 'Entwurf speichern',
    Preview: 'Vorschau',
    Publish: 'Veröffentlichen',
    'Edit Book Metadata': 'Buchmetadaten bearbeiten',
    'Book title...': 'Buchtitel...',
    'Book subtitle...': 'Buch-Untertitel...',
    Title: 'Titel',
    Subtitle: 'Untertitel',
    'Go to previous page': 'Zurück',
    'Go to next page': 'Weiter',
    'Drawing Pad': 'Zeichenblock',
    Hello: 'Hallo',
    'coming soon': 'Demnächst verfügbar',
    'no students yet': 'Es wurden noch keine Schülerinnen und Schüler zu dieser Klasse hinzugefügt.',
    View: 'Aussicht',
    'See results': 'Ergebnisse',
    Close: 'Schließen',
    Graphs: 'Grafiken',
    Answers: 'Antworten',
    'Enter Classroom': 'Klasse beitreten',
    'Leave Classroom': 'Klasse verlassen',
    'Are you sure?': 'Sind Sie sicher?',
    'classroom code': 'Code',
    'Code copied to clipboard': 'Code in die Zwischenablage kopiert',
    'Word Cloud': 'Wortwolke',
    Ready: 'Fertig',
    'Select a page from the left to edit': 'Wählen Sie eine Seite von links zum Bearbeiten aus',
    'Teacher Content': 'Lehrerkommentar',
    'Select a student': 'Eine/n Schüler/in auswählen',
    Image: 'Bild',
    Video: 'Video',
    Text: 'Text',
    'Quiz/Poll': 'Quiz/Umfrage',
    Or: 'Oder',
    'Upload Image': 'Bild hochladen',
    'No content, click the edit button to edit':
      'Kein Inhalt, klicken Sie auf die Bearbeitungsschaltfläche zum Bearbeiten',
    Accept: 'Akzeptieren',
    'Select a class:': 'wähle eine Klasse aus:',
    Essay: 'Aufsatz',
    'Edit Statement: ': 'Anweisung bearbeiten: ',
    'Enter Link': 'Link eingeben',
    'you can only upload a picture': 'Sie können nur ein Bild hochladen',
    'Choose type': 'Typ auswählen',
    'Are you sure? We have no backups': 'Sind Sie sicher, dass Sie diese Klasse löschen möchten?',
    'Paste the link here': 'Im Falle eines Bildes aus dem Internet, fügen Sie hier den Link ein',
    'Edit Info': 'Info bearbeiten',
    'Edit Class Information': 'Klasseninformationen ändern',
    'HelpNavitem': 'Videotutorial zur Lernplattform'
  },
};

export default {
  setLanguage: (language) => {
    currentLanguage = language;
  },

  get: (key) => {
    const translation = translations[currentLanguage][key];
    if (translation) return translation;
    return `~~~~ 🇫🇷 UNTRANSLATED STRING 🇩🇪 (${key})~~~~`;
  },
};

/* eslint-enable max-len */
