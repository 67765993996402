import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import compose from 'lodash/flowRight';
import getRandomPatternImage from '../../util/getRandomPatternImage';
import MutationCourseCreate from '../../GraphQL/MutationCourseCreate';
import QueryCourseList from '../../GraphQL/QueryCourseList';

import component from './Component';

export default compose(
  withRouter,
  graphql(QueryCourseList, {
    props: ({ data }) => {
      const { courseList, loading } = data;

      return {
        courses: courseList || [],
        loading,
      };
    },
  }),
  graphql(MutationCourseCreate, {
    props: ({ mutate }) => ({
      onAdd: (title, description, book) => mutate({
        variables: {
          title,
          description,
          image: getRandomPatternImage(),
          books: [book],
        },
      }),
    }),
    options: {
      refetchQueries: [{ query: QueryCourseList }],
    },
  }),
)(component);
