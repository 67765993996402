import { graphql } from 'react-apollo';
import compose from 'lodash/flowRight';

import QueryBookGet from '../../../GraphQL/QueryBookGet';

import component from './Component';

export default compose(
  graphql(QueryBookGet, {
    props: ({ data: { bookGet } }) => ({ book: bookGet || {} }),
  }),
)(component);
