import gql from 'graphql-tag';

export default gql`
  mutation courseActivityAppend($courseId: ID!, $content: String!, $type: CourseActivityType!) {
    courseActivityAppend(courseId: $courseId, content: $content, type: $type) {
      id
      activities {
        content
        type
        createdAt
      }
    }
  }
`;
