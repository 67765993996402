// @ts-check
import React from 'react';
import t from 'prop-types';
import { Chip, ChipSet } from 'rmwc/Chip';
import { ListItem } from 'rmwc/List';
import { Typography } from 'rmwc/Typography';

import '@material/list/dist/mdc.list.min.css';
import '@material/typography/dist/mdc.typography.css';
import '@material/chips/dist/mdc.chips.css';

import I18n from '../../../util/i18n';

const chipColors = {
  active: '#89c140',
  away: '#ed963e',
  unknown: '#bbb',
};

const StudentList = ({ students, className }) => (
  <div className={className}>
    <ChipSet>
      {students.length ? (
        students.map(({ user: { displayName: name, id }, status = 'unknown' }) => (
          <Chip key={id} style={{ backgroundColor: chipColors[status] }} text={name} />
        ))
      ) : (
        <ListItem>
          <Typography tag="h3">{I18n.get('no students yet')}...</Typography>
        </ListItem>
      )}
    </ChipSet>
  </div>
);

StudentList.propTypes = {
  students: t.arrayOf(
    t.shape({
      status: t.string,
      user: t.shape({
        id: t.string,
        displayName: t.string,
      }).isRequired,
    }),
  ).isRequired,
  className: t.string,
};

StudentList.defaultProps = {
  className: '',
};

export default StudentList;
