import React from 'react';
import t from 'prop-types';
import { TabBar, Tab } from 'rmwc/Tabs';
import { HorizontalBar } from 'react-chartjs-2';
import { Chip, ChipSet } from 'rmwc/Chip';

import I18n from '../../../util/i18n';
import { quizValidator } from '../../../util/cellAnswersValidator';

import './CellAnswersDialog.css';

import '@material/tab-bar/dist/mdc.tab-bar.min.css';
import '@material/typography/dist/mdc.typography.min.css';
import '@material/chips/dist/mdc.chips.css';

class CellAnswersQuiz extends React.Component {
  constructor() {
    super();
    this.state = {
      activeTabIndex: 0,
    };
  }

  onTabChange = evt => this.setState({ activeTabIndex: evt.detail.index });

  render() {
    const { answers, items, validation } = this.props;
    const { activeTabIndex } = this.state;

    const quizDataColors = [];
    const quizDataData = [];

    items.forEach((el, index) => {
      quizDataData.push(
        Object.values(answers).filter(e => e.data && JSON.parse(e.data).indexOf(index) > -1).length,
      ); // count answers per items
      quizDataColors.push(validation && validation.indexOf(index) > -1 ? '#689f38' : '#e53935'); // check if the option is correct or incorrect
    });

    let labels = [];
    if (window.innerWidth < 1100) {
      items.forEach((el, index) => {
        if (el.length > 10) {
          labels[index] = el.slice(0, 2);
        } else labels[index] = el;
      });
    } else {
      labels = items;
    }

    const quizData = {
      labels,
      datasets: [
        {
          label: 'Anzahl der Antworten',
          backgroundColor: quizDataColors,
          hoverBackgroundColor: '#424242',
          data: quizDataData,
        },
      ],
    };

    const quizDataOpts = {
      display: false,
      position: 'top',
      fullWidth: true,
      reverse: false,
      labels: {
        fontColor: 'rgb(255, 99, 132)',
      },
      gridLines: { display: false },
    };

    const getContents = (index) => {
      switch (index) {
        case 0:
          return (
            <div className="klfa-answers-tab-container">
              <HorizontalBar
                data={quizData}
                redraw
                legend={quizDataOpts}
                options={{
                  scales: {
                    xAxes: [
                      {
                        gridLines: {
                          display: false,
                        },
                        ticks: {
                          beginAtZero: true,
                          stepSize: 1,
                        },
                      },
                    ],
                    yAxes: [
                      {
                        barPercentage: 0.8,
                        maxBarThickness: 80,
                        gridLines: {
                          display: false,
                        },
                        ticks: {
                          fontColor: 'var(--klfa-secondary)',
                          fontFamily: 'Roboto,sans-serif',
                          fontSize: 16,
                          fontStyle: 'bold',
                        },
                      },
                    ],
                  },
                }}
              />
            </div>
          );
        case 1:
          return (
            <React.Fragment>
              {Object.keys(answers).map((k) => {
                const isCorrect = quizValidator(validation, JSON.parse(answers[k].data || '[]'));
                return (
                  <ChipSet
                    className={`klfa-answers-dialog-student ${
                      isCorrect ? '__correct' : '__incorrect'
                    }`}
                    key={k}
                  >
                    <Chip
                      className="klfa-answers-dialog-student-name"
                      leadingIcon={isCorrect ? 'check_circle' : 'remove_circle_online'}
                      text={k}
                    />

                    {answers[k].data
                      && JSON.parse(answers[k].data).map((el, idx) => (
                        <Chip key={idx} text={items[el]} />
                      ))}
                  </ChipSet>
                );
              })}
            </React.Fragment>
          );
        default:
          return <h1>500 Invalid Index</h1>;
      }
    };
    return (
      <React.Fragment>
        <TabBar activeTabIndex={activeTabIndex} onActivate={this.onTabChange}>
          <Tab>{I18n.get('Graphs')}</Tab>
          <Tab>{I18n.get('Answers')}</Tab>
        </TabBar>
        <div className="klfa-answers-dialog-content">{getContents(activeTabIndex)}</div>
      </React.Fragment>
    );
  }
}

CellAnswersQuiz.propTypes = {
  answers: t.shape({}),
  items: t.arrayOf(t.string),
  validation: t.oneOfType([t.number, t.arrayOf(t.number), t.oneOf(['any', 'none'])]),
};

CellAnswersQuiz.defaultProps = {
  answers: {},
  items: [],
  validation: 'any',
};

export default CellAnswersQuiz;
