import React from 'react';
import { Button, ButtonIcon } from 'rmwc/Button';
import t from 'prop-types';

import '@material/dialog/dist/mdc.dialog.css';
import '@material/button/dist/mdc.button.css';
import '@material/textfield/dist/mdc.textfield.css';
import '@material/theme/dist/mdc.theme.css';

import I18n from '../../util/i18n';

import CoursePropertiesForm from './CoursePropertiesForm';

const AddNewCourseButton = ({ onAdd }) => (
  <CoursePropertiesForm onSubmit={onAdd}>
    <Button raised>
      <ButtonIcon icon="add" />
      {I18n.get('Create New Class')}
    </Button>
  </CoursePropertiesForm>
);

AddNewCourseButton.propTypes = {
  onAdd: t.func.isRequired,
};

export default AddNewCourseButton;
