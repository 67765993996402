import { graphql } from 'react-apollo';

import MutationCourseDelete from '../../GraphQL/MutationCourseDelete';
import QueryCourseList from '../../GraphQL/QueryCourseList';

import component from './CourseCard';

export default graphql(MutationCourseDelete, {
  props: ({ mutate }) => ({
    onDelete: (courseId) => {
      mutate({
        variables: { courseId },
      });
    },
  }),
  options: {
    refetchQueries: [{ query: QueryCourseList }],
  },
})(component);
