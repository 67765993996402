import React from 'react';
import t from 'prop-types';

const getGeogebraParams = self => ({
  id: self.appletId,
  appName: self.perspective || 'classic',
  width: self.width || 800,
  height: 400,
  showToolBar: false,
  borderColor: null,
  showMenuBar: false,
  allowStyleBar: true,
  showAlgebraInput: false,
  enableLabelDrags: false,
  enableShiftDragZoom: true,
  capturingThreshold: null,
  showToolBarHelp: false,
  showResetIcon: false,
  enableRightClick: false,
  errorDialogsActive: false,
  showTutorialLink: false,
  showLogging: false,
  useBrowserForJS: false,
  autoHeight: true,
  showFullscreenButton: true,
  showSuggestionButtons: false,
  language: 'de',
});

class GeoGebraViewer extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.geogebraContainerRef = React.createRef();
  }

  componentDidMount() {
    const width = this.geogebraContainerRef && this.geogebraContainerRef.current
      && this.geogebraContainerRef.current.getBoundingClientRect().width;

    const { content } = this.props;
    this.appletId = 'ggbAnswerViewer';
    this.width = width;

    const params = getGeogebraParams(this);
    params.ggbbase64 = content;

    this.applet = new window.GGBApplet(5.0, params);
    this.applet.inject('geogebraAnswerViewer');
  }

  shouldComponentUpdate(nextProps) {
    const { content } = nextProps;
    if (window.ggbAnswerViewer) {
      window.ggbAnswerViewer.setBase64(content);
    }
    return false;
  }

  render() {
    return (
      <div
        id="geogebraAnswerViewer"
        ref={this.geogebraContainerRef}
        style={{ width: '100%' }}
      />
    );
  }
}

GeoGebraViewer.propTypes = {
  content: t.string.isRequired,
};

export default GeoGebraViewer;
