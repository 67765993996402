import React from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, DialogButton,
} from 'rmwc/Dialog';
import t from 'prop-types';
import { TextField, TextFieldHelperText } from 'rmwc/TextField';
import { Select } from 'rmwc/Select';
import noop from 'lodash/noop';

import '@material/dialog/dist/mdc.dialog.css';
import '@material/button/dist/mdc.button.css';
import '@material/textfield/dist/mdc.textfield.css';
import '@material/theme/dist/mdc.theme.css';

import I18n from '../../util/i18n';

import './CoursePropertiesForm.css';

class CoursePropertiesForm extends React.Component {
  static propTypes = {
    onSubmit: t.func.isRequired,
    onClose: t.func,
    submitLabel: t.string,
    children: t.node,
    title: t.string,
    description: t.string,
    open: t.bool,
  };

  static defaultProps = {
    submitLabel: I18n.get('Create Class'),
    onClose: noop,
    children: null,
    title: '',
    description: '',
    open: false,
  };

  constructor(props) {
    super(props);

    const { title, description, open } = props;

    this.state = {
      title,
      description,
      open,
      book: 'mathe',
    };
  }

  open = () => {
    this.setState({ open: true });
  };

  close = (e) => {
    if (!e || !e.detail || !e.detail.action) return;

    const { onClose, onSubmit } = this.props;
    const { title, description, book } = this.state;

    switch (e.detail.action) {
      case 'accept':
        onSubmit(title, description, book);
        this.setState({
          open: false,
          title: '',
          description: '',
        }, onClose);
        break;
      case 'close':
        this.setState({
          open: false,
        }, onClose);
        break;
      default:
        break;
    }
  };

  changeTitle = (event) => {
    this.setState({ title: event.target.value });
  };

  changeDescription = (event) => {
    this.setState({ description: event.target.value });
  };

  renderForm = () => {
    const { title, description, book } = this.state;

    return (
      <React.Fragment>
        <TextField
          value={title || ''}
          onChange={this.changeTitle}
          label={I18n.get('Class name...')}
          size={60}
        />
        <TextFieldHelperText>{I18n.get('E.g.: 5B')}</TextFieldHelperText>
        <br />
        <TextField
          value={description || ''}
          onChange={this.changeDescription}
          label={I18n.get('Class description...')}
          textarea
          fullwidth
        />
        <TextFieldHelperText>{I18n.get('E.g.: Mo 11am, We 10am, Fri 2pm')}</TextFieldHelperText>
        <br />
        <Select
          box="true"
          placeholder=""
          value={book}
          options={[{ label: 'BNE & Mathematik digital', value: 'mathe' }]}
          readOnly
        />
      </React.Fragment>
    );
  };

  render() {
    const { children, submitLabel } = this.props;
    const { open = false, title, description } = this.state;

    const disabled = title === '' || description === '';
    const trigger = children ? (
      <span onClick={this.open} role="button" tabIndex="-1" onKeyDown={noop}>
        {children}
      </span>
    ) : null;

    return (
      <React.Fragment>
        {trigger}
        <Dialog open={open} onClose={this.close}>
          <DialogTitle>{submitLabel}</DialogTitle>
          <DialogContent>{this.renderForm()}</DialogContent>
          <DialogActions>
            <DialogButton action="close" isDefaultAction>
              {I18n.get('Cancel')}
            </DialogButton>
            <DialogButton action="accept" disabled={disabled} raised>
              {submitLabel}
            </DialogButton>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default CoursePropertiesForm;
