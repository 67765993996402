import React, { Component, useRef } from 'react';
import t from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import {
  TopAppBar, TopAppBarRow, TopAppBarSection, TopAppBarTitle,
} from 'rmwc/TopAppBar';
import { Icon } from 'rmwc/Icon';
import { Button, ButtonIcon } from 'rmwc/Button';
import { IconButton } from 'rmwc/IconButton';
import { SimpleMenu, MenuItem } from 'rmwc/Menu';
import ReactToPrint from 'react-to-print';
import {
  Dialog, DialogTitle, DialogActions, DialogButton, DialogContent,
} from 'rmwc/Dialog';

import TopAppBarButton from '../TopAppBarButtonContainer';
import I18n from '../../../../util/i18n';
import Cache from '../../../../util/cache';
import BookTitleAndSubtitle from '../../../Books/BookTitleAndSubtitleContainer';

import styles from './TopAppBar.css';

import '@material/top-app-bar/dist/mdc.top-app-bar.css';
import '@material/button/dist/mdc.button.css';
import '@material/list/dist/mdc.list.css';
import '@material/dialog/dist/mdc.dialog.css';

class DesktopTopAppBar extends Component {
  static propTypes = {
    course: t.shape({
      title: t.string,
    }).isRequired,
    bookId: t.string.isRequired,
    showSidebar: t.bool.isRequired,
    toggleSidebar: t.func.isRequired,
    toggleTableOfContents: t.func.isRequired,
    onLeaveCourse: t.func.isRequired,
    history: t.shape({
      push: t.func.isRequired,
    }).isRequired,
    toggleEditMode: t.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { isOpen: false };
  }

  toggleIsOpen = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  onAccept = () => {
    const { onLeaveCourse } = this.props;

    onLeaveCourse();
    this.toggleIsOpen();
  };

  render() {
    const {
      showSidebar,
      bookId,
      course = {},
      toggleSidebar,
      toggleTableOfContents,
      history,
      toggleEditMode,
      componentToPrint,
    } = this.props;
    const { isOpen } = this.state;
    const { isTeacher, isStudent } = Cache.getItem('userRoles');

    const onLogout = () => history.push('/logout');

    return (
      <TopAppBar className={styles.topAppBar} fixed>
        <TopAppBarRow>
          <TopAppBarSection className={styles.backButtonSection} alignStart>
            {isStudent ? null : (
              <Link to="/classes">
                <ButtonIcon icon="arrow_back_ios" style={{ color: 'white' }} />
              </Link>
            )}
          </TopAppBarSection>
          <TopAppBarSection
            className={`${styles.sidebarButtonContainer} desktopButton`}
            onClick={toggleSidebar}
          >
            <div className={styles.sidebarButtonHeader}>
              <div className={styles.sidebarButtonHeaderTitle}>
                <ButtonIcon icon="group_work" />
                {course.title || '...'}
              </div>
              <ButtonIcon icon={showSidebar ? 'close' : 'expand_more'} />
            </div>
          </TopAppBarSection>
          <TopAppBarSection>
            <TopAppBarTitle>
              <BookTitleAndSubtitle bookId={bookId} title />
            </TopAppBarTitle>
          </TopAppBarSection>
          <TopAppBarSection alignEnd>
            {isTeacher ? (
              <TopAppBarButton checked={course.syncWithStudents} />
            ) : null}
            <Button onClick={toggleTableOfContents}>
              <ButtonIcon icon="toc" />
              {I18n.get('Table of contents')}
            </Button>

            <SimpleMenu
              handle={<IconButton icon="more_vert" />}
              anchorCorner="bottomLeft"
              className={styles.simpleMenu}
            >
              {isTeacher ? (
                <MenuItem onClick={toggleEditMode}>
                  <Icon icon="edit" />
                  {I18n.get('Teacher edit')}
                </MenuItem>
              ) : (
                <MenuItem onClick={this.toggleIsOpen}>
                  <Icon icon="exit_to_app" />
                  {I18n.get('Leave Classroom')}
                </MenuItem>
              )}

              {/* print */}
              <ReactToPrint
                onBeforeGetContent={() => {
                  window.prinnnnt = true
                  const event = new Event("printEvent");
                  window.dispatchEvent(event);
                }}
                onAfterPrint={() => {
                  window.prinnnnt = false
                  const event = new Event("printEvent");
                  window.dispatchEvent(event);
                }}
                onPrintError={() => {
                  window.prinnnnt = false
                  const event = new Event("printEvent");
                  window.dispatchEvent(event);
                }}
                trigger={() => (
                  <MenuItem>
                    <Icon icon="arrow_downward" />
                    {I18n.get('Printable View')}
                  </MenuItem>
                )}
                content={() => componentToPrint}
              />

              {/* videotutorial */}
              {isTeacher && <MenuItem to=''
                onClick={() => (
                  window.open(
                    `${process.env.REACT_APP_URL}/static/videotutorial.html`,
                    '_blank'
                  )
                )}>
                <Icon icon="help_outline" />
                {I18n.get('HelpNavitem')}
              </MenuItem>}

              {/* logout */}
              <MenuItem onClick={onLogout}>
                <Icon icon="close" />
                {I18n.get('Logout')}
              </MenuItem>
            </SimpleMenu>
          </TopAppBarSection>
        </TopAppBarRow>

        <Dialog open={isOpen} onClose={this.toggleIsOpen} className={styles.exitClassroomModal}>
          <DialogTitle>{I18n.get('Are you sure?')}</DialogTitle>
          <DialogActions>
            <DialogButton action="close" isDefaultAction className={styles.modalButtons}>
              {I18n.get('Cancel')}
            </DialogButton>
            <DialogButton action="accept" raised onClick={this.onAccept}>
              {I18n.get('Accept')}
            </DialogButton>
          </DialogActions>
        </Dialog>
      </TopAppBar >
    );
  }
}

export default withRouter(DesktopTopAppBar);
