import React from 'react';

import Edit from '../Upload';

import View from './View';

const Video = (props) => {
  const { editMode = false } = props;

  return editMode ? <Edit acceptedTypes="video/*" {...props} /> : <View {...props} />;
};

export default Video;
