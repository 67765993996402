import React, { Component } from 'react';
import t from 'prop-types';

import I18n from '../../../util/i18n';

import ListItem from './ListItemContainer';

class ChapterListItem extends Component {
  static propTypes = {
    id: t.string.isRequired,
    title: t.string,
    subtitle: t.string,
    bookId: t.string.isRequired,
    pages: t.arrayOf(
      t.shape({
        id: t.string,
        title: t.string,
        subtitle: t.string,
      }),
    ),
    readOnly: t.bool,
  };

  static defaultProps = {
    title: I18n.get('Untitled Chapter'),
    subtitle: '',
    readOnly: false,
    pages: undefined,
  };

  static getDerivedStateFromProps = (props, prevState) => {
    const {
      pages,
      match: {
        params: { pageId },
      },
    } = props;
    const { isCollapsed: originalIsCollapsed } = prevState || {};
    if (originalIsCollapsed === false) return { isCollapsed: false };
    let isCollapsed = true;
    pages.forEach(({ id }) => {
      if (id === pageId) isCollapsed = false;
    });
    return {
      isCollapsed,
    };
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  getInitialIsCollapsed = (pages, currentPageId) => {
    let isCollapsed = true;
    pages.forEach(({ id }) => {
      if (id === currentPageId) isCollapsed = false;
    });
    return isCollapsed;
  };

  toggleIsCollapsed = () => {
    const { isCollapsed } = this.state;
    this.setState({ isCollapsed: !isCollapsed });
  };

  render() {
    const {
      id, title, subtitle, pages, bookId, readOnly,
    } = this.props;
    const { isCollapsed } = this.state;

    const items = [
      <ListItem
        key={id}
        id={id}
        title={title}
        subtitle={subtitle}
        pages={pages}
        type="Chapter"
        graphic={isCollapsed ? 'folder' : 'folder_open'}
        bookId={bookId}
        readOnly={readOnly}
        onClick={this.toggleIsCollapsed}
      />,
    ];

    if (pages && !isCollapsed) {
      pages.forEach((page) => {
        if (!page) {
          // eslint-disable-next-line no-console
          console.error(
            `%c Chapter ${title} (${id}) contains invalid pages`,
            'color: blue; font-size: large',
            pages,
          );
          return;
        }

        items.push(
          <ListItem
            key={page.id}
            {...page}
            indent={1}
            type="Page"
            graphic="notes"
            bookId={bookId}
            readOnly={readOnly}
          />,
        );
      });
    }

    return items;
  }
}

export default ChapterListItem;
