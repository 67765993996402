import React from 'react';
import t from 'prop-types';
import { Route, Redirect, withRouter } from 'react-router-dom';
import {
  TopAppBar, TopAppBarRow, TopAppBarSection, TopAppBarTitle,
} from 'rmwc/TopAppBar';
import { Icon } from 'rmwc/Icon';
import { IconButton } from 'rmwc/IconButton';
import { Button, ButtonIcon } from 'rmwc/Button';
import { SimpleMenu, MenuItem } from 'rmwc/Menu';

import Cache from '../../util/cache';
import I18n from '../../util/i18n';
import { Desktop, Mobile } from '../Responsive';

import styles from './Default.css';

import '@material/top-app-bar/dist/mdc.top-app-bar.min.css';
import '@material/button/dist/mdc.button.min.css';
import '@material/menu/dist/mdc.menu.min.css';
import '@material/menu-surface/dist/mdc.menu-surface.min.css';
import '@material/icon-button/dist/mdc.icon-button.min.css';

const DefaultLayout = ({ component: Component, history, ...rest }) => {
  const {
    isPublisher, isTeacher, isStudent, isOther,
  } = Cache.getItem('userRoles') || {};

  if (!isPublisher && !isTeacher && !isStudent) {
    return <Redirect to="/" />;
  }
  const role = (isPublisher && 'publisher')
    || (isTeacher && I18n.get('Teacher'))
    || (isStudent && I18n.get('Student'))
    || (isOther && I18n.get('Teacher'));

  const logout = () => {
    history.push('/logout');
  };

  // const gotoLibrary = () => {
  //   history.push('/books');
  // };
  // const gotoClasses = () => {
  //   history.push('/classes');
  // };

  function setLanguage(language) {
    return () => {
      I18n.setLanguage(language);
      Cache.persistItem('language', language, { expires: new Date(2099, 1, 1) });
      window.location.reload();
    };
  }

  function getComponent(matchProps) {
    /* eslint-disable react/jsx-no-bind */
    return (
      <div className={styles.defaultLayout}>
        <TopAppBar className={styles.topbar} fixed>
          <TopAppBarRow>
            <TopAppBarSection alignStart>
              <Icon
                strategy="url"
                icon="/images/logo-mathe-interaktiv.svg"
                className={styles.klfaLogo}
              />
              <TopAppBarTitle>Agenda 2030 und die Nachhaltigkeitziele - Mathematik</TopAppBarTitle>
            </TopAppBarSection>
            <Desktop>
              <TopAppBarSection alignEnd>
                {/* {isPublisher && ( */}
                {/*  <Button onClick={gotoLibrary}> */}
                {/*    <ButtonIcon icon="library_books" /> {I18n.get('Library')} */}
                {/*  </Button> */}
                {/* )} */}
                {/* {!isPublisher && ( */}
                {/*  <Button onClick={gotoClasses}> */}
                {/*    <ButtonIcon icon="group_work" /> {I18n.get('Classes')} */}
                {/*  </Button> */}
                {/* )} */}
                {/* <Button unelevated> */}
                {/*  <ButtonIcon icon="assessments" /> {I18n.get('Analytics')} */}
                {/* </Button>} */}
                {/* <SimpleMenu handle={<IconButton icon="language" />} anchorCorner="bottomLeft"> */}
                {/*  <MenuItem onClick={setLanguage('de')}>Deutsch</MenuItem> */}
                {/*  <MenuItem onClick={setLanguage('en')}>English</MenuItem> */}
                {/*  <MenuItem onClick={setLanguage('fr')}>Français</MenuItem> */}
                {/* </SimpleMenu> */}
                <SimpleMenu
                  handle={(
                    <Button>
                      <ButtonIcon icon="account_circle_outline" />
                      {role}
                    </Button>
)}
                  anchorCorner="bottomLeft"
                >
                  <MenuItem onClick={logout}>{I18n.get('Logout')}</MenuItem>
                </SimpleMenu>
              </TopAppBarSection>
            </Desktop>
          </TopAppBarRow>
          <Mobile>
            <TopAppBarRow>
              <TopAppBarSection alignEnd>
                {/* {isPublisher && ( */}
                {/*  <Button onClick={gotoLibrary}> */}
                {/*    <ButtonIcon icon="library_books" /> {I18n.get('Library')} */}
                {/*  </Button> */}
                {/* )} */}
                {/* {!isPublisher && ( */}
                {/*  <Button onClick={gotoClasses}> */}
                {/*    <ButtonIcon icon="group_work" /> {I18n.get('Classes')} */}
                {/*  </Button> */}
                {/* )} */}
                {/* <Button unelevated> */}
                {/*  <ButtonIcon icon="assessments" /> {I18n.get('Analytics')} */}
                {/* </Button> */}
                <SimpleMenu handle={<IconButton icon="language" />} anchorCorner="bottomLeft">
                  <MenuItem onClick={setLanguage('de')}>Deutsch</MenuItem>
                  <MenuItem onClick={setLanguage('en')}>English</MenuItem>
                  <MenuItem onClick={setLanguage('fr')}>Français</MenuItem>
                </SimpleMenu>
                <SimpleMenu
                  handle={(
                    <Button>
                      <ButtonIcon icon="account_circle_outline" />
                      {role}
                    </Button>
                  )}
                  anchorCorner="bottomLeft"
                >
                  <MenuItem onClick={logout}>{I18n.get('Logout')}</MenuItem>
                </SimpleMenu>
              </TopAppBarSection>
            </TopAppBarRow>
          </Mobile>
        </TopAppBar>
        <div className="klfa-dashed-line" />
        <div className="klfa-default-content-container">
          <Component {...matchProps} />
        </div>
        <div className="egi-footer">
          <div>
            <a href="https://www.engagement-global.de" target="_blank" rel="noopener noreferrer">
              <img src="/images/logo-egi-white.svg" alt="" />
            </a>
          </div>
          <div>
            <a href="https://www.bmz.de/de/" target="_blank" rel="noopener noreferrer">
              <img src="/images/logo-bmz.jpg" alt="" />
            </a>
          </div>
          <div>
            <a href="https://klett-mint.de/" target="_blank" rel="noopener noreferrer">
              <img src="/images/logo-km.jpg" alt="" />
            </a>
          </div>
        </div>
      </div>
    );
    /* eslint-enable react/jsx-no-bind */
  }

  return <Route {...rest} render={getComponent} />;
};

DefaultLayout.propTypes = {
  component: t.func.isRequired,
  history: t.shape({
    push: t.func.isRequired,
  }).isRequired,
};

export default withRouter(DefaultLayout);
