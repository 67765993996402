import { withApollo, graphql } from 'react-apollo';

import compose from 'lodash/flowRight';

import MutationCourseUpdate from '../../GraphQL/MutationCourseUpdate';
import QueryCourseList from '../../GraphQL/QueryCourseList';

import component from './RenameDialog';

export default compose(
  withApollo,
  graphql(MutationCourseUpdate, {
    props: ({ mutate, ownProps }) => ({
      onUpdate: (title, description) => mutate({
        variables: {
          id: ownProps.id,
          title,
          description,
          image: undefined,
          books: undefined,
        },
      }),
    }),
    options: {
      refetchQueries: [{ query: QueryCourseList }],
    },
  }),
)(component);
