// @ts-check
import React from 'react';
import t from 'prop-types';

import Hub from '../../../util/hub';
import RawCell from '../../Cells/RawSync';

// @ts-ignore
import styles from './CellView.css';

const isEqual = require('lodash/fp/isEqual');

const answerPropType = t.shape({
  answerType: t.oneOf(['quiz', 'essay', 'dragAndSort', 'dragToBucket', 'paint', 'geogebra']).isRequired,
  index: t.number.isRequired,
  data: t.string,
  isReady: t.bool,
});

const normalizeAnswers = (cellId, cellAnswers) => {
  const result = {};

  if (cellAnswers && cellAnswers.length) {
    cellAnswers.forEach(({ user: { displayName }, answers }) => {
      answers.forEach((answer) => {
        const {
          answerType, index, data, isReady,
        } = answer;
        const key = `${cellId}::${answerType}::${index}::others`;
        result[key] = result[key] || {};
        result[key][displayName] = { data, isReady };
      });
    });
  }

  return result;
};

class CellView extends React.Component {
  static propTypes = {
    id: t.string.isRequired,
    contents: t.string,
    customCss: t.string,
    transpiledCode: t.string,
    visibility: t.string,
    __typename: t.string.isRequired,
    myAnswer: t.shape({
      answers: t.arrayOf(answerPropType),
    }),
    answers: t.arrayOf(
      t.shape({
        user: t.shape({
          id: t.string,
          displayName: t.string,
        }),
        answers: t.arrayOf(answerPropType),
      }),
    ),
    answerUpdate: t.func.isRequired,
  };

  static defaultProps = {
    contents: '',
    customCss: '',
    visibility: 'all',
    myAnswer: {},
    answers: [],
    transpiledCode: '',
  };

  constructor(props) {
    super(props);
    const { id, answers } = props;
    this.normalizedAnswers = normalizeAnswers(id, answers);
  }

  shouldComponentUpdate(nextProps) {
    // don't update the DOM after mounted
    // for now it ignores contents, customCss, visibility changes
    const { id, myAnswer } = this.props;

    if (myAnswer && !isEqual(myAnswer, nextProps.myAnswer)) {
      const { answers: oldAnswers } = myAnswer;
      const { answers: newAnswers } = nextProps.myAnswer;
      for (let i = 0; i < newAnswers.length; i++) {
        const {
          answerType, index, data, isReady,
        } = newAnswers[i];

        if (
          (oldAnswers[i] || {}).data !== newAnswers[i].data
          || oldAnswers[i].isReady !== newAnswers[i].isReady
        ) {
          Hub.dispatch(`${id}::${answerType}::${index}::own`, {
            data,
            isReady,
          });
        }
      }
    }

    const othersNew = normalizeAnswers(id, nextProps.answers);

    Object.keys(othersNew).forEach((channel) => {
      Hub.dispatch(channel, othersNew[channel]);
    });
    return false;
  }

  render() {
    const {
      id,
      contents,
      customCss,
      visibility,
      myAnswer,
      answers,
      answerUpdate,
      __typename,
      transpiledCode,
    } = this.props;

    return (
      <React.Fragment key={id}>
        <style>{(customCss || '').replace(/#CELLID/g, `.kalfa-cell-${id}`)}</style>

        <div
          key={id}
          className={[
            'kalfa-cell-view',
            `kalfa-cell-type-${__typename}`,
            `kalfa-cell-${id}`,
            `kalfa-cell-visibility-${visibility || 'all'}`,
            styles.cell,
          ].join(' ')}
        >
          <RawCell
            contents={contents}
            id={id}
            myAnswer={myAnswer}
            answers={answers}
            answerUpdate={answerUpdate}
            transpiledCode={transpiledCode}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default CellView;
