import React, { Component } from 'react';
import t from 'prop-types';

import '@material/theme/dist/mdc.theme.css';

import Cache from '../../../util/cache';
import Hub from '../../../util/hub';
import { dragAndSortValidator } from '../../../util/cellAnswersValidator';
import CellAnswers from '../CellAnswers';
import Cell from '../Cell';

import Widget from './Widget';

import './DragAndSort.css';

const logger = console;

class DragAndSortCell extends Component {
  static propTypes = {
    statement: t.string,
    items: t.arrayOf(
      t.shape({
        rank: t.number,
        contents: t.node,
      }),
    ),
  };

  static defaultProps = {
    statement: '',
    items: [],
  };

  static getDerivedStateFromProps(props, prevState) {
    const { prevProps } = prevState || {};
    if (props === prevProps) return null;

    const { items } = props;
    return {
      items,
      prevProps: props,
    };
  }

  constructor(props) {
    super(props);
    const { registerWithCell, answerUpdate } = window.poormansContextReplacement;
    const {
      cellId, rank, initialStateJson, answers, initialIsReady, course,
    } = registerWithCell(
      'dragAndSort',
    );

    Hub.listen(`${cellId}::dragAndSort::${rank}::own`, this);
    Hub.listen(`${cellId}::dragAndSort::${rank}::others`, this);

    this.rank = rank;
    this.answerUpdate = answerUpdate;
    this.course = course;

    let { items } = props;
    try {
      items = initialStateJson ? JSON.parse(`${initialStateJson}`) : items;
      items = items.map((i) => {
        // react requires adding this property or else it won't render
        // https://reactjs.org/blog/2015/12/18/react-components-elements-and-instances.html#further-reading
        if (!i.contents.$$typeof) {
          // eslint-disable-next-line no-param-reassign
          i.contents.$$typeof = Symbol.for('react.element');
        }
        return i;
      });
    } catch (e) {
      logger.error('Invalid JSON', initialStateJson, e);
    }

    this.state = {
      items,
      answers,
      // this property is used in getDerivedStateFromProps
      // eslint-disable-next-line react/no-unused-state
      prevProps: props,
      isReady: initialIsReady,
      hasOwnAnswer: !!initialStateJson,
    };
  }

  onHubCapsule(capsule) {
    const { channel } = capsule;
    if (channel.endsWith('::own')) {
      return this.onOwnAnswerCapsule(capsule);
    }
    if (channel.endsWith('::others')) {
      return this.onOthersAnswerCapsule(capsule);
    }
    logger.error('Unknown channel for capsule', capsule);
    return null;
  }

  onOwnAnswerCapsule(capsule) {
    const {
      payload: { data, isReady },
    } = capsule;
    try {
      let items = (data && JSON.parse(data)) || [];
      items = items.map((i) => {
        // react requires adding this property or else it won't render
        // https://reactjs.org/blog/2015/12/18/react-components-elements-and-instances.html#further-reading
        if (!i.contents.$$typeof) {
          // eslint-disable-next-line no-param-reassign
          i.contents.$$typeof = Symbol.for('react.element');
        }
        return i;
      });

      this.setState({ items, isReady, hasOwnAnswer: true });
    } catch (e) {
      logger.error('Invalid JSON', data, e);
    }
  }

  onOthersAnswerCapsule(capsule) {
    const { payload: answers } = capsule;
    this.setState({ answers });
  }

  moveItem = (fromIndex, newIndex) => {
    if (fromIndex === newIndex) return;
    const { items } = this.state;
    const [item] = items.splice(fromIndex, 1);
    items.splice(newIndex, 0, item);
    this.setState({ items, hasOwnAnswer: true });
  };

  persistData = () => {
    const { items } = this.state;
    this.answerUpdate('dragAndSort', this.rank, JSON.stringify(items || []));
  };

  toggleIsReady = () => {
    const { isReady } = this.state;
    this.answerUpdate('dragAndSort', this.rank, undefined, !isReady);
    this.setState({ isReady: !isReady });
  };

  render() {
    const {
      items, answers, isReady, hasOwnAnswer,
    } = this.state;

    const { statement } = this.props;
    const { isTeacher } = Cache.getItem('userRoles') || {};
    const isCorrect = dragAndSortValidator(items);

    let isReadyStr = isReady ? 'yes' : 'no';
    if (!hasOwnAnswer) isReadyStr = 'disabled';

    if (typeof items !== 'object' || items.constructor !== Array) return null;
    return (
      <React.Fragment>
        <Cell
          {...this.props}
          isReady={isReadyStr}
          toggleIsReady={this.toggleIsReady}
          type="dragAndSort"
          isCorrect={isCorrect}
        >
          <div className="kalfa-cell-statement kalfa-dragandsort-statement">{statement}</div>
          <Widget items={items} moveItem={this.moveItem} persistData={this.persistData} />
        </Cell>
        {isTeacher && (
          <CellAnswers answers={answers} course={this.course} type="dragAndSort" {...this.props} />
        )}
      </React.Fragment>
    );
  }
}

export default DragAndSortCell;
