import React from 'react';
import t from 'prop-types';
import { IconButton } from 'rmwc/IconButton';

import '@material/button/dist/mdc.button.css';
import '@material/icon-button/dist/mdc.icon-button.css';

import I18n from '../../util/i18n';
import PageView from '../Pages/View/PageViewContainer';

import CustomBookCssAndCodeRendererHOC from './CustomBookCssAndCodeRendererContainerHOC';
import NavigationButton from './NavigationButtonContainer';
import styles from './BookInClass.css';

const PageNavigationButton = ({ history, direction }) => ({ pageId, bookId, onUpdate }) => {
  const {
    location: { pathname },
    push,
  } = history;
  const segments = pathname.split('/');
  segments[segments.length - 1] = pageId;
  const to = segments.join('/');

  const icons = {
    previous: 'chevron_left',
    next: 'chevron_right',
  };

  const labels = {
    previous: I18n.get('Go to previous page'),
    next: I18n.get('Go to next page'),
  };

  function navigate() {
    onUpdate(bookId, pageId);
    push(to);
    window.$('#BookInClass').animate({ scrollTop: 0 });
  }

  return (
    <IconButton
      className={styles[direction]}
      icon={icons[direction]}
      label={labels[direction]}
      onClick={navigate}
      disabled={!pageId}
    />
  );
};

class BookInClass extends React.Component {
  static propTypes = {
    bookId: t.string.isRequired,
    pageId: t.string.isRequired,
    className: t.string,
    history: t.shape({
      location: t.shape({
        pathname: t.string,
      }),
    }),
    editMode: t.bool,
  };

  static defaultProps = {
    className: '',
    id: '',
    editMode: false,
    history: {},
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      bookId, pageId, history, className, editMode, courseId,
    } = this.props;

    return (
      <div id="BookInClass" className={className}>
        <CustomBookCssAndCodeRendererHOC bookId={bookId}>
          <div
            ref={(c) => {
              this.test = c;
            }}
            className={`kalfa-book-view kalfa-book-${bookId}`}
          >
            <PageView pageId={pageId} editMode={editMode} courseId={courseId} />
          </div>
          <div className={styles.buttons}>
            <NavigationButton
              bookId={bookId}
              pageId={pageId}
              direction="previous"
              component={PageNavigationButton({
                history,
                direction: 'previous',
              })}
            />
            <NavigationButton
              bookId={bookId}
              pageId={pageId}
              direction="next"
              component={PageNavigationButton({ history, direction: 'next' })}
            />
          </div>
        </CustomBookCssAndCodeRendererHOC>
      </div>
    );
  }
}

export default BookInClass;
