import gql from 'graphql-tag';

export default gql`
  query cellGet($cellId: ID!) {
    cellGet(id: $cellId) {
      id
      contents
      customCss
      transpiledCode
      visibility
    }
  }
`;
