import React, { Component } from 'react';
import {
  Dialog, DialogContent, DialogActions, DialogButton,
} from 'rmwc/Dialog';

import I18n from '../../util/i18n';

import Cell from './Cell';
import '@material/dialog/dist/mdc.dialog.css';
import '@material/button/dist/mdc.button.css';

export default class Lightbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  onOpen = () => {
    this.setState({ isOpen: true });
  };

  onClose = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { isOpen } = this.state;
    const { children } = this.props;

    return (
      <Cell {...this.props} type="lightbox">
        <div onClick={this.onOpen}>{children}</div>
        <Dialog open={isOpen} onClose={this.onClose}>
          <DialogContent>{children}</DialogContent>
          <DialogActions>
            <DialogButton action="close" isDefaultAction>
              {I18n.get('Close')}
            </DialogButton>
          </DialogActions>
        </Dialog>
      </Cell>
    );
  }
}
