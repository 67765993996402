import { Component } from 'react';

export default class NavigationUsingKeys extends Component {
  componentDidMount() {
    window.addEventListener('keydown', this.navigate);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.navigate);
  }

  getNavigationTargetId = ({ pages = [], pageId, direction }) => {
    let pageIds = [];

    pages.forEach((chapterOrPage = {}) => {
      if (chapterOrPage.pages && chapterOrPage.pages.length) {
        pageIds = pageIds.concat(
          (chapterOrPage.pages || []).filter(notNull => notNull).map(({ id }) => id),
        );
      } else {
        pageIds.push(chapterOrPage.id);
      }
    });

    const index = pageIds.indexOf(pageId);
    const position = index === -1 ? 0 : index;
    let target;

    switch (direction) {
      case 'previous':
        target = position >= 1 ? pageIds[position - 1] : null;
        break;
      case 'next':
        target = position < pageIds.length - 1 ? pageIds[position + 1] : null;
        break;
      default:
        // eslint-disable-next-line prefer-destructuring
        target = pageIds[0];
        break;
    }

    return target;
  };

  navigate = ({ keyCode }) => {
    const {
      book: { pages },
      pageId,
      bookId,
      onUpdate,
    } = this.props;
    const nextPageId = this.getNavigationTargetId({
      pages,
      pageId,
      direction: 'next',
    });
    const prevPageId = this.getNavigationTargetId({
      pages,
      pageId,
      direction: 'previous',
    });

    if (keyCode === 37) {
      onUpdate(bookId, prevPageId);
      window.$('#BookInClass').animate({ scrollTop: 0 });
    } else if (keyCode === 39) {
      onUpdate(bookId, nextPageId);
      window.$('#BookInClass').animate({ scrollTop: 0 });
    }
  };

  render() {
    return null;
  }
}
