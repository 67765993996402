import { graphql } from 'react-apollo';

import compose from 'lodash/flowRight';

import MutationCourseCellUpdate from '../../GraphQL/MutationCourseCellUpdate';
import MutationCourseCellDelete from '../../GraphQL/MutationCourseCellDelete';
import QueryCourseGet from '../../GraphQL/QueryCourseGet';

import component from './CourseCell';

export default compose(
  graphql(MutationCourseCellUpdate, {
    props: ({ mutate, ownProps }) => ({
      saveContents: contents => mutate({
        variables: {
          id: ownProps.id,
          contents,
        },
      }),
    }),
  }),
  graphql(MutationCourseCellDelete, {
    props: ({ mutate, ownProps }) => ({
      deleteCell: () => mutate({
        variables: {
          id: ownProps.id,
        },
        optimisticResponse: () => ({
          courseCellDelete: {
            id: ownProps.id,
            contents: '',
            __typename: 'CourseCell',
          },
        }),
        refetchQueries: [{ query: QueryCourseGet, variables: { courseId: ownProps.courseId } }],
      }),
    }),
  }),
)(component);
