import React from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Cell from './Cell';
import './Carousel.css';

const Carousel = (props) => {
  const { children } = props;
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Cell {...props} type="carousel">
      <Slider {...settings}>{children}</Slider>
    </Cell>
  );
};

export default Carousel;
