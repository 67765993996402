import React from 'react';
import t from 'prop-types';
import { ButtonIcon } from 'rmwc/Button';

import styles from './AddCellButton.css';

import '@material/typography/dist/mdc.typography.css';
import '@material/list/dist/mdc.list.css';
import '@material/theme/dist/mdc.theme.css';

const AddRawCellButton = ({ onAdd }) => (
  <div className={styles.container}>
    <div onClick={onAdd} className={styles.button}>
      <ButtonIcon icon="add" className={styles.buttonIcon} />
    </div>
  </div>
);

AddRawCellButton.propTypes = {
  onAdd: t.func.isRequired,
};

export default AddRawCellButton;
