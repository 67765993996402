import gql from 'graphql-tag';

export default gql`
  mutation courseCurrentPositionUpdate($courseId: ID!, $bookId: ID!, $pageId: ID!) {
    courseCurrentPositionUpdate(id: $courseId, bookId: $bookId, pageId: $pageId) {
      id
      currentPosition {
        bookId
        pageId
      }
    }
  }
`;
