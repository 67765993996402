import React from 'react';
import t from 'prop-types';
import { IconButton } from 'rmwc/IconButton';

import '@material/typography/dist/mdc.typography.css';
import '@material/list/dist/mdc.list.css';
import '@material/theme/dist/mdc.theme.css';

const AddPageListItem = ({ onAdd }) => <IconButton icon="playlist_add" onClick={onAdd} />;

AddPageListItem.propTypes = {
  onAdd: t.func.isRequired,
};

export default AddPageListItem;
