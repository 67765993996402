export default [
  // Drawing tools
  'Pencil',
  'Eraser',
  // 'Text',
  'Line',
  'ArrowOneSide',
  'ArrowTwoSide',
  'Triangle',
  'Rectangle',
  'Circle',
  'Image',
  // 'Polygon',
  // 'BackgroundImage',

  // Drawing options
  // 'ColorHtml5',
  'Color',
  'ShapeBorder',
  'BrushSize',
  'OpacityOption',
  'LineWidth',
  'StrokeWidth',

  // 'Resize',
  'ShapeContextMenu',
  // 'CloseButton',
  'OvercanvasPopup',
  'OpenPopupButton',
  // 'MinimizeButton',
  'ToggleVisibilityButton',
  // 'MovableFloatingMode',
  'FullscreenModeButton',
  // 'Zoom',
  'TextLineHeight',
  'TextAlign',

  'TextFontFamily',
  'TextFontSize',
  'TextFontWeight',
  'TextFontStyle',
  'TextDecoration',
  'TextColor',
  'TextBackgroundColor',
];
