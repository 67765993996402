import gql from 'graphql-tag';

export default gql`
  subscription courseUpdated($id: ID!) {
    courseUpdated(id: $id) {
      id
      title
      description
      image
      code
      students {
        user {
          id
          displayName
        }
        status
      }
      activities {
        content
        type
        createdAt
      }
      courseCells {
        id
        contents
        afterCell
        rank
        cellType
        myAnswer {
          answers {
            answerType
            index
            data
            isReady
          }
        }
      }
    }
  }
`;
