import gql from 'graphql-tag';

export default gql`
  query bookGet($bookId: ID!) {
    bookGet(id: $bookId) {
      id
      title
      subtitle
      customCss
      customJs
      pages {
        id
        title
        subtitle
        pages {
          id
          title
          subtitle
        }
      }
    }
  }
`;
