import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import compose from 'lodash/flowRight';

import QueryCellGet from '../../GraphQL/QueryCellGet';

import component from './Editor';

export default compose(
  withRouter,
  graphql(QueryCellGet, {
    props: ({ data: { loading, cellGet }, ownProps }) => ({
      ...ownProps,
      loading,
      cell: cellGet,
    }),
  }),
)(component);
