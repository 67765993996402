import React from 'react';
import t from 'prop-types';

import '@material/textfield/dist/mdc.textfield.css';
import '@material/theme/dist/mdc.theme.css';

const GoalIconCell = (props) => {
  const { uri, href } = props;
  const startsWith = (str = '', search = '') => str.substr(0, search.length) === search;

  const image = (startsWith(uri, 'https://') || startsWith(uri, 'data:image/gif;'))
    ? uri
    : [
      process.env.REACT_APP_URL,
      process.env.REACT_APP_KLFA_S3URL,
      'static/img',
      uri,
    ].join('/');

  return (
    <div className="eg-goal-icon">
      <a href={href} title={href} target="blank">
        <img className="kalfa-goalicon" src={image} alt={uri} />
      </a>
    </div>
  );
};

GoalIconCell.propTypes = {
  href: t.string,
  uri: t.string,
};

GoalIconCell.defaultProps = {
  href: '_blank',
  uri: 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
};

export default GoalIconCell;