// @ts-check
import React, { Component } from 'react';
import t from 'prop-types';
import { Droppable, DragDropContext } from 'react-beautiful-dnd';

import DragAndSortItem from './DragAndSortItem';

export default class Widget extends Component {
  static propTypes = {
    items: t.arrayOf(
      t.shape({
        rank: t.number,
        contents: t.node,
      }),
    ),
  };

  static defaultProps = {
    items: [],
  };

  onDragEnd = (result) => {
    const { moveItem, persistData } = this.props;
    const { destination, source } = result;
    if (!source || !destination) return;
    moveItem(source.index, destination.index);
    persistData();
  };

  renderItems = () => {
    const { items } = this.props;
    return items.map((i, index) => {
      if (!i || typeof i !== 'object' || i.constructor !== Object) return null;
      const { contents } = i;
      return <DragAndSortItem contents={contents} index={index} key={index} />;
    });
  };

  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable-1">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {this.renderItems()}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}
