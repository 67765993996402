import t from 'prop-types';

const getNavigationTargetId = ({
  pages, pageId, direction, targetPageId,
}) => {
  let pageIds = [];

  pages.forEach((chapterOrPage = {}) => {
    if (chapterOrPage.pages && chapterOrPage.pages.length) {
      pageIds = pageIds.concat(
        (chapterOrPage.pages || []).filter(notNull => notNull).map(({ id }) => id),
      );
    } else {
      pageIds.push(chapterOrPage.id);
    }
  });

  const index = pageIds.indexOf(pageId);
  const position = index === -1 ? 0 : index;
  let target;

  switch (direction) {
    case 'previous':
      target = position >= 1 ? pageIds[position - 1] : null;
      break;
    case 'next':
      target = position < pageIds.length - 1 ? pageIds[position + 1] : null;
      break;
    case 'beginning':
      // eslint-disable-next-line prefer-destructuring
      target = position > 1 ? pageIds[0] : null;
      break;
    case 'end':
      target = position < pageIds.length - 1 ? pageIds[pageIds.length - 1] : null;
      break;
    case 'id':
      target = targetPageId;
      break;
    default:
      // eslint-disable-next-line prefer-destructuring
      target = pageIds[0];
      break;
  }

  return target;
};

const NavigationButton = ({
  book,
  pageId,
  direction,
  component,
  onUpdate,
  bookId,
  targetPageId,
  loading,
}) => {
  if (loading) return null;
  const { pages = [] } = book || {};
  const id = getNavigationTargetId({
    pages,
    pageId,
    direction,
    targetPageId,
  });
  return component({ pageId: id, bookId, onUpdate });
};

NavigationButton.propTypes = {
  book: t.shape({
    pages: t.arrayOf(
      t.shape({
        id: t.string,
      }),
    ),
  }).isRequired,
  pageId: t.string,
  direction: t.oneOf(['beginning', 'end', 'next', 'previous', 'id']).isRequired,
  component: t.func.isRequired,
  onUpdate: t.func,
};

NavigationButton.defaultProps = {
  onUpdate: () => {},
};

export default NavigationButton;
