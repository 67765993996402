function readCache() {
  const hypersayCache = JSON.parse(localStorage.getItem('hypersayCache') || '{}');
  const validItems = {};

  Object.keys(hypersayCache).forEach((k) => {
    const { value, expires } = hypersayCache[k];
    if (expires) {
      if (new Date(expires) >= new Date()) {
        validItems[k] = value;
      }
    } else {
      validItems[k] = value;
    }
  });

  return validItems;
}

const cache = readCache();

function setItem(key, value) {
  cache[key] = value;
  return cache;
}

function getItem(key, defaultValue) {
  return cache[key] || defaultValue;
}

function persistItem(key, value, options) {
  const { expires } = options || {};
  const persistentCache = readCache();

  persistentCache[key] = { value };
  if (expires) persistentCache[key].expires = expires;

  localStorage.setItem('hypersayCache', JSON.stringify(persistentCache));
  return setItem(key, value);
}

window.mycache = {
  cache,
  persistItem,
  setItem,
  getItem,
};

export default {
  persistItem,
  setItem,
  getItem,
};
