import gql from 'graphql-tag';

export default gql`
  subscription courseCellUpdated($courseId: ID!) {
    courseCellUpdated(courseId: $courseId) {
      id
      courseId
      afterCell
      rank
      cellType
      contents
    }
  }
`;
