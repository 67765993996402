import React from 'react';
import t from 'prop-types';

import '@material/dialog/dist/mdc.dialog.css';
import '@material/button/dist/mdc.button.css';
import '@material/textfield/dist/mdc.textfield.css';
import '@material/theme/dist/mdc.theme.css';

import I18n from '../../util/i18n';

import CoursePropertiesForm from './CoursePropertiesForm';

const RenameDialog = ({
  onUpdate, onClose, title, description,
}) => (
  <CoursePropertiesForm
    onSubmit={onUpdate}
    submitLabel={I18n.get('Edit Class Information')}
    onClose={onClose}
    title={title}
    description={description}
    open
  />
);

RenameDialog.propTypes = {
  onUpdate: t.func.isRequired,
  onClose: t.func.isRequired,
  title: t.string.isRequired,
  description: t.string.isRequired,
};

export default RenameDialog;
