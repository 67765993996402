import React, { Component } from 'react';
import t from 'prop-types';

import Cache from '../../../util/cache';
import Hub from '../../../util/hub';
import { dragToBucketValidator } from '../../../util/cellAnswersValidator';
import CellAnswers from '../CellAnswers';
import Cell from '../Cell';

import Widget from './Widget';

import './DragToBucket.css';

import '@material/textfield/dist/mdc.textfield.css';
import '@material/theme/dist/mdc.theme.css';

const logger = console;

class DragToBucketCell extends Component {
  static propTypes = {
    validation: t.string,
    buckets: t.shape({
      unallocated: t.arrayOf(t.string),
    }),
    items: t.shape({}),
    statement: t.string,
  };

  static defaultProps = {
    validation: null,
    buckets: {},
    items: {},
    statement: '',
  };

  state = {};

  static getDerivedStateFromProps(props, prevState) {
    const { prevProps } = prevState || {};
    if (props === prevProps) return {};
    return {
      buckets: props.buckets,
      prevProps: props,
    };
  }

  constructor(props) {
    super(props);
    const { registerWithCell, answerUpdate } = window.poormansContextReplacement;
    const {
      cellId, rank, initialStateJson, answers, initialIsReady, course,
    } = registerWithCell(
      'dragToBucket',
    );
    let { buckets } = this.props;

    Hub.listen(`${cellId}::dragToBucket::${rank}::own`, this);
    Hub.listen(`${cellId}::dragToBucket::${rank}::others`, this);

    this.rank = rank;
    this.initialStateJson = initialStateJson;
    this.answerUpdate = answerUpdate;
    this.course = course;

    try {
      buckets = (this.initialStateJson && JSON.parse(`${this.initialStateJson}`)) || buckets;
    } catch (e) {
      logger.error('Invalid JSON', this.initialStateJson, e);
    }

    this.state = {
      buckets,
      answers,
      prevProps: props, // eslint-disable-line react/no-unused-state
      isReady: initialIsReady,
      hasOwnAnswer: !!initialStateJson,
    };
  }

  onHubCapsule(capsule) {
    const { channel } = capsule;
    if (channel.endsWith('::own')) {
      return this.onOwnAnswerCapsule(capsule);
    }
    if (channel.endsWith('::others')) {
      return this.onOthersAnswerCapsule(capsule);
    }
    logger.error('Unknown channel for capsule', capsule);
    return null;
  }

  onOwnAnswerCapsule(capsule) {
    const {
      payload: { data, isReady },
    } = capsule;
    try {
      const buckets = (data && JSON.parse(data)) || [];
      this.setState({ buckets, isReady, hasOwnAnswer: true });
    } catch (e) {
      logger.error('Invalid JSON', data, e);
    }
  }

  onOthersAnswerCapsule(capsule) {
    const { payload: answers } = capsule;
    this.setState({ answers });
  }

  moveItem = (index, fromBucket, toBucket) => {
    const { buckets } = this.state;
    const fromBucketItems = buckets[fromBucket] || [];
    const toBucketItems = buckets[toBucket] || [];

    if (index !== -1) {
      const item = fromBucketItems.splice(index, 1);
      const newBuckets = {
        ...buckets,
        [fromBucket]: fromBucketItems,
        [toBucket]: [...toBucketItems, item[0]],
      };
      this.setState({
        buckets: newBuckets,
        hasOwnAnswer: true,
      });
      this.answerUpdate('dragToBucket', this.rank, JSON.stringify(newBuckets));
    }
  };

  toggleIsReady = () => {
    const { isReady } = this.state;
    this.answerUpdate('dragToBucket', this.rank, undefined, !isReady);
    this.setState({ isReady: !isReady });
  };

  render() {
    const {
      items, validation, statement, buckets: propsBuckets,
    } = this.props;

    const {
      answers, buckets: stateBuckets, isReady, hasOwnAnswer,
    } = this.state;

    const { isTeacher } = Cache.getItem('userRoles') || {};

    if (!stateBuckets) return null;

    let isCorrect = dragToBucketValidator(validation, items, stateBuckets);

    const buckets = [];

    Object.keys(propsBuckets).forEach((bucketName) => {
      stateBuckets[bucketName].forEach((item) => {
        if (items[item].belongsTo !== bucketName) {
          isCorrect = false;
        }
      });
      buckets.push({ name: bucketName, contents: stateBuckets[bucketName] });
    });

    let isReadyStr = isReady ? 'yes' : 'no';
    if (!hasOwnAnswer) isReadyStr = 'disabled';

    return (
      <React.Fragment>
        <Cell
          {...this.props}
          isReady={isReadyStr}
          toggleIsReady={this.toggleIsReady}
          type="dragToBucket"
          isCorrect={isCorrect}
          renderAnswer={this.renderAnswer}
        >
          <div className="kalfa-cell-statement kalfa-dragtobucket-statement">{statement}</div>
          {<Widget buckets={buckets} items={items} moveItem={this.moveItem} />}
        </Cell>
        {isTeacher && (
          <CellAnswers answers={answers} course={this.course} type="dragToBucket" {...this.props} />
        )}
      </React.Fragment>
    );
  }
}

export default DragToBucketCell;
