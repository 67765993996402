import React, { Component } from 'react';
import t from 'prop-types';
import { Button, ButtonIcon } from 'rmwc/Button';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, DialogButton,
} from 'rmwc/Dialog';

import I18n from '../../util/i18n';

import styles from './AddCellButton.css';

import '@material/dialog/dist/mdc.dialog.css';
import '@material/button/dist/mdc.button.css';
import '@material/typography/dist/mdc.typography.css';
import '@material/list/dist/mdc.list.css';
import '@material/theme/dist/mdc.theme.css';

class AddRawCellButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  toggleDialog = (isOpen) => {
    this.setState({ isOpen });
  };

  handleOnAdd = type => () => {
    const { onAdd } = this.props;
    onAdd(type);
    this.toggleDialog(false);
  };

  render() {
    const { isOpen } = this.state;

    return (
      <React.Fragment>
        <div className={styles.container}>
          <div onClick={() => this.toggleDialog(true)} className={styles.button}>
            <ButtonIcon icon="add" className={styles.buttonIcon} />
          </div>
        </div>
        <Dialog open={isOpen} onClose={() => this.toggleDialog(false)}>
          <DialogTitle>{I18n.get('Choose type')}</DialogTitle>
          <DialogContent>
            <Button raised onClick={this.handleOnAdd('html')} className={styles.addButton}>
              {I18n.get('Text')}
            </Button>
            <Button raised onClick={this.handleOnAdd('video')} className={styles.addButton}>
              {I18n.get('Video')}
            </Button>
            <Button raised onClick={this.handleOnAdd('image')} className={styles.addButton}>
              {I18n.get('Image')}
            </Button>
            <Button onClick={this.handleOnAdd('essay')} raised>
              {I18n.get('Essay')}
            </Button>
          </DialogContent>
          <DialogActions>
            <DialogButton action="close" isDefaultAction>
              {I18n.get('Cancel')}
            </DialogButton>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

AddRawCellButton.propTypes = {
  onAdd: t.func.isRequired,
};

export default AddRawCellButton;
