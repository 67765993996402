import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import compose from 'lodash/flowRight';

import QueryCourseGet from '../../GraphQL/QueryCourseGet';

import component from './Course';

export default compose(
  withRouter,
  graphql(QueryCourseGet, {
    options: (props) => {
      const {
        match: {
          params: { courseId },
        },
      } = props;
      return {
        variables: {
          courseId,
        },
      };
    },
    props: ({ data: { loading, courseGet }, ownProps }) => {
      const {
        match: {
          params: { courseId, bookId, pageId },
        },
      } = ownProps;
      return {
        courseId,
        bookId,
        pageId,
        loading,
        course: courseGet,
      };
    },
  }),
)(component);
