// @ts-check
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import compose from 'lodash/flowRight';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';

import Cache from '../../../util/cache';
import MutationUserLeaveCourse from '../../../GraphQL/MutationUserLeaveCourse';
import QueryCourseGet from '../../../GraphQL/QueryCourseGet';
import SubscriptionStudentStatusUpdated from '../../../GraphQL/SubscriptionStudentStatusUpdated';
import SubscriptionCourseUpdated from '../../../GraphQL/SubscriptionCourseUpdated';
import SubscriptionCourseCellUpdated from '../../../GraphQL/SubscriptionCourseCellUpdated';
import SubscriptionCourseCellDeleted from '../../../GraphQL/SubscriptionCourseCellDeleted';
import SubscriptionAnswerUpdated from '../../../GraphQL/SubscriptionAnswerUpdated';

import component from './Component';

const answerUpdateQuery = (
  prev,
  {
    subscriptionData: {
      data: { answerUpdated },
    },
  },
) => {
  // This updates only the CourseCells.
  // for the Cell answers themselves there is a similar function
  // in PageViewContainer

  const prevCells = prev.courseGet.courseCells;

  const {
    userId, cellId, answers = [], user,
  } = answerUpdated;

  const { id: currentUserId } = Cache.getItem('userCurrent');
  const { isTeacher } = Cache.getItem('userRoles');

  const cellIds = prevCells.map(cell => cell.id);
  const cellIndex = cellIds.indexOf(cellId);

  if (cellIndex < 0) return prev; // not on this page

  const prevCell = prevCells[cellIndex] || {};

  if (isTeacher) {
    const prevAnswers = prevCell.answers || [];
    const answerIndex = prevAnswers.map(a => a.user.id).indexOf(userId);

    if (answerIndex < 0) {
      // new customer
      prevAnswers.push({
        user,
        answers,
        __typename: 'Answer',
      });
    } else {
      const prevAnswer = prevAnswers[answerIndex];
      let newAnswerDatas = prevAnswer.answers;

      answers.forEach((newAD) => {
        const {
          answerType: newAT, index: newI, data: newD, isReady: newIR,
        } = newAD;

        newAnswerDatas = newAnswerDatas.map((prevAD) => {
          const { answerType: prevAT, index: prevI } = prevAD;
          if (newAT !== prevAT) return prevAD;
          if (newI !== prevI) return prevAD;
          return {
            ...prevAD,
            data: newD,
            isReady: newIR,
            __typename: 'AnswerData',
          };
        });
        prevAnswer.answers = newAnswerDatas;
      });
    }
  }

  if (userId === currentUserId) {
    // update myAnswer, too
    let prevMyAnswerData = (prevCell.myAnswer || {}).answers || [];

    answers.forEach((newAD) => {
      const {
        answerType: newAT, index: newI, data: newD, isReady: newIR,
      } = newAD;

      const matchedAD = prevMyAnswerData.filter(
        prevAD => prevAD.answerType === newAT && prevAD.index === newI,
      );

      if (matchedAD.length) {
        prevMyAnswerData = prevMyAnswerData.map((prevAD) => {
          const { answerType: prevAT, index: prevI } = prevAD;
          if (newAT !== prevAT) return prevAD;
          if (newI !== prevI) return prevAD;
          return {
            ...prevAD,
            data: newD,
            isReady: newIR,
            __typename: 'AnswerData',
          };
        });
      } else {
        prevMyAnswerData = prevMyAnswerData.slice(0); // create a copy to refresh
        prevMyAnswerData.push(newAD);
      }
    });

    prevCell.myAnswer = { ...prevCell.myAnswer, answers: prevMyAnswerData, __typename: 'Answer' };
  }

  return {
    ...prev,
    courseGet: { ...prev.courseGet, courseCells: prevCells },
  };
};

export default compose(
  withRouter,
  graphql(QueryCourseGet, {
    props: ({
      data: {
        // @ts-ignore
        loading,
        courseGet,
        subscribeToMore,
      },
      ownProps,
    }) => ({
      ...ownProps,
      loading,
      students: ((courseGet || {}).students || {}).items || [],
      course: courseGet || {},
      subscribeToCourseUpdated: () => subscribeToMore({
        document: SubscriptionCourseUpdated,
        // @ts-ignore
        variables: { id: ownProps.courseId },
        updateQuery: (
          prev,
          {
            subscriptionData: {
              data: { courseUpdated },
            },
          },
        ) => ({ ...prev, ...courseUpdated }),
      }),
      subscribeToCourseCellUpdated: () => subscribeToMore({
        document: SubscriptionCourseCellUpdated,
        // @ts-ignore
        variables: { courseId: ownProps.courseId },
        updateQuery: (
          prev,
          {
            subscriptionData: {
              data: { courseCellUpdated },
            },
          },
        ) => {
          const newCell = { ...courseCellUpdated };
          const courseCells = get(prev, ['courseGet', 'courseCells'], []).slice(0);
          const { id } = courseCellUpdated;

          const cellIndex = findIndex(courseCells, { id });

          newCell.myAnswer = newCell.myAnswer || null;
          newCell.answers = newCell.answers || [];

          if (cellIndex > -1) {
            courseCells[cellIndex] = { ...courseCells[cellIndex], newCell };
          } else {
            courseCells.push(newCell);
          }

          return {
            ...prev,
            courseGet: {
              ...prev.courseGet,
              courseCells,
            },
          };
        },
      }),
      subscribeToCourseCellDeleted: () => subscribeToMore({
        document: SubscriptionCourseCellDeleted,
        // @ts-ignore
        variables: { courseId: ownProps.courseId },
        updateQuery: (
          prev,
          {
            subscriptionData: {
              data: { courseCellDeleted },
            },
          },
        ) => {
          const { id } = courseCellDeleted;
          const courseCells = get(prev, ['courseGet', 'courseCells'], []).filter(
            cell => cell.id !== id,
          );
          return {
            ...prev,
            courseGet: {
              ...prev.courseGet,
              courseCells,
            },
          };
        },
      }),
      subscribeToUpdateStudentStatus: () => subscribeToMore({
        document: SubscriptionStudentStatusUpdated,
        variables: {
          // @ts-ignore
          courseId: ownProps.courseId,
        },
        updateQuery: (
          prev,
          {
            subscriptionData: {
              data: { studentStatusUpdated },
            },
          },
        ) => {
          const {
            user: { id: userId },
            status,
          } = studentStatusUpdated;

          const students = prev.courseGet.students.map(s => (s.user.id === userId ? { ...s, status } : s));

          return {
            ...prev,
            courseGet: { ...prev.courseGet, students },
          };
        },
      }),
      subscribeToAnswerUpdated: () => subscribeToMore({
        document: SubscriptionAnswerUpdated,
        // @ts-ignore
        variables: { courseId: ownProps.courseId },
        updateQuery: answerUpdateQuery,
      }),
    }),
  }),
  graphql(MutationUserLeaveCourse, {
    props: ({ mutate }) => ({
      leaveCourse: () => mutate({
        // @ts-ignore
        update: (_proxy_, { data: { userLeaveCourse } }) => {
          if (userLeaveCourse) {
            window.location.pathname = '/join';
          }
        },
      }),
    }),
  }),
)(component);
