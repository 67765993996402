import { graphql } from 'react-apollo';

import compose from 'lodash/flowRight';

import MutationCourseActivityAppend from '../../../GraphQL/MutationCourseActivityAppend';
import MutationCourseActivityDelete from '../../../GraphQL/MutationCourseActivityDelete';

import component from './ClassWall';

export default compose(
  graphql(MutationCourseActivityAppend, {
    props: ({ mutate, ownProps: { courseId } }) => ({
      activityAppend: (content, type = 'html') => mutate({
        variables: {
          courseId,
          content,
          type,
        },
      }),
    }),
  }),
  graphql(MutationCourseActivityDelete, {
    props: ({ mutate, ownProps: { courseId } }) => ({
      activityDelete: index => mutate({
        variables: {
          courseId,
          index,
        },
      }),
    }),
  }),
)(component);
