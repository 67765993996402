import { graphql } from 'react-apollo';
import compose from 'lodash/flowRight';

import MutationBookUpdate from '../../GraphQL/MutationBookUpdate';
import QueryBookGet from '../../GraphQL/QueryBookGet';

import component from './EditBookMetadataButtonAndDialog';

export default compose(
  graphql(QueryBookGet, {
    props: ({ data: { bookGet, loading } }) => {
      const { title, subtitle } = bookGet || [];
      return {
        title,
        subtitle,
        loadingBook: loading,
      };
    },
  }),
  graphql(MutationBookUpdate, {
    props: ({ mutate, ownProps }) => ({
      onSave: input => mutate({
        variables: {
          id: ownProps.bookId,
          ...input,
        },
        optimisticResponse: () => ({
          updateBook: {
            id: ownProps.bookId,
            ...input,
            __typename: 'Book',
            optimistic: true,
          },
        }),
      }),
    }),
  }),
)(component);
