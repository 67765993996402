import React from 'react';
import { Menu, MenuItem } from 'rmwc/Menu';
import t from 'prop-types';

import I18n from '../../../util/i18n';

const RightClickMenu = ({
  onClose, isOpen, onDelete, openMetadataDialog,
}) => (
  <Menu open={isOpen} onClose={onClose}>
    <MenuItem onClick={onDelete}>{I18n.get('Delete')}</MenuItem>
    <MenuItem onClick={openMetadataDialog}>{I18n.get('Edit')}</MenuItem>
  </Menu>
);

RightClickMenu.propTypes = {
  onClose: t.func,
  onDelete: t.func,
  openMetadataDialog: t.func,
  isOpen: t.bool,
};

RightClickMenu.defaultProps = {
  onClose: () => {},
  onDelete: () => {},
  openMetadataDialog: () => {},
  isOpen: false,
};

export default RightClickMenu;
