import React from 'react';
import ReactDom from 'react-dom';
import t from 'prop-types';

import CourseContext from '../Courses/View/CourseContext';

const getTextToCode = () => import(/* webpackChunkName: "textToCode" */ '../../util/textToCode')
  .then(
    ({ default: textToCode }) => textToCode,
  );

const answerPropType = t.shape({
  answerType: t.oneOf(['quiz', 'essay', 'dragAndSort', 'dragToBucket', 'paint', 'geogebra']).isRequired,
  index: t.number.isRequired,
  data: t.string,
  isReady: t.bool,
});

const ErrorFrame = ({
  error: {
    loc: { line, column },
    message,
  },
}) => (
  <div>
    {line}, {column}
    <pre>{message}</pre>
  </div>
);

ErrorFrame.propTypes = {
  error: t.shape({
    loc: t.shape({
      line: t.number,
      column: t.number,
    }),
    message: t.string,
  }).isRequired,
};

class RawCell extends React.Component {
  static propTypes = {
    contents: t.string,
    id: t.string,
    myAnswer: t.shape({
      answers: t.arrayOf(answerPropType),
    }),
    transpiledCode: t.string,
    answers: t.arrayOf(
      t.shape({
        user: t.shape({
          id: t.string,
          displayName: t.string,
        }),
        answers: t.arrayOf(answerPropType),
      }),
    ),
    answerUpdate: t.func,
  };

  static defaultProps = {
    contents: '',
    id: '',
    transpiledCode: '',
    myAnswer: {},
    answers: [],
    answerUpdate: () => {},
  };

  constructor(props) {
    super(props);
    const { contents, transpiledCode, id } = props;

    if (transpiledCode) {
      this.transform = {
        code: `;window.h=React.createElement;${transpiledCode}`,
      };
    } else {
      getTextToCode().then((textToCode) => {
        this.transform = textToCode(contents, id);
      });
    }
  }

  async componentDidMount() {
    setTimeout(() => {
      this.refresh();
    }, 200);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { contents, transpiledCode, id } = newProps;
    if (this.contents === contents) return;
    if (transpiledCode) {
      this.transform = {
        code: transpiledCode.split('h(').join('React.createElement('),
      };
      this.refresh();
    } else {
      getTextToCode().then((textToCode) => {
        this.transform = textToCode(contents, id);
        this.refresh();
      });
    }
  }

  componentWillUnmount() {
    ReactDom.unmountComponentAtNode(this.root);
  }

  componentDidCatch(error) {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ error: <ErrorFrame error={error} /> });
  }

  refresh() {
    const { answers, myAnswer, answerUpdate } = this.props;

    if (this.transform && this.transform.err) {
      // eslint-disable-next-line react/no-unused-state
      this.setState({ error: <ErrorFrame error={this.transform.err} /> });
    } else {
      try {
        const componentExecutableCode = this.transform.code.startsWith('"use strict";')
          ? this.transform.code.substr('"use strict";'.length)
          : this.transform.code;

        let RawCellComponent = '';
        try {
          // eslint-disable-next-line no-eval
          RawCellComponent = eval(componentExecutableCode);
        } catch (e) {
          console.error(e);
        }
        const { id: cellId } = this.props;
        const currentIndexes = {};

        const registerWithCell = (cellType) => {
          const currentValue = currentIndexes[cellType] || 0;
          currentIndexes[cellType] = currentValue + 1;

          let initialStateJson;
          let initialIsReady;
          const othersAnswers = {};

          if (myAnswer && myAnswer.answers && myAnswer.answers.length) {
            const [widgetAnswer = {}] = myAnswer.answers.filter(
              ({ answerType, index }) => answerType === cellType && index === currentValue,
            );
            initialStateJson = widgetAnswer.data;
            initialIsReady = widgetAnswer.isReady;
          }

          if (answers && answers.length) {
            answers.forEach(({ user: { displayName }, answers: userAnswers }) => {
              if (!displayName) return;
              const [userAnswer] = userAnswers.filter(
                ({ answerType, index }) => answerType === cellType && index === currentValue,
              );
              othersAnswers[displayName] = userAnswer || {};
            });
          }

          return {
            cellId,
            rank: currentValue,
            initialStateJson,
            initialIsReady,
            answers: othersAnswers,
            course: this.course,
          };
        };

        const onAnswerUpdate = (answerType, index, data, isReady) => {
          const newAnswer = {
            myAnswer,
            cellId,
            answerType,
            index,
          };
          if (typeof data !== 'undefined') newAnswer.data = data;
          if (typeof isReady !== 'undefined') newAnswer.isReady = isReady;
          if (typeof answerUpdate === 'function') {
            answerUpdate(newAnswer);
          }
        };

        const Wrapper = ({ children }) => {
          window.poormansContextReplacement = {
            registerWithCell,
            answerUpdate: onAnswerUpdate,
          };
          return children;
        };

        ReactDom.render(<Wrapper>{RawCellComponent}</Wrapper>, this.root);

        // eslint-disable-next-line react/no-unused-state
        this.setState({ error: undefined });
      } catch ({ message }) {
        // eslint-disable-next-line react/no-unused-state
        this.setState({
          // eslint-disable-next-line react/no-unused-state
          error: <ErrorFrame error={{ loc: { line: undefined, column: undefined }, message }} />,
        });
      }
    }
  }

  render() {
    const { error } = this.state || {};
    const getContent = ({ course }) => (
      <div
        ref={(c) => {
          this.root = c;
          this.course = course;
        }}
      >
        {error}
      </div>
    );
    return <CourseContext.Consumer>{getContent}</CourseContext.Consumer>;
  }
}

export default RawCell;
