import gql from 'graphql-tag';

export default gql`
  mutation courseCellUpdate(
    $id: ID!
    $courseId: ID
    $afterCell: ID
    $rank: Int
    $contents: String
  ) {
    courseCellUpdate(
      id: $id
      courseId: $courseId
      afterCell: $afterCell
      rank: $rank
      contents: $contents
    ) {
      id
      courseId
      afterCell
      rank
      cellType
      contents
    }
  }
`;
