import React from 'react';
import t from 'prop-types';

import urlParser from '../../../../../node_modules/js-video-url-parser/dist/jsVideoUrlParser';

import styles from './Video.css';

const View = ({ uri }) => {
  if (/^(http|https).*/.test(uri)) {
    const parsedContent = urlParser.parse(uri);
    if (!parsedContent) return <p>Invalid or unsupported Video link. Edit and try again</p>;

    const { provider, id } = parsedContent;
    if (provider !== 'youtube' && provider !== 'vimeo') {
      return (
        <p>Only Vimeo and YouTube are supported. You can, however, upload a video yourself.</p>
      );
    }

    const iframe = provider === 'youtube'
      ? `https://www.youtube.com/embed/${id}`
      : `https://player.vimeo.com/video/${id}`;

    return (
      <div className={styles.iframeWrapper}>
        <iframe title={iframe} src={iframe} frameBorder="0" style={{ width: '100%' }} />
      </div>
    );
  }

  const src = [
    process.env.REACT_APP_URL,
    process.env.REACT_APP_KLFA_S3URL,
    uri,
  ].join('/');

  return (
    <video className={styles.video} key={src} controls>
      <track kind="captions" />
      <source src={src} type="video/mp4" />
      Your browser does not support the video element.
    </video>
  );
};

View.propTypes = {
  uri: t.string,
};

View.defaultProps = {
  uri: 'https://placekitten.com/g/450/200',
};

export default View;
