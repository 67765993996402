import React from 'react';
import t from 'prop-types';
import { IconButton } from 'rmwc/IconButton';
import { Typography } from 'rmwc/Typography';

import Cache from '../../util/cache';
import I18n from '../../util/i18n';

import styles from './Cell.css';
import '@material/button/dist/mdc.button.min.css';

const INTERACTIVE_WIDGETS = ['quiz', 'essay', 'dragAndSort', 'dragToBucket', 'paint', 'geogebra'];

const renderEmoji = (isCorrect, isReady, validation, type, disableValidationFeedback) => {
  if (disableValidationFeedback) return null;
  if (!validation && (type === 'quiz' || type === 'essay' || type === 'paint')) return null;
  if (!isReady) {
    return <img className={styles.correctnessImage} src="/images/021-sleeping.png" alt="" />;
  }
  if (isCorrect) {
    return <img className={styles.correctnessImage} src="/images/024-happy-1.svg" alt="" />;
  }
  return <img className={styles.correctnessImage} src="/images/022-surprised-1.svg" alt="" />;
};

const Cell = ({
  name,
  children,
  type,
  customCss,
  isCorrect,
  noCheckbox,
  className,
  isReady: isReadyStr,
  toggleIsReady,
  validation,
  disableValidationFeedback,
}) => {
  const props = {};
  const { isTeacher } = Cache.getItem('userRoles') || {};

  const isReady = isReadyStr === 'yes';
  const isReadyDisabled = isReadyStr === 'disabled';

  const classNames = ['kalfa-cell', `kalfa-cell-${type}`, className];
  if (isCorrect === false) classNames.push('klfa-cell-incorrect');
  if (isCorrect === true) classNames.push('klfa-cell-correct');
  if (name) props.name = name;

  const isReadyShown = INTERACTIVE_WIDGETS.indexOf(type) !== -1
    && !isTeacher
    && window.location.search !== '?viewOnly';

  return (
    <React.Fragment>
      {customCss && <style>{customCss}</style>}
      <div {...props} className={classNames.join(' ')}>
        {children}
        {isReady && !isTeacher ? <div className={styles.disableAction} /> : null}
      </div>
      {isReadyShown && !noCheckbox ? (
        <div className="klfa-ready-button">
          <Typography use="button">{I18n.get('Ready')}</Typography>
          <IconButton
            onChange={toggleIsReady}
            onIcon="check_box"
            icon="check_box_outline_blank"
            checked={isReady}
            disabled={isReadyDisabled}
          />
          {renderEmoji(isCorrect, isReady, validation, type, disableValidationFeedback)}
        </div>
      ) : null}
    </React.Fragment>
  );
};

Cell.propTypes = {
  name: t.string,
  children: t.oneOfType([t.node, t.arrayOf(t.node)]).isRequired,
  type: t.oneOf([
    'raw',
    'quiz',
    'essay',
    'figure',
    'goalicon',
    'youtube',
    'paint',
    'audio',
    'video',
    'dragToBucket',
    'dragAndSort',
    'carousel',
    'lightbox',
    'foldable',
    'geogebra',
  ]).isRequired,
  customCss: t.string,
  isCorrect: t.bool,
  className: t.string,
  isReady: t.oneOf(['yes', 'no', 'disabled']),
  toggleIsReady: t.func,
  // eslint-disable-next-line react/forbid-prop-types
  validation: t.any,
  disableValidationFeedback: t.bool,
};

Cell.defaultProps = {
  name: null,
  customCss: null,
  isCorrect: null,
  className: null,
  isReady: 'no',
  validation: null,
  disableValidationFeedback: false,
  toggleIsReady: () => {},
};

export default Cell;