import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';

import compose from 'lodash/flowRight';

import QueryBookGet from '../../GraphQL/QueryBookGet';
import MutationCourseCurrentPositionUpdate from '../../GraphQL/MutationCourseCurrentPositionUpdate';

import component from './NavigationButton';

export default compose(
  withRouter,
  graphql(QueryBookGet, {
    props: ({ data: { bookGet, loading } }) => ({
      book: bookGet || {},
      loading,
    }),
  }),
  graphql(MutationCourseCurrentPositionUpdate, {
    props: ({
      mutate,
      ownProps: {
        match: {
          params: { courseId },
        },
      },
    }) => ({
      onUpdate: (bookId, pageId) => mutate({
        variables: {
          courseId,
          bookId,
          pageId,
        },
      }),
    }),
  }),
)(component);
