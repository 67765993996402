import React, { Component } from 'react';
import t from 'prop-types';
import { TextField } from 'rmwc/TextField';
import { IconButton } from 'rmwc/IconButton';
import { Typography } from 'rmwc/Typography';

import Cache from '../../../../util/cache';
import I18n from '../../../../util/i18n';

import styles from './Component.css';

const stopPropagation = e => e.stopPropagation();

function getStateFromProps(props, state = {}) {
  const {
    myAnswer: { data = '', isReady = false },
  } = props;

  let parsedData = '';
  try {
    parsedData = JSON.parse(data || {});
  } catch (error) {
    // do nothing
  }

  if (parsedData === state.value && isReady === state.isReady) {
    return null;
  }

  return {
    value: parsedData,
    isReady,
  };
}
export default class Essay extends Component {
  static propTypes = {
    statement: t.string,
    myAnswer: t.shape({
      answers: t.arrayOf(t.shape({})),
    }),
    answerUpdate: t.func.isRequired,
    updateContents: t.func.isRequired,
    editMode: t.bool,
  };

  static defaultProps = {
    statement: null,
    myAnswer: null,
    editMode: false,
  };

  constructor(props) {
    super(props);
    this.state = getStateFromProps(props);
  }

  // FIXME: this will stop working in React 17
  UNSAFE_componentWillReceiveProps(newProps) {
    const newState = getStateFromProps(newProps, this.state);
    if (newState) this.setState(newState);
  }

  onChangeAnswer = (e) => {
    const { answerUpdate } = this.props;

    const { value } = e.target;
    this.setState({ value });

    if (this.timer) clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      answerUpdate({ data: JSON.stringify(value) });
    }, 1000);
  };

  onChangeStatement = (e) => {
    const { updateContents } = this.props;
    updateContents(e.target.value);
  };

  toggleIsReady = () => {
    const { answerUpdate } = this.props;

    const { isReady } = this.state;
    answerUpdate({ isReady: !isReady });
    this.setState({ isReady: !isReady });
  };

  render() {
    const { value, isReady } = this.state;
    const { editMode, statement } = this.props;
    const { isTeacher } = Cache.getItem('userRoles') || {};

    return (
      <div className={styles.essay}>
        {editMode ? (
          <div className={styles.edit}>
            <span>{I18n.get('Edit Statement: ')}</span>
            <TextField
              fullwidth
              onChange={this.onChangeStatement}
              defaultValue={statement}
              onKeyDown={stopPropagation}
            />
          </div>
        ) : (
          <div>{statement}</div>
        )}
        <div>
          <TextField
            readOnly={isReady}
            className={styles.test}
            textarea
            fullwidth
            onChange={this.onChangeAnswer}
            value={value}
            onKeyDown={stopPropagation}
          />
          {!isTeacher && (
            <div className="klfa-ready-button">
              <Typography use="button">{I18n.get('Ready')}</Typography>
              <IconButton
                onChange={this.toggleIsReady}
                onIcon="check_box"
                icon="check_box_outline_blank"
                checked={isReady}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
