import React from 'react';
import t from 'prop-types';

import { Desktop, TabletAndBelow } from '../../Responsive';
import BookInClass from '../../Books/BookInClass';
import TableOfContents from '../../Books/TableOfContents';

import NavigationUsingKeys from './NavigationUsingKeysContainer';
import UpdatePositionObserver from './UpdatePositionObserverContainer';
import CourseContext from './CourseContext';
import WindowFocusObserver from './WindowFocusObserverContainer';
import DesktopCourseSidebar from './Desktop/CourseSidebarContainer';
import DesktopTopAppBar from './Desktop/TopAppBar';
import MobileMenuSidebarContainer from './Mobile/MobileMenuSidebarContainer';
import MobileTopAppBar from './Mobile/MobileTopAppBar';
import styles from './Component.css';

class CourseViewer extends React.Component {
  static propTypes = {
    courseId: t.string.isRequired,
    bookId: t.string.isRequired,
    pageId: t.string.isRequired,
    course: t.shape({
      title: t.string,
    }).isRequired,
    leaveCourse: t.func.isRequired,
    subscribeToCourseUpdated: t.func.isRequired,
    subscribeToCourseCellUpdated: t.func.isRequired,
    subscribeToCourseCellDeleted: t.func.isRequired,
    subscribeToUpdateStudentStatus: t.func.isRequired,
    subscribeToAnswerUpdated: t.func.isRequired,
    history: t.shape({ push: t.func.isRequired }).isRequired,
  };

  constructor(props) {
    super(props);

    const {
      subscribeToUpdateStudentStatus,
      subscribeToCourseUpdated,
      subscribeToCourseCellUpdated,
      subscribeToCourseCellDeleted,
      subscribeToAnswerUpdated,
    } = props;

    this.unsubscribeFromCourseUpdated = subscribeToCourseUpdated();
    this.unsubscribeFromCourseCellUpdated = subscribeToCourseCellUpdated();
    this.unsubscribeFromCourseCellDeleted = subscribeToCourseCellDeleted();
    this.unsubscribeFromStudentStatusUpdated = subscribeToUpdateStudentStatus();
    this.unsubscribeToAnswerUpdated = subscribeToAnswerUpdated();

    this.state = {
      showSidebar: true,
      showMobileDrawer: false,
      showTableOfContents: false,
      editMode: false,
    };
  }

  componentWillUnmount() {
    this.unsubscribeFromCourseUpdated();
    this.unsubscribeFromCourseCellUpdated();
    this.unsubscribeFromCourseCellDeleted();
    this.unsubscribeFromStudentStatusUpdated();
    this.unsubscribeToAnswerUpdated();
  }

  onLeaveCourse = () => {
    const { leaveCourse, history } = this.props;
    leaveCourse();
    history.push('/');
  };

  toggleEditMode = () => {
    const { editMode } = this.state;

    this.setState({ editMode: !editMode });
  };

  toggleSidebar = () => {
    const { showSidebar } = this.state;
    let { showTableOfContents } = this.state;
    if (showSidebar === false) showTableOfContents = false;

    this.setState({
      showSidebar: !showSidebar,
      showTableOfContents,
    });
  };

  toggleMobileDrawer = () => {
    const { showMobileDrawer } = this.state;
    this.setState({
      showMobileDrawer: !showMobileDrawer,
    });
  };

  toggleTableOfContents = () => {
    const { showTableOfContents } = this.state;
    let { showSidebar } = this.state;
    if (showTableOfContents === false) showSidebar = false;

    this.setState({
      showSidebar,
      showTableOfContents: !showTableOfContents,
    });
  };

  render() {
    const {
      courseId, bookId, pageId, course, history,
    } = this.props;

    const {
      showSidebar, showMobileDrawer, showTableOfContents, editMode,
    } = this.state;

    return (
      <CourseContext.Provider
        value={{
          course,
          courseId,
          bookId,
          pageId,
        }}
      >
        <UpdatePositionObserver courseId={courseId} />
        <WindowFocusObserver />

        <Desktop>
          <DesktopTopAppBar
            showSidebar={showSidebar}
            bookId={bookId}
            course={course}
            toggleSidebar={this.toggleSidebar}
            toggleTableOfContents={this.toggleTableOfContents}
            onLeaveCourse={this.onLeaveCourse}
            toggleEditMode={this.toggleEditMode}
            componentToPrint={this.componentToPrint}
          />
          <div className="klfa-dashed-line smaller" />
          <div className={styles.contentsContainer}>
            {showSidebar && <DesktopCourseSidebar courseId={courseId} className={styles.sidebar} />}

            {showTableOfContents && (
              <TableOfContents
                bookId={bookId}
                readOnly
                course={course.id}
                className={styles.tableOfContents}
              />
            )}

            <BookInClass
              bookId={bookId}
              pageId={pageId}
              className={styles.contents}
              editMode={editMode}
              courseId={courseId}
              ref={(el) => {
                this.componentToPrint = el;
              }}
              history={history}
            />
            <NavigationUsingKeys bookId={bookId} pageId={pageId} history={history} />
          </div>
        </Desktop>

        <TabletAndBelow>
          <MobileMenuSidebarContainer
            bookId={bookId}
            open={showMobileDrawer}
            courseId={courseId}
            course={course}
            onClose={this.toggleMobileDrawer}
            onLeaveCourse={this.onLeaveCourse}
            toggleEditMode={this.toggleEditMode}
            componentToPrint={this.componentToPrint}
            history={history}
          />
          <MobileTopAppBar
            bookId={bookId}
            course={course}
            toggleSidebar={this.toggleMobileDrawer}
          />
          <div className="klfa-dashed-line smaller" />
          <BookInClass
            bookId={bookId}
            pageId={pageId}
            editMode={editMode}
            className={styles.contents}
            courseId={courseId}
            history={history}
            ref={(el) => {
              this.componentToPrint = el;
            }}
          />
        </TabletAndBelow>
      </CourseContext.Provider>
    );
  }
}

export default CourseViewer;
