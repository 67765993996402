import { withApollo, graphql } from 'react-apollo';

import compose from 'lodash/flowRight';

import getRandomPatternImage from '../../util/getRandomPatternImage';
import MutationCourseCreate from '../../GraphQL/MutationCourseCreate';
import QueryCourseList from '../../GraphQL/QueryCourseList';

import component from './AddNewCourseButton';

export default compose(
  withApollo,
  graphql(MutationCourseCreate, {
    props: ({ mutate }) => ({
      onAdd: (title, description, book) => mutate({
        variables: {
          title,
          description,
          image: getRandomPatternImage(),
          books: [book],
        },
      }),
    }),
    options: {
      refetchQueries: [{ query: QueryCourseList }],
    },
  }),
)(component);
