import React from 'react';
import t from 'prop-types';
import { Menu, MenuItem, MenuSurfaceAnchor } from 'rmwc/Menu';
import { Icon } from 'rmwc/Icon';

import '@material/menu/dist/mdc.menu.min.css';
import '@material/menu-surface/dist/mdc.menu-surface.min.css';

import styles from './CellOverlay.css';

class CellOverlay extends React.Component {
  static propTypes = {
    items: t.arrayOf(
      t.shape({
        label: t.string,
        icon: t.string,
        action: t.func,
      }),
    ),
    onClick: t.func,
  };

  static defaultProps = {
    items: [],
    onClick: () => {},
  };

  constructor() {
    super();
    this.state = {
      menuIsOpen: false,
    };
  }

  onClick = () => {
    const { menuIsOpen } = this.state;
    if (menuIsOpen) {
      return;
    }

    const { onClick } = this.props;
    onClick();
  };

  onSelect = (evt) => {
    const {
      detail: { index },
    } = evt;
    const { items } = this.props;
    evt.stopPropagation();
    const fn = items[index].action;

    if (typeof fn === 'function') {
      fn();
    } else {
      throw Error('Cell Overlay menu items should have actions...');
    }
  };

  closeMenu = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    this.setState({ menuIsOpen: false });
  };

  toggleMenu = (evt) => {
    evt.preventDefault();
    const { menuIsOpen } = this.state;
    this.setState({ menuIsOpen: !menuIsOpen });
  };

  render() {
    const { menuIsOpen } = this.state;
    const { items = [] } = this.props;

    const menuItems = items.map(({ label, icon }) => (
      <MenuItem key={icon}>
        {icon ? <Icon icon={icon} iconOptions={{ strategy: 'ligature' }} /> : null} {label}
      </MenuItem>
    ));

    return (
      <button
        className={styles.cellOverlay}
        onContextMenu={this.toggleMenu}
        onClick={this.onClick}
        type="submit"
      >
        <MenuSurfaceAnchor onContextMenu={this.toggleMenu}>
          <Menu open={menuIsOpen} onSelect={this.onSelect} onClose={this.closeMenu}>
            {menuItems}
          </Menu>
        </MenuSurfaceAnchor>
      </button>
    );
  }
}

export default CellOverlay;
