import gql from 'graphql-tag';

export default gql`
  mutation userJoinCourse($code: String!) {
    userJoinCourse(code: $code) {
      id
      currentUserPosition {
        bookId
        pageId
      }
    }
  }
`;
