import React, { Component } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import t from 'prop-types';

import Bucket from './Bucket';
import styles from './DragToBucket.css';

import '@material/theme/dist/mdc.theme.css';

export default class Widget extends Component {
  static propTypes = {
    buckets: t.arrayOf(
      t.shape({
        name: t.string.isRequired,
        contents: t.arrayOf(t.string.isRequired).isRequired,
      }),
    ).isRequired,
    moveItem: t.func.isRequired,
    viewOnly: t.bool,
    items: t.shape({}).isRequired,
  };

  static defaultProps = {
    viewOnly: false,
  };

  onDragEnd = (result) => {
    const { moveItem } = this.props;
    const { source, destination } = result;
    if (!source || !destination) return;
    moveItem(source.index, source.droppableId, destination.droppableId);
  };

  render() {
    const { buckets, items, viewOnly } = this.props;

    const bucketComponents = buckets.map(({ name: bucketName, contents }) => (
      <Bucket
        name={bucketName}
        key={bucketName}
        items={contents}
        allItems={items}
        widthPercentage={100 / buckets.length}
      />
    ));

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <div className={styles.widget}>
          {viewOnly ? <div className={styles.viewOnly} /> : null}
          {bucketComponents}
        </div>
      </DragDropContext>
    );
  }
}
