import React from 'react';
import t from 'prop-types';

import Editor from '../../components/Books/BookEditor';
import View from '../../components/Books/BookView';

const Book = (props) => {
  const {
    match: {
      params: { bookId, pageId },
    },
    location: { search },
  } = props;

  if (search === '?viewOnly') {
    return <View bookId={bookId} pageId={pageId} />;
  }
  return <Editor bookId={bookId} pageId={pageId} />;
};

Book.propTypes = {
  match: t.shape({
    params: t.shape({
      bookId: t.string,
      pageId: t.string,
    }),
  }),
  location: t.shape({
    search: t.string,
  }),
};

Book.defaultProps = {
  match: { params: { bookId: null, pageId: null } },
  location: { search: '' },
};

export default Book;
