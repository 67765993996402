import gql from 'graphql-tag';

export default gql`
  subscription courseCurrentPositionUpdated($id: ID!) {
    courseCurrentPositionUpdated(id: $id) {
      id
      currentPosition {
        bookId
        pageId
      }
    }
  }
`;
