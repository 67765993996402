import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';

import compose from 'lodash/flowRight';

import QueryCourseGet from '../../../GraphQL/QueryCourseGet';
import SubscriptionCourseCurrentPositionUpdated from '../../../GraphQL/SubscriptionCourseCurrentPositionUpdated';

import component from './UpdatePositionObserver';

export default compose(
  withRouter,
  graphql(QueryCourseGet, {
    props: ({ data: { subscribeToMore, courseGet: course }, ownProps }) => ({
      ...ownProps,
      course: course || {},
      subscribeToUpdateCourseCurrentPosition: () => {
        subscribeToMore({
          document: SubscriptionCourseCurrentPositionUpdated,
          variables: {
            id: ownProps.courseId,
          },
          updateQuery: (
            prev,
            {
              subscriptionData: {
                data: { courseCurrentPositionUpdated },
              },
            },
          ) => ({
            ...prev,
            ...courseCurrentPositionUpdated,
            cameFromTheServer: true,
          }),
        });
      },
    }),
  }),
)(component);
