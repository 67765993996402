import gql from 'graphql-tag';

export default gql`
  query courseGet($courseId: ID!) {
    courseGet(id: $courseId) {
      id
      title
      description
      image
      code
      syncWithStudents
      books {
        id
        pages {
          id
        }
      }
      currentPosition {
        bookId
        pageId
      }
      currentUserPosition {
        bookId
        pageId
      }
      students {
        user {
          id
          displayName
        }
        status
      }
      activities {
        content
        type
        createdAt
      }
      courseCells {
        id
        contents
        afterCell
        rank
        cellType
        myAnswer {
          answers {
            answerType
            index
            data
            isReady
          }
        }
        answers {
          user {
            id
            displayName
            roles
          }
          answers {
            answerType
            index
            data
            isReady
          }
        }
      }
    }
  }
`;
