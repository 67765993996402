import React from 'react';
import t from 'prop-types';
import { TopAppBar, TopAppBarRow, TopAppBarSection } from 'rmwc/TopAppBar';
import { Link } from 'react-router-dom';
import { ButtonIcon } from 'rmwc/Button';

import I18n from '../../util/i18n';
import Cache from '../../util/cache';
import PageEditor from '../Pages/PageEditorContainer';

import CustomBookCssAndCodeRendererHOC from './CustomBookCssAndCodeRendererContainerHOC';
import TableOfContents from './TableOfContents';
import EditCustomCssAndCodeButtonAndDialog from './EditCustomCssAndCodeButtonAndDialogContainer';
import EditBookMetadataButtonAndDialog from './EditBookMetadataButtonAndDialogContainer';
import styles from './BookEditor.css';

const renderTopBar = bookId => (
  <React.Fragment>
    <TopAppBar fixed className={styles.topAppBar}>
      <TopAppBarRow>
        <TopAppBarSection alignStart>
          <Link className="mdc-button mdc-ripple-upgraded" to="/books">
            <ButtonIcon icon="arrow_back_ios" />
            {I18n.get('Exit Editor')}
          </Link>
        </TopAppBarSection>
        <TopAppBarSection alignEnd>
          <EditBookMetadataButtonAndDialog bookId={bookId} />
          <EditCustomCssAndCodeButtonAndDialog bookId={bookId} />
        </TopAppBarSection>
      </TopAppBarRow>
    </TopAppBar>
  </React.Fragment>
);

const renderPage = (bookId, pageId) => {
  if (!pageId) {
    return (
      <div className={`${styles.page} ${styles.placeholder}`}>
        {I18n.get('Select a page from the left to edit')}
      </div>
    );
  }
  return <PageEditor bookId={bookId} pageId={pageId} className={styles.page} />;
};

const BookEditor = ({ bookId, pageId }) => {
  const { isPublisher } = Cache.getItem('userRoles') || {};
  if (!isPublisher) {
    window.location = `${window.location.toString()}?viewOnly`;
  }

  return (
    <div className={styles.container}>
      {renderTopBar(bookId)}
      <CustomBookCssAndCodeRendererHOC bookId={bookId}>
        <div className={styles.sidebar}>
          <TableOfContents bookId={bookId} className={styles.widthToc} />
          {renderPage(bookId, pageId)}
        </div>
      </CustomBookCssAndCodeRendererHOC>
    </div>
  );
};

BookEditor.propTypes = {
  bookId: t.string.isRequired,
  pageId: t.string,
};

BookEditor.defaultProps = {
  pageId: null,
};

export default BookEditor;
