import React from 'react';
import t from 'prop-types';
import { TabBar, Tab } from 'rmwc/Tabs';
// import { HorizontalBar } from 'react-chartjs-2';
import { Chip, ChipSet } from 'rmwc/Chip';

import I18n from '../../../util/i18n';
// import { essayValidator } from '../../../util/cellAnswersValidator';

import './CellAnswersDialog.css';

import '@material/tab-bar/dist/mdc.tab-bar.min.css';
import '@material/typography/dist/mdc.typography.min.css';
import '@material/chips/dist/mdc.chips.min.css';

class CellAnswersEssay extends React.Component {
  constructor() {
    super();
    this.state = {
      activeTabIndex: 0,
    };
  }

  onTabChange = evt => this.setState({ activeTabIndex: evt.detail.index });

  renderAnswer = (answer) => {
    const parsedAnswer = JSON.parse(`{"data":${answer}}`).data;
    const lines = parsedAnswer.split('\\n');
    return lines.map((line, index) => (
      <React.Fragment key={index}>
        <span>{line}</span>
        <br />
      </React.Fragment>
    ));
  };

  render() {
    const { answers /* items, validation */ } = this.props;
    const { activeTabIndex } = this.state;

    const getContents = (index) => {
      switch (index) {
        case 1:
          return <div className="klfa-answers-tab-container">WordCloud</div>;
        case 0:
          return (
            <React.Fragment>
              {Object.keys(answers).map((k) => {
                // const isCorrect = quizValidator(validation, JSON.parse(answers[k].data));
                const isCorrect = true;
                return (
                  <div
                    className={`klfa-answers-student-essay ${
                      isCorrect ? '__correct' : '__incorrect'
                    }`}
                    key={k}
                  >
                    <div className="name-chips">
                      <ChipSet>
                        <Chip
                          className="klfa-answers-student-name"
                          leadingIcon={isCorrect ? 'check_circle' : 'remove_circle_online'}
                          text={k}
                        />
                      </ChipSet>
                    </div>
                    <div className="klfa-answers-student-essay-data">
                      {this.renderAnswer(answers[k].data || '""')}
                    </div>
                  </div>
                );
              })}
            </React.Fragment>
          );
        default:
          return <h1>500 Invalid Index</h1>;
      }
    };
    return (
      <React.Fragment>
        <TabBar activeTabIndex={activeTabIndex} onActivate={this.onTabChange}>
          <Tab>{I18n.get('Answers')}</Tab>
          {/* <Tab>{I18n.get('Word Cloud')}</Tab> */}
        </TabBar>
        <div className="klfa-answers-dialog-content">{getContents(activeTabIndex)}</div>
      </React.Fragment>
    );
  }
}

CellAnswersEssay.propTypes = {
  answers: t.shape({}),
};

CellAnswersEssay.defaultProps = {
  answers: {},
};

export default CellAnswersEssay;
