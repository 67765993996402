import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import compose from 'lodash/flowRight';

import MutationCourseCurrentPositionUpdate from '../../../GraphQL/MutationCourseCurrentPositionUpdate';

import component from './ListItem';

export default compose(
  withRouter,
  graphql(MutationCourseCurrentPositionUpdate, {
    props: ({
      mutate,
      ownProps: {
        match: {
          params: { courseId },
        },
      },
    }) => ({
      onUpdate: (bookId, pageId) => {
        if (courseId) {
          mutate({
            variables: {
              courseId,
              bookId,
              pageId,
            },
          });
        }
      },
    }),
  }),
)(component);
