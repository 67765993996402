import React, { Component } from 'react';
import t from 'prop-types';
import { Icon } from 'rmwc/Icon';
import { Button, ButtonIcon } from 'rmwc/Button';

import '@material/button/dist/mdc.button.css';
import '@material/list/dist/mdc.list.css';
import '@material/theme/dist/mdc.theme.css';
import '@material/typography/dist/mdc.typography.css';

import I18n from '../../util/i18n';
import RawCell from '../Cells/Raw';
import PleaseWait from '../PleaseWait';
import BookTitleAndSubtitle from '../Books/BookTitleAndSubtitleContainer';

import AddRawCellButton from './AddRawCellButtonContainer';
import CellOverlay from './CellOverlay';
import styles from './PageEditor.css';
import EditPageMetadata from './EditPageMetadataContainer';

const CellPropTypes = {
  id: t.string.isRequired,
  customCss: t.string,
  contents: t.string,
  visibility: t.oneOf(['all', 'teacher']),
  __typename: t.string,
};

const PagePropTypes = t.shape({
  id: t.string.isRequired,
  title: t.string,
  subtitle: t.string,
  editingPhase: t.oneOf(['draft', 'published']),
  createdAt: t.number,
  updatedAt: t.number,
  customCss: t.string,
  cells: t.arrayOf(t.shape(CellPropTypes)),
});

const PageDefaultPropTypes = t.shape({
  id: '',
  title: I18n.get('Untitled Page'),
  subtitle: '',
});

class Editor extends Component {
  static propTypes = {
    bookId: t.string.isRequired,
    loading: t.bool,
    page: PagePropTypes,
    deleteCell: t.func.isRequired,
    className: t.string,
    history: t.shape({ push: t.func.isRequired }).isRequired,
  };

  static defaultProps = {
    loading: false,
    page: PageDefaultPropTypes,
    className: '',
  };

  constructor() {
    super();
    this.state = {
      width: 740,
    };
  }

  changeWidth = width => () => {
    this.setState({ width });
  };

  navigateTo = ({ bookId, pageId, cellId }) => () => {
    const { history } = this.props;
    history.push(`/book/${bookId}/page/${pageId}/cell/${cellId}`);
  };

  render() {
    const {
      bookId, loading, page, deleteCell, className, history,
    } = this.props;
    const { width } = this.state;

    if (!page.id) return <PleaseWait />;

    const onDeleteCell = ({ cellId, cellIndex }) => () => {
      // eslint-disable-next-line no-alert
      if (window.confirm(I18n.get('Are you sure? We have no backups'))) {
        deleteCell({ page, cellId, cellIndex });
      }
    };

    const cellItems = (page.cells || [])
      .filter(notNull => notNull)
      .map(({
        id, contents, customCss, visibility, __typename,
      }, index) => (
        <React.Fragment key={id}>
          <AddRawCellButton pageId={page.id} positionInPage={index} />
          <style>{(customCss || '').replace(/#CELLID/g, `.kalfa-cell-${id}`)}</style>
          <div
            key={id}
            className={[
              styles.cell,
              `kalfa-cell-type-${__typename}`,
              `kalfa-cell-${id}`,
              `kalfa-cell-visibility-${visibility || 'all'}`,
            ].join(' ')}
          >
            <RawCell contents={contents} index={index} id={id} />
            <CellOverlay
              onClick={this.navigateTo({ bookId, pageId: page.id, cellId: id })}
              items={[
                {
                  label: I18n.get('Edit Cell'),
                  icon: 'edit',
                  action: () => history.push(`/book/${bookId}/page/${page.id}/cell/${id}`),
                },
                {
                  label: I18n.get('Delete Cell'),
                  icon: 'delete',
                  action: onDeleteCell({ cellId: id, cellIndex: index }),
                },
              ]}
            />
          </div>
        </React.Fragment>
      ));

    return (
      <div className={`${styles.container} ${className}`}>
        <div className={styles.header}>
          <BookTitleAndSubtitle bookId={bookId} title />
          <div className={styles.buttons}>
            <Button unelevated={width === 350} onClick={this.changeWidth(350)}>
              <ButtonIcon icon="phone_iphone" /> W: 350px
            </Button>
            <Button unelevated={width === 740} onClick={this.changeWidth(740)}>
              <ButtonIcon icon="laptop" /> W: 740px
            </Button>
          </div>
        </div>
        <div className={`${styles.page} kalfa-book-${bookId}`} style={{ width: `${width}px` }}>
          {loading ? <Icon icon="hourglass_empty" iconOptions={{ strategy: 'ligature' }} /> : ''}
          <EditPageMetadata pageId={page.id} title={page.title} subtitle={page.subtitle} />
          <div>
            {cellItems}
            <AddRawCellButton pageId={page.id} positionInPage={cellItems.length} />
          </div>
        </div>
      </div>
    );
  }
}

export default Editor;
