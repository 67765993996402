import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Chip, ChipSet } from 'rmwc/Chip';
import {
  Card,
  CardPrimaryAction,
  CardMedia,
  CardAction,
  CardActions,
  CardActionButtons,
  CardActionIcons,
} from 'rmwc/Card';
import { Snackbar } from 'rmwc/Snackbar';
import { Icon } from 'rmwc/Icon';
import { SimpleMenu, MenuItem } from 'rmwc/Menu';
import { IconButton } from 'rmwc/IconButton';
import { Elevation } from 'rmwc/Elevation';
// import { LinearProgress } from 'rmwc/LinearProgress';
import { Link } from 'react-router-dom';
import t from 'prop-types';
import { Typography } from 'rmwc/Typography';

import '@material/elevation/dist/mdc.elevation.min.css';
import '@material/typography/dist/mdc.typography.css';
import '@material/snackbar/dist/mdc.snackbar.min.css';

import '@material/card/dist/mdc.card.css';
import '@material/button/dist/mdc.button.css';
import '@material/icon-button/dist/mdc.icon-button.css';
import '@material/chips/dist/mdc.chips.css';

import I18n from '../../util/i18n';

import styles from './CourseCard.css';
import RenameDialog from './RenameDialogContainer';

class Course extends React.Component {
  constructor() {
    super();
    this.state = {
      copied: false,
    };
  }

  onCopy = () => this.setState({ copied: true });

  onHide = () => this.setState({ copied: false });

  showRenameComponent = () => this.setState({ renameVisible: true });

  hideRenameComponent = () => this.setState({ renameVisible: false });

  render() {
    const {
      id,
      title,
      organisation,
      image,
      code,
      students,
      teacher,
      onDelete,
      currentPosition,
      description,
    } = this.props;

    const { copied, renameVisible = false } = this.state;

    function deleteCourse() {
      // eslint-disable-next-line no-alert
      if (window.confirm(I18n.get('Are you sure? We have no backups'))) {
        onDelete(id);
      }
    }

    const nameList = students
      .map(({ user = {} }) => user.displayName)
      .filter(notEmpty => notEmpty)
      .sort()
      .join(', ');

    const linkToCourseComponents = ['/class', id];
    const { bookId, pageId } = currentPosition || {};

    if (bookId) {
      linkToCourseComponents.push('book');
      linkToCourseComponents.push(bookId);

      if (pageId) {
        linkToCourseComponents.push('page');
        linkToCourseComponents.push(pageId);
      }
    }

    const to = linkToCourseComponents.join('/');

    // Dinu's comment:
    // This is a shitty way to display  the component, but
    // there was no reasonable way to do otherwise with the
    // current SimpleMenu from rmwc.
    // the dialog was opened inside teh menu item.
    const renameComponent = renameVisible && (
      <RenameDialog
        id={id}
        title={title}
        description={description}
        onClose={this.hideRenameComponent}
      />
    );

    return (
      <Elevation className={styles.card} z={1} wrap="true">
        {renameComponent}
        <Card id={id} style={{ position: 'relative' }}>
          <CopyToClipboard text={code} onCopy={this.onCopy}>
            <div className={styles.code}>{code}</div>
          </CopyToClipboard>
          <Link to={to}>
            <CardPrimaryAction>
              <CardMedia
                sixteenByNine
                style={{ backgroundImage: `url(/images/${image}` }}
                className={styles.cardMedia}
              />
              <div className={styles.cardContent}>
                <Typography use="headline6" tag="h2">
                  {title}
                </Typography>
                <Typography
                  use="subtitle2"
                  tag="h3"
                  theme="text-secondary-on-background"
                  className={styles.cardSecondryText}
                >
                  {organisation}
                  <br />
                  {description}
                </Typography>
                <ChipSet>
                  <Chip selected leadingIcon="person" text={teacher.displayName} disabled />
                  <Chip
                    title={nameList}
                    leadingIcon="people"
                    text={`${students.length} ${I18n.get('Students')}`}
                  />
                </ChipSet>
                {/* <div className={styles.bookContainer}>
                  <div>
                    <img
                      className={styles.bookImage}
                      src={`/images/${book.image || 'course-placeholder.jpg'}`}
                      alt="progress"
                    />
                  </div>
                  <div className={styles.bookDetails}>
                    <span className={styles.bookTitle}>{book.title}</span>
                    <div>
                      <LinearProgress progress={0.7} />
                    </div>
                  </div>
                </div> */}
              </div>
            </CardPrimaryAction>
          </Link>
          <CardActions>
            <CardActionButtons>
              <CardAction>
                <Link className="mdc-button mdc-card__action mdc-card__action--button" to={to}>
                  {I18n.get('GO TO CLASS')}
                </Link>
              </CardAction>
            </CardActionButtons>
            <CardActionIcons>
              <SimpleMenu handle={<IconButton icon="more_vert" />} anchorCorner="bottomLeft">
                <MenuItem onClick={this.showRenameComponent}>
                  <Icon icon="edit" iconOptions={{ strategy: 'ligature' }} />
                  <span>{I18n.get('Edit Info')}</span>
                </MenuItem>
                <MenuItem onClick={deleteCourse}>
                  <Icon icon="delete" iconOptions={{ strategy: 'ligature' }} />
                  <span>{I18n.get('Delete')}</span>
                </MenuItem>
              </SimpleMenu>
            </CardActionIcons>
          </CardActions>
          {copied && (
            <Snackbar
              show={copied}
              message={I18n.get('Code copied to clipboard')}
              onHide={this.onHide}
              style={{ zIndex: 1 }}
              alignStart
            />
          )}
        </Card>
      </Elevation>
    );
  }
}

Course.propTypes = {
  id: t.string,
  title: t.string,
  image: t.string,
  code: t.string,
  organisation: t.string,
  students: t.arrayOf(
    t.shape({
      displayName: '',
    }),
  ),
  teacher: t.shape({
    displayName: t.string,
  }),
  book: t.shape({
    title: t.string,
    image: t.string,
    progress: t.number,
  }),
  currentPosition: t.shape({
    bookId: t.string,
    pageId: t.string,
  }),
  onDelete: t.func.isRequired,
  description: t.string,
};

Course.defaultProps = {
  id: '',
  title: I18n.get('Untitled Class'),
  image: 'SOME_PLACEHOLDER_PLEASE',
  code: 'code',
  organisation: '',
  students: [''],
  teacher: {
    displayName: '',
  },
  book: {
    title: '',
    image: '',
    progress: 0,
  },
  currentPosition: {
    bookId: null,
    pageId: null,
  },
  description: 'description',
};

export default Course;
