// @ts-check

import React from 'react';
import t from 'prop-types';
import { Chip } from 'rmwc/Chip';
import {
  Drawer, DrawerHeader, DrawerContent, DrawerTitle,
} from 'rmwc/Drawer';
import noop from 'lodash/noop';

import '@material/drawer/dist/mdc.drawer.min.css';
import '@material/chips/dist/mdc.chips.min.css';

import {
  dragAndSortValidator,
  dragToBucketValidator,
  paintValidator,
} from '../../../util/cellAnswersValidator';
import Storage from '../../../util/storage';
import I18n from '../../../util/i18n';
import DragAndSortWidget from '../DragAndSort/Widget';
import DragToBucketWidget from '../DragToBucket/Widget';

import './CellAnswersDialog.css';

// @ts-ignore
import styles from './Generic.css';
import GeoGebraViewer from './GeoGebraViewer';

class CellAnswersDragToBucket extends React.Component {
  static propTypes = {
    answers: t.shape({}),
    type: t.oneOf(['dragToBucket', 'dragAndSort', 'paint', 'geogebra']).isRequired,
    items: t.oneOfType([t.shape({}), t.arrayOf(t.shape({}))]),
    validation: t.oneOfType([t.string, t.shape({})]),
  };

  static defaultProps = {
    answers: {},
    items: null,
    validation: null,
  };

  constructor(props) {
    super(props);
    this.state = { selectedUser: '' };
  }

  handleOnClick = user => () => {
    this.setState({ selectedUser: user });
  };

  getWidget = () => {
    const { selectedUser } = this.state;

    if (!selectedUser) {
      return (
        <div className={styles.placeholder}>
          <span>{I18n.get('Select a student')}</span>
        </div>
      );
    }

    const { answers, type, items } = this.props;
    const answer = answers[selectedUser];

    const cleanAnswer = () => JSON.parse(answer.data || '[]').map((i) => {
      if (!i.contents.$$typeof) {
        // eslint-disable-next-line no-param-reassign
        i.contents.$$typeof = Symbol.for('react.element');
      }
      return i;
    });

    const getSrc = () => {
      const { imageKey, timestamp } = JSON.parse(answer.data);
      return Storage.keyToUrl(imageKey, timestamp);
    };

    const getGgbContent = () => JSON.parse(answer.data);

    let buckets = [];
    let bucketObject;

    switch (type) {
      case 'dragToBucket':
        bucketObject = JSON.parse(answer.data) || [];
        buckets = Object.keys(bucketObject).map(bucketName => ({
          name: bucketName,
          contents: bucketObject[bucketName],
        }));
        return <DragToBucketWidget buckets={buckets} items={items} moveItem={noop} viewOnly />;
      case 'dragAndSort':
        return <DragAndSortWidget items={cleanAnswer()} moveItem={noop} persistData={noop} />;
      case 'paint':
        return <img src={getSrc()} alt="" />;
      case 'geogebra':
        return <GeoGebraViewer content={getGgbContent()} />;
      default:
        return null;
    }
  };

  render() {
    const { selectedUser } = this.state;
    const {
      answers, type, validation, items,
    } = this.props;

    const validator = (answer) => {
      let answerData = answer.data;
      try {
        answerData = JSON.parse(answer.data || {});
      } catch (error) {
        // do nothing
      }
      switch (type) {
        case 'dragAndSort':
          return dragAndSortValidator(answerData);
        case 'dragToBucket':
          return dragToBucketValidator(validation, items, answerData);
        case 'paint':
          return paintValidator(validation, answerData);
        default:
          return 'Type of exercise missing';
      }
    };

    return (
      <div className={styles.answersWrapper}>
        <Drawer>
          <DrawerHeader>
            <DrawerTitle>Antwort von Schüler/in</DrawerTitle>
          </DrawerHeader>
          <DrawerContent>
            {Object.keys(answers).map((user) => {
              const isCorrect = validator(answers[user]);
              const isSelected = selectedUser === user;

              return (
                <Chip
                  onClick={this.handleOnClick(user)}
                  className={`user ${isSelected && 'selected'} ${isCorrect && 'correct'}`}
                  key={user}
                  leadingIcon={isCorrect ? 'check_circle' : 'remove_circle_online'}
                  trailingIcon={isSelected ? 'chevron_right' : null}
                  text={user}
                />
              );
            })}
          </DrawerContent>
        </Drawer>

        <div className={styles.widget}>{this.getWidget()}</div>
      </div>
    );
  }
}

export default CellAnswersDragToBucket;
