import drawerLocalization from './drawerLocalization';
import drawerPlugins from './drawerPlugins';

export default {
  texts: drawerLocalization,
  exitOnOutsideClick: false,
  plugins: drawerPlugins,
  pluginsConfig: {
    Image: {
      scaleDownLargeImage: true,
      maxImageSizeKb: 1024, // 1MB
    },
    // // Deactivated because it's not working and not useful
    // BackgroundImage: {
    //   scaleDownLargeImage: true,
    //   maxImageSizeKb: 1024, // 1MB
    //   imagePosition: 'center', // one of  'center', 'stretch', 'top-left', 'top-right', 'bottom-left', 'bottom-right'
    //   acceptedMIMETypes: ['image/jpeg', 'image/png', 'image/gif'],
    //   dynamicRepositionImage: true,
    //   dynamicRepositionImageThrottle: 100,
    // },
  },
  toolbars: {
    drawingTools: {
      position: 'top',
      positionType: 'inside',
      customAnchorSelector: '#custom-toolbar-here',
      compactType: 'scrollable',
      hidden: false,
      toggleVisibilityButton: false,
      // fullscreenMode: {
      //   position: 'top',
      //   hidden: false,
      //   toggleVisibilityButton: false,
      // },
    },
    toolOptions: {
      position: 'bottom',
      positionType: 'inside',
      compactType: 'scrollable',
      hidden: false,
      toggleVisibilityButton: false,
      // fullscreenMode: {
      //   position: 'bottom',
      //   compactType: 'popup',
      //   hidden: false,
      //   toggleVisibilityButton: false,
      // },
    },
    settings: {
      // position : 'right',
      // positionType: 'inside',
      // compactType : 'scrollable',
      hidden: true,
      // toggleVisibilityButton: false,
      // fullscreenMode: {
      //   position: 'right',
      //   hidden: false,
      //   toggleVisibilityButton: false,
      // },
    },
  },
  // defaultImageUrl: '/examples/redactor/images/drawer.jpg',
  defaultActivePlugin: { name: 'Pencil', mode: 'lastUsed' },
  debug: true,
  transparentBackground: true,
  align: 'floating', // one of 'left', 'right', 'center', 'inline', 'floating'
};
