// @ts-check
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';

import { unregister } from './registerServiceWorker';
import './util/initApp';
import Root from './routes/Root';

// It looks like it breaks /api/* path
// import registerServiceWorker from './registerServiceWorker';

unregister();

Sentry.init({
  dsn: 'https://00e5ad1bfb7f4837ab782c8ba7ee3bb1@sentry.io/1424070',
  release: window.location.hostname,
});

const root = document.getElementById('root');

if (root !== null) {
  ReactDOM.render(<Root />, root);
}

// registerServiceWorker();
