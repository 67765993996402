import { withApollo, graphql } from 'react-apollo';

import compose from 'lodash/flowRight';

import I18n from '../../../util/i18n';
import MutationCreatePage from '../../../GraphQL/MutationCreatePage';
import MutationAppendPageToBook from '../../../GraphQL/MutationAppendPageToBook';

import component from './AddPage';

export default compose(
  withApollo,
  graphql(MutationCreatePage, {
    props: ({ mutate, ownProps: { bookId, client } }) => ({
      onAdd: () => mutate({
        variables: {
          title: I18n.get('Untitled Page'),
        },
        update: (proxy, { data: { createPage } }) => {
          if (createPage.optimistic === true) return;
          client.mutate({
            mutation: MutationAppendPageToBook,
            variables: {
              bookId,
              pageId: createPage.id,
            },
            optimisticResponse: () => ({
              appendRawCellToPage: {
                __typename: 'Page',
                id: createPage.id,
              },
            }),
          });
        },
        optimisticResponse: () => ({
          createPage: {
            id: `${Date.now()}`,
            title: I18n.get('Untitled Page'),
            __typename: 'Page',
            optimistic: true,
          },
        }),
      }),
    }),
  }),
)(component);
