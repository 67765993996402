import gql from 'graphql-tag';

export default gql`
  mutation bookUpdate(
    $id: ID!
    $title: String
    $subtitle: String
    $customCss: String
    $customJs: String
    $pageIds: [ID]
    $image: String
  ) {
    bookUpdate(
      id: $id
      title: $title
      subtitle: $subtitle
      customCss: $customCss
      customJs: $customJs
      pageIds: $pageIds
      image: $image
    ) {
      id
      title
      subtitle
      customCss
      customJs
    }
  }
`;
