import gql from 'graphql-tag';

export default gql`
  subscription answerUpdated($courseId: ID) {
    answerUpdated(courseId: $courseId) {
      userId
      cellId
      user {
        id
        displayName
        roles
      }
      answers {
        answerType
        index
        data
        isReady
      }
    }
  }
`;
