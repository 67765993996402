import React, { Component } from 'react';
import t from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';

import styles from './BucketItem.css';

class BucketItem extends Component {
  render() {
    const { contents, index } = this.props;

    return (
      <Draggable draggableId={JSON.stringify(contents)} index={index}>
        {provided => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={styles.bucketItem}
          >
            {contents}
          </div>
        )}
      </Draggable>
    );
  }
}

BucketItem.propTypes = {
  index: t.number.isRequired,
  contents: t.oneOfType([t.arrayOf(t.node), t.node]).isRequired,
};

export default BucketItem;
