import React from 'react';
import t from 'prop-types';

import Editor from '../../components/CellEditor/EditorContainer';

const Cell = ({
  match: {
    params: { bookId, pageId, cellId },
  },
}) => <Editor bookId={bookId} pageId={pageId} cellId={cellId} />;

Cell.propTypes = {
  match: t.shape({
    params: t.shape({
      bookId: t.string.isRequired,
      pageId: t.string.isRequired,
      cellId: t.string.isRequired,
    }),
  }).isRequired,
};

export default Cell;
