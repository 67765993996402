import { graphql } from 'react-apollo';

import MutationCourseCellCreate from '../../GraphQL/MutationCourseCellCreate';
import QueryCourseGet from '../../GraphQL/QueryCourseGet';

import component from './AddCourseCellButton';

export default graphql(MutationCourseCellCreate, {
  props: ({ mutate, ownProps: { courseId, rank, afterCell } }) => ({
    onAdd: cellType => mutate({
      variables: {
        courseId,
        afterCell,
        rank,
        cellType,
      },
      refetchQueries: [{ query: QueryCourseGet, variables: { courseId } }],
    }),
  }),
})(component);
