import React from 'react';
import t from 'prop-types';

import '@material/button/dist/mdc.button.css';

import I18n from '../../../util/i18n';

import './CellAnswersBar.css';

export const CellAnswersBarContainer = ({ answers, onClick, course }) => (
  <button type="button" className="klfa-answers--container" onClick={onClick}>
    <CellAnswersBar answers={answers} course={course} />
    <div className="mdc-button">{I18n.get('See results')}</div>
  </button>
);

export const CellAnswersBar = ({ answers, course }) => {
  const totalStudents = ((course && course.students) || []).length;
  if (!totalStudents) return null; // no students, no bar, otherwise division by zeros, etc.

  // somehow all empty answers for essays already contain ""
  const totalAnswersReceived = Object.values(answers).filter(
    el => el.data && el.data !== [] && el.data !== '""',
  ).length;
  const totalAnswersReady = Object.values(answers).filter(el => el.isReady === true).length;

  const percentageReady = Math.floor((totalAnswersReady / totalStudents) * 100);
  const percentageReceiving = Math.floor(
    ((totalAnswersReceived - totalAnswersReady) / totalStudents) * 100,
  );
  const percentageInactive = 100 - percentageReady - percentageReceiving;

  return (
    <div className="klfa-answers-progress-bar--container">
      {totalAnswersReady > 0 && (
        <div
          className="klfa-answers__ready"
          style={{ width: `${percentageReady}%` }}
          title={`vorliegende Schülerantworten: ${totalAnswersReady}`}
        />
      )}
      {totalAnswersReceived - totalAnswersReady > 0 && (
        <div
          className="klfa-answers__receiving"
          style={{ width: `${percentageReceiving}%` }}
          title={`Schülerantworten in Bearbeitung: ${totalAnswersReceived - totalAnswersReady}`}
        />
      )}
      {totalStudents - totalAnswersReceived > 0 && (
        <div
          className="klfa-answers__inactive"
          style={{ width: `${percentageInactive}%` }}
          title={`inaktiv: ${totalStudents - totalAnswersReceived}`}
        />
      )}
    </div>
  );
};

CellAnswersBarContainer.propTypes = {
  answers: t.shape({}),
  onClick: t.func.isRequired,
  course: t.shape({ students: t.array }),
};

CellAnswersBarContainer.defaultProps = {
  answers: {},
  course: null,
};

CellAnswersBar.propTypes = {
  answers: t.shape({}),
  course: t.shape({ students: t.array }),
};

CellAnswersBar.defaultProps = {
  answers: {},
  course: null,
};

export default CellAnswersBarContainer;
