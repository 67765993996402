import { graphql } from 'react-apollo';

import Cache from '../../../util/cache';
import MutationStudentStatusUpdate from '../../../GraphQL/MutationStudentStatusUpdate';

import component from './WindowFocusObserver';

export default graphql(MutationStudentStatusUpdate, {
  props: ({ mutate }) => ({
    onUpdate: (status) => {
      if (!(Cache.getItem('userRoles') || {}).isStudent) return;
      mutate({ variables: { status } });
    },
  }),
})(component);
