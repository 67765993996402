import { graphql } from 'react-apollo';

import MutationUpdateRawCell from '../../GraphQL/MutationCellUpdate';

import component from './EditorRawCell';

export default graphql(MutationUpdateRawCell, {
  props: ({ mutate, ownProps }) => ({
    onSave: ({ code: contents, css: customCss, visibility }) => mutate({
      variables: {
        id: ownProps.cell.id,
        contents,
        customCss,
        visibility,
      },
      optimisticResponse: () => ({
        updateRawCell: {
          id: ownProps.cell.id,
          contents,
          customCss,
          visibility,
          __typename: 'Cell',
          optimistic: true,
        },
      }),
    }),
  }),
})(component);
