import gql from 'graphql-tag';

export default gql`
  query courseList {
    courseList {
      id
      title
      image
      code
      description
      teachers {
        id
        displayName
        organisation
      }
      students {
        user {
          id
          displayName
        }
      }
      books {
        id
        title
        image
      }
      currentPosition {
        bookId
        pageId
      }
    }
  }
`;
