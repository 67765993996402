import React from 'react';

import { CellAnswersBarContainer } from './CellAnswersBar';
import CellAnswersDialog from './CellAnswersDialog';

class CellAnswers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      standardDialogOpen: false,
    };
  }

  onCloseDialog = () => {
    this.setState({ standardDialogOpen: false });
  };

  onOpenDialog = () => {
    this.setState({ standardDialogOpen: true });
  };

  render() {
    const { standardDialogOpen } = this.state;

    return (
      <React.Fragment>
        <CellAnswersBarContainer onClick={this.onOpenDialog} {...this.props} />
        <CellAnswersDialog onClose={this.onCloseDialog} open={standardDialogOpen} {...this.props} />
      </React.Fragment>
    );
  }
}

export default CellAnswers;
