import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import compose from 'lodash/flowRight';

import MutationCourseSyncWithStundentsUpdate from '../../../GraphQL/MutationCourseSyncWithStundentsUpdate';
import component from './TopAppBarButton';

export default compose(
  withRouter,
	graphql(MutationCourseSyncWithStundentsUpdate, {
		props: ({
			mutate,
			ownProps: {
				match: {
					params: { courseId },
				},
			},
		}) => ({
			onUpdate: (syncWithStudents) => mutate({
				variables: {
					courseId,
					syncWithStudents
				},
			}),
		}),
	}),
)(component);