import React from 'react';
import Responsive from 'react-responsive';

// These are modified values because of a stupid "bug" report about
// "scroll position not bein kept on rotate"; this is because changing laoyouts
// the compromise is to use mobile layout for iPad in landscape mode too

export const Desktop = props => <Responsive {...props} minWidth={1026} />;
export const Tablet = props => <Responsive {...props} minWidth={768} maxWidth={1025} />;
export const TabletAndBelow = props => <Responsive {...props} maxWidth={1025} />;
export const Mobile = props => <Responsive {...props} maxWidth={767} />;
export const Default = props => <Responsive {...props} minWidth={768} />;

// ORIGINAL VALUES
// export const Desktop = props => <Responsive {...props} minWidth={992} />;
// export const Tablet = props => <Responsive {...props} minWidth={768} maxWidth={991} />;
// export const TabletAndBelow = props => <Responsive {...props} maxWidth={991} />;
// export const Mobile = props => <Responsive {...props} maxWidth={767} />;
// export const Default = props => <Responsive {...props} minWidth={768} />;

export default Default;
