// @ts-check
import React from 'react';
// import Loadable from 'react-loadable';
import {
  BrowserRouter as Router, Route, Switch, Redirect,
} from 'react-router-dom';

import graphQLClient from '../util/getGraphQLClient';
import AuthWrapper from '../components/AuthWrapper';
import GraphQLProviderHOC from '../components/GraphQLProviderHOC';
import DefaultLayout from '../components/Layouts/Default';
import JoinCourse from '../components/JoinCourse';
import JumpTo from '../components/JumpTo';
import PleaseWait from '../components/PleaseWait';

import Books from './Books';
import Courses from './Courses';

const Dispatcher = {
  publisher: () => <Redirect to="/books" />,
  teacher: () => <Redirect to="/classes" />,
  student: user => () => {
    if (!user || !user.currentCourse) return <Redirect to="/join" />;

    const redirectToItems = ['', 'class', user.currentCourse.id];

    const { bookId = '', pageId = '' } = user.currentCourse.currentUserPosition || {};

    if (bookId) {
      redirectToItems.push('book', bookId);
      if (pageId) redirectToItems.push('page', pageId);
    }

    return <Redirect to={redirectToItems.join('/')} />;
  },
  default: () => (
    <h1>As you are not a teacher or a student, this application does not know what to do.</h1>
  ),
};

const Logout = () => {
  graphQLClient.clearStore();
  const LOGOUT_API = process.env.REACT_APP_KLFA_LOGOUT_API_ENDPOINT || '/api/logout';

  window.location.replace(LOGOUT_API);
  return <PleaseWait />;
};

const getRoutesPerRole = ({ isTeacher, isStudent, isPublisher }, user) => {
  if (isTeacher) {
    return [
      <DefaultLayout key="jump-to" path="/jump-to/book/:bookId/page/:pageId" component={JumpTo} />,
      <DefaultLayout key="courses" path="/classes" component={Courses} />,
      <Route key="course" path="/class" component={Courses} />,
      <Route key="logout" path="/logout" component={Logout} />,
      <DefaultLayout key="default" path="/" exact component={Dispatcher.teacher} />,
    ];
  }
  if (isStudent) {
    return [
      <DefaultLayout key="jump-to" path="/jump-to/book/:bookId/page/:pageId" component={JumpTo} />,
      <DefaultLayout key="join" path="/join" component={JoinCourse} />,
      <Route key="course" path="/class" component={Courses} />,
      <Route key="logout" path="/logout" component={Logout} />,
      <DefaultLayout key="default" path="/" exact component={Dispatcher.student(user)} />,
    ];
  }
  if (isPublisher) {
    return [
      <DefaultLayout key="jump-to" path="/jump-to/book/:bookId/page/:pageId" component={JumpTo} />,
      <Route key="books" path="/books" component={Books} />,
      <Route key="book" path="/book" component={Books} />,
      <Route key="logout" path="/logout" component={Logout} />,
      <DefaultLayout key="default" path="/" exact component={Dispatcher.publisher} />,
    ];
  }
  return Dispatcher.default;
};

const Root = () => (
  <GraphQLProviderHOC>
    <Router>
      <Switch>
        <AuthWrapper>{getRoutesPerRole}</AuthWrapper>
      </Switch>
    </Router>
  </GraphQLProviderHOC>
);

export default Root;
