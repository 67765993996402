import gql from 'graphql-tag';

export default gql`
  query storageUploadUrlGet($filename: String!) {
    storageUploadUrlGet(filename: $filename) {
      key
      url
    }
  }
`;
