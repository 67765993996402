import React from 'react';
import t from 'prop-types';

class WindowFocusObserver extends React.Component {
  static propTypes = {
    onUpdate: t.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { onUpdate } = props;
    this.onFocus = () => onUpdate('active');
    this.onBlur = () => onUpdate('away');
  }

  componentDidMount() {
    const {
      onFocus,
      onBlur,
      props: { onUpdate },
    } = this;

    onUpdate('active');
    window.addEventListener('focus', onFocus, false);
    window.addEventListener('blur', onBlur, false);
  }

  componentWillUnmount() {
    const {
      onFocus,
      onBlur,
      props: { onUpdate },
    } = this;
    onUpdate('offline');
    window.removeEventListener('focus', onFocus, false);
    window.removeEventListener('blur', onBlur, false);
  }

  render() {
    return null;
  }
}

export default WindowFocusObserver;
