import React from 'react';
import t from 'prop-types';
import { Button, ButtonIcon } from 'rmwc/Button';
import { Link } from 'react-router-dom';
import {
  Card, CardMedia, CardActions, CardActionButtons,
} from 'rmwc/Card';
import { Grid, GridCell } from 'rmwc/Grid';
import { Typography } from 'rmwc/Typography';
import { Chip, ChipSet } from 'rmwc/Chip';

import '@material/card/dist/mdc.card.css';
import '@material/typography/dist/mdc.typography.css';
import '@material/layout-grid/dist/mdc.layout-grid.css';
import '@material/chips/dist/mdc.chips.css';
import '@material/button/dist/mdc.button.css';

import Cache from '../../util/cache';
import I18n from '../../util/i18n';

import styles from './AllInCards.css';

const Book = ({
  id, title, teachers = [], students = [], subtitle, description,
}) => {
  const { isPublisher } = Cache.getItem('userRoles') || {};
  return (
    id && (
      <GridCell>
        <Card>
          <Link to={`/book/${id}`} className="mdc-card__primary-action">
            <CardMedia
              sixteenByNine
              style={{
                backgroundImage: 'url(images/mathe-cover.png)',
              }}
            />
            <div style={{ padding: '0 1rem 1rem 1rem' }}>
              <Typography use="headline6" tag="h2">
                {title}
              </Typography>
              <Typography
                use="subtitle2"
                tag="h3"
                theme="text-secondary-on-background"
                style={{ marginTop: '-1rem' }}
              >
                {subtitle}
              </Typography>
              <Typography use="body1" tag="div" theme="text-secondary-on-background">
                {description}
              </Typography>
            </div>
          </Link>
          <CardActions>
            <ChipSet>
              <Chip leadingIcon="person" text={`${teachers.length} ${I18n.get('Teachers')}`} />
              <Chip leadingIcon="people" text={`${students.length} ${I18n.get('Students')}`} />
            </ChipSet>
          </CardActions>
          <CardActions>
            <CardActionButtons>
              <Link
                className="mdc-button mdc-card__action mdc-card__action--button"
                to={`/book/${id}?viewOnly`}
              >
                {I18n.get('View')}
              </Link>
              {isPublisher ? (
                <Link
                  className="mdc-button mdc-card__action mdc-card__action--button"
                  to={`/book/${id}`}
                >
                  {I18n.get('Edit')}
                </Link>
              ) : null}
            </CardActionButtons>
          </CardActions>
        </Card>
      </GridCell>
    )
  );
};

Book.propTypes = {
  id: t.string,
  title: t.string,
  subtitle: t.string,
  description: t.string,
};

Book.defaultProps = {
  id: '',
  title: I18n.get('Untitled Book'),
  subtitle: '',
  description: '',
};

const Books = ({ books }) => (
  <React.Fragment>
    <div className={styles.topBooks}>
      <Typography use="button" tag="h1">
        {I18n.get('Library')}
      </Typography>
      <Button outlined disabled>
        <ButtonIcon icon="add" />
        {I18n.get('Create New Book')}
      </Button>
    </div>

    <Grid>
      {books.map(({
        id, title, subtitle, description, teachers, students,
      }) => (
        <Book
          key={id}
          id={id}
          title={title}
          subtitle={subtitle}
          description={description}
          teachers={teachers}
          students={students}
        />
      ))}
    </Grid>
  </React.Fragment>
);

Books.propTypes = {
  books: t.arrayOf(t.shape(Book.propTypes)),
};

Books.defaultProps = {
  books: [],
};

export default Books;
