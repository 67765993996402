import { graphql } from 'react-apollo';

import QueryBookGetCssAndJs from '../../GraphQL/QueryBookGetCssAndJs';

import component from './CustomBookCssAndCodeRendererHOC';

export default graphql(QueryBookGetCssAndJs, {
  props: ({ data: { loading, bookGet }, ownProps }) => ({
    ...ownProps,
    loading,
    cssAndJs: bookGet || {},
  }),
})(component);
