import gql from 'graphql-tag';

export default gql`
  mutation removeChapterOrPageFromBook($bookId: ID!, $chapterOrPageIndex: Int!) {
    removeChapterOrPageFromBook(input: { id: $bookId, chapterOrPageIndex: $chapterOrPageIndex }) {
      id
      pages {
        id
      }
    }
  }
`;
