import React, { useEffect, useState } from 'react';
import t, { func } from 'prop-types';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, DialogButton,
} from 'rmwc/Dialog';
import { Typography } from 'rmwc/Typography';

import I18n from '../../../util/i18n';

import { CellAnswersBar } from './CellAnswersBar';
import styles from './CellAnswersDialog.css';
import Quiz from './Quiz';
import Essay from './Essay';
import Generic from './Generic';

import '@material/dialog/dist/mdc.dialog.css';
import '@material/button/dist/mdc.button.css';
import '@material/typography/dist/mdc.typography.min.css';

const getBody = (props) => {
  switch (props.type) {
    case 'quiz':
      return <Quiz {...props} />;
    case 'essay':
      return <Essay {...props} />;
    default:
      return <Generic {...props} />;
  }
};

getBody.propTypes = {
  type: t.oneOf(['quiz', 'essay', 'dragAndSort', 'dragToBucket', 'paint']).isRequired,
};

/* eslint-disable react/destructuring-assignment */
const CellAnswersDialog = (props) => {
  const [isPrinting, setIsPrinting] = useState(false);
  useEffect(() => {
    const hideDialogForPrinting = () => {
      setIsPrinting(window.prinnnnt);
    };

    window.addEventListener('printEvent', hideDialogForPrinting);
    return () => window.removeEventListener('printEvent', hideDialogForPrinting);
  });

  return (
    <>
      { !isPrinting && (
        <Dialog open={props.open} onClose={props.onClose} className="klfa-answers-dialog">
          {props.statement && (
            <DialogTitle>
              <Typography use="headline6">{props.statement}</Typography>
            </DialogTitle>
          )}
          <DialogContent>{getBody(props)}</DialogContent>
          <DialogActions className={styles.dialogFooter}>
            <CellAnswersBar {...props} />
            <DialogButton action="close" onClick={props.onClose}>
              {I18n.get('Close')}
            </DialogButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
/* eslint-enable react/destructuring-assignment */

CellAnswersDialog.propTypes = {
  answers: t.shape({}),
  answersReceiving: t.number,
  onClose: t.func.isRequired,
  open: t.bool,
  totalStudents: t.number,
  type: t.oneOf(['quiz', 'essay', 'dragAndSort', 'dragToBucket', 'paint', 'geogebra']),
  statement: t.string,
};

CellAnswersDialog.defaultProps = {
  answers: {},
  answersReceiving: Math.floor(Math.random() * 18 + 1),
  totalStudents: 25,
  open: false,
  type: 'essay',
  statement: null,
};

export default CellAnswersDialog;
