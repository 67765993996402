import React, { Component } from 'react';
import t from 'prop-types';
import { Button, ButtonIcon } from 'rmwc/Button';

import {
  ListItem,
  ListItemText,
  ListItemGraphic,
} from 'rmwc/List';

import I18n from '../../../util/i18n';
import '@material/typography/dist/mdc.typography.css';
import '@material/list/dist/mdc.list.css';
import '@material/theme/dist/mdc.theme.css';


class TopAppBarButton extends Component {
  static propTypes = {
    checked: t.bool,
    isMobile: t.bool,
    onUpdate: t.func.isRequired
  };

  render() {
    const {
      checked,
      isMobile,
      onUpdate
    } = this.props;

    function syncWithStudents() {
      onUpdate(checked === null ? false : !checked);
    };

    return (
      isMobile ?
        <ListItem onClick={syncWithStudents}>
          <ListItemGraphic icon="sync" />
          <ListItemText>{I18n.get('Sync with students')}</ListItemText>
        </ListItem>
      :
        <Button onClick={syncWithStudents}>
          {(checked === false && checked !== null)
              ? <ButtonIcon icon="sync_disabled"/>
              : <ButtonIcon icon="sync"/>
          }
          {I18n.get('Sync with students')}
        </Button>
    );
  }
}

export default TopAppBarButton;
