import React from 'react';
import t from 'prop-types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

import Cache from '../../../util/cache';
import I18n from '../../../util/i18n';

const MODULES = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'strike', 'blockquote'],
    [{ script: 'sub' }, { script: 'super' }],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link'],
    ['clean'],
  ],
};

const FORMATS = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'strike',
  'blockquote',
  'sub',
  'super',
  'script',
  'list',
  'bullet',
  'indent',
  'link',
];

const stopPropagation = e => e.stopPropagation();

const CourseCell = ({
  editMode, html, updateContents, parent,
}) => {
  const { isTeacher } = Cache.getItem('userRoles') || {};
  const placeholder = isTeacher ? I18n.get('No content, click the edit button to edit') : '';

  const quillProps = {
    readOnly: !editMode,
    theme: 'bubble',
    value: html,
    onChange: updateContents,
    modules: MODULES,
    formats: FORMATS,
    placeholder,
    style: { marginBottom: '60px' },
  };

  if (parent) quillProps.bounds = parent;

  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
  return (
    <div onKeyDown={stopPropagation}>
      <ReactQuill {...quillProps} />
    </div>
  );
};

CourseCell.propTypes = {
  html: t.string,
  updateContents: t.func.isRequired,
  editMode: t.bool.isRequired,
  parent: t.shape({}),
};

CourseCell.defaultProps = {
  html: '<p></p>',
  parent: null,
};

export default CourseCell;
