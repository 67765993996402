import gql from 'graphql-tag';

export default gql`
  query bookList {
    bookList {
      id
      title
      subtitle
    }
  }
`;
