import React from 'react';
import t from 'prop-types';
import { List } from 'rmwc/List';

import '@material/typography/dist/mdc.typography.css';
import '@material/list/dist/mdc.list.css';
import '@material/theme/dist/mdc.theme.css';

import I18n from '../../../util/i18n';
import PleaseWait from '../../PleaseWait';

import AddPageListItem from './AddPageContainer';
import ChapterListItem from './ChapterListItemContainer';
import ListItem from './ListItemContainer';
import styles from './Component.css';

const TableOfContents = ({ readOnly, book: { id: bookId, pages }, className }) => {
  if (!bookId) return <PleaseWait />;

  const items = (pages || []).map((chapterOrPage, index) => {
    const { id, title, subtitle } = chapterOrPage;
    if (chapterOrPage.pages && chapterOrPage.pages.length) {
      return <ChapterListItem key={id} {...chapterOrPage} bookId={bookId} readOnly={readOnly} />;
    }

    return (
      <ListItem
        {...chapterOrPage}
        bookId={bookId}
        index={index}
        title={title}
        subtitle={subtitle}
        key={id}
        type="Page"
        graphic="notes"
        readOnly={readOnly}
      />
    );
  });

  return (
    <div className={`${styles.container} ${className}`}>
      {readOnly ? (
        <List>{items}</List>
      ) : (
        <React.Fragment>
          <div className={styles.header}>
            <span>{I18n.get('Table of contents')}</span>
            <div>
              <AddPageListItem bookId={bookId} />
              {/* <AddChapterListItem bookId={bookId} /> */}
            </div>
          </div>
          <List twoLine className={styles.mdcList}>
            {items}
          </List>
        </React.Fragment>
      )}
    </div>
  );
};

TableOfContents.defaultProps = {
  book: {
    pages: [],
    id: '',
  },
  readOnly: false,
  className: '',
};

TableOfContents.propTypes = {
  book: t.shape({
    id: t.string,
    pages: t.arrayOf(
      t.shape({
        id: t.string,
        title: t.string,
        subtitle: t.string,
      }),
    ),
  }),
  readOnly: t.bool,
  className: t.string,
};

export default TableOfContents;
