const bus = [];
const listeners = {};

const logger = console;

const toListeners = (capsule) => {
  const { channel } = capsule;
  const holder = listeners[channel];
  if (!holder) {
    return;
  }

  holder.forEach((listener) => {
    try {
      listener.listener.onHubCapsule(capsule);
    } catch (e) {
      logger.warn(`error dispatching ${channel} event to ${listener.name}`);
    }
  });

  bus.pop();
};

const dispatch = (channel, payload, source = '') => {
  const capsule = {
    channel,
    payload: Object.assign({}, payload),
    source,
  };

  try {
    bus.push(capsule);
    toListeners(capsule);
  } catch (e) {
    logger.warn('Hub dispatch error', e);
  }
};

const listen = (channel, listener, listenerName = 'noname') => {
  let holder = listeners[channel];
  if (!holder) {
    holder = [];
    listeners[channel] = holder;
  }

  holder.push({
    name: listenerName,
    listener,
  });
};

const remove = (channel, listener, listenerName = 'noname') => {
  let holder = listeners[channel];
  if (holder) {
    holder = holder.filter(
      _listener => !(listener === _listener.listener && listenerName === _listener.name),
    );
    listeners[channel] = holder;
  }
};

export default {
  dispatch,
  listen,
  remove,
  toListeners,
};
