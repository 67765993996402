import gql from 'graphql-tag';

export default gql`
  query userGetCurrent {
    userGetCurrent {
      id
      displayName
      roles
      currentCourse {
        id
        currentUserPosition {
          bookId
          pageId
        }
      }
    }
  }
`;
