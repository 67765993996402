import gql from 'graphql-tag';

export default gql`
  mutation courseActivityDelete($courseId: ID!, $index: Int!) {
    courseActivityDelete(courseId: $courseId, index: $index) {
      id
      activities {
        content
        type
        createdAt
      }
    }
  }
`;
