import React from 'react';
import t from 'prop-types';

import Edit from '../Upload';

import View from './View';

const Image = (props) => {
  const { editMode } = props;
  return editMode
    ? <Edit acceptedTypes=".jpg, .jpeg, .png, .gif, .JPG, .JPEG, .PNG, .GIF" {...props} />
    : <View {...props} />;
};

Image.propTypes = {
  editMode: t.bool,
};

Image.defaultProps = {
  editMode: false,
};

export default Image;
