// @ts-check
import React from 'react';
import t from 'prop-types';
import { ApolloProvider } from 'react-apollo';

import graphQLClient from '../util/getGraphQLClient';

const GraphQLProviderHOC = ({ children }) => (
  <ApolloProvider client={graphQLClient}>{children}</ApolloProvider>
);

GraphQLProviderHOC.propTypes = {
  children: t.oneOfType([t.node, t.arrayOf(t.node)]).isRequired,
};

export default GraphQLProviderHOC;
