import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AllBooksInCards from '../../components/Books/AllInCardsContainer';

import Book from './Book';
import Cell from './Cell';

const App = () => (
  <Switch>
    <Route path="/book/:bookId/page/:pageId/cell/:cellId" component={Cell} />
    <Route path="/book/:bookId/page/:pageId" component={Book} />
    <Route path="/book/:bookId" component={Book} />
    <Route path="/books" component={AllBooksInCards} />
    <Route component={Book} />
  </Switch>
);

export default App;
