import gql from 'graphql-tag';

export default gql`
  mutation courseUpdate(
    $id: ID!
    $title: String
    $description: String
    $image: String
    $books: [String!]
  ) {
    courseUpdate(id: $id, title: $title, description: $description, image: $image, books: $books) {
      id
      title
      description
      image
      books {
        id
        title
      }
    }
  }
`;
